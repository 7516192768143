<div class="card ">
    <!--begin::Header-->
    <div class="card-header pb-1">
        <div class="card-title ">
            <h3 class="">{{l('CustomerLocations')}}</h3>
        </div>
    </div>
    <!--end::Header-->
    <div class="card-body py-1">
        <div class="row align-items-center" id="EmployeeLocations">
                <!--<Primeng-TurboTable-Start>-->
                <div class="primeng-datatable-container col-12" [busyIf]="primengTableHelper.isLoading">
                    <p-table #dataTable editMode="row" dataKey="id"  sortMode="multiple"
                        (onLazyLoad)="getEmployeeLocations($event)" [value]="primengTableHelper.records"
                        rows="{{primengTableHelper.defaultRecordsCountPerPage}}" [paginator]="false" [lazy]="true"
                        [responsive]="primengTableHelper.isResponsive" [scrollable]="true" ScrollWidth="100%">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 90px">
                                    {{'Action' | localize}}
                                </th>
                                <th style="width: 240px" pSortableColumn="customerLocationName">
                                    {{'CustomerLocation' | localize}}
                                    <p-sortIcon field="customerLocationName"></p-sortIcon>
                                </th>
                                <th style="width: 110px" pSortableColumn="isLead">
                                    {{'IsLead' | localize}}
                                    <p-sortIcon field="isLead"></p-sortIcon>
                                </th>
                                <th style="width: 110px" pSortableColumn="doNotSend">
                                    {{'DoNotSend' | localize}}
                                    <p-sortIcon field="doNotSend"></p-sortIcon>
                                </th>
                                <!-- <th pSortableColumn="isPrefer">
                                    {{'IsPrefer' | localize}}
                                    <p-sortIcon field="isPrefer"></p-sortIcon>
                                </th> -->
                                <th style="width: 145px" pSortableColumn="lastTrainedDate">
                                    {{'LastTrainedDate' | localize}}
                                    <p-sortIcon field="lastTrainedDate"></p-sortIcon>
                                </th>
                                <th style="width: 130px" pSortableColumn="averageRating">
                                    {{'AverageRating' | localize}}
                                    <p-sortIcon field="averageRating"></p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-ri="rowIndex" let-rowdata="rowData" let-editing="editing"
                            let-record="$implicit">
                            <tr [pEditableRow]="record">
                                <td style="width: 90px">
                                    <!-- <button class="btn btn-outline-danger btn-icon btn-sm" (click)="removeEmployeeLocation(record)"
                                        title="{{'Delete' | localize}}">
                                        <i class="fa fa-trash" [attr.aria-label]="l('Delete')"></i>
                                    </button> -->
                                    <button *ngIf="!editing" pInitEditableRow (click)="dateExistingORNot(record.lastTrainedDate)"
                                        class="btn btn-outline-primary btn-icon btn-sm" icon="pi pi-edit"
                                        title="{{'Edit' | localize}}">
                                        <i class="fa fa-edit" [attr.aria-label]="l('Edit')"></i>
                                    </button>
                                    <button *ngIf="editing" pSaveEditableRow icon="pi pi-check"
                                        class="btn btn-outline-success btn-icon btn-sm" title="{{'Save' | localize}}"
                                        (click)="updateEmployeeCustomerLocation(record)">
                                        <i class="fa fa-check" [attr.aria-label]="l('Save')"></i>
                                    </button>
                                </td>
                                <td style="width: 240px" data-toggle="tooltip" title="{{ record.customerLocationName }}" data-placement="right">
                                    <span class="p-column-title">{{'CustomerLocation' | localize}}</span> {{record.customerLocationName}}
                                </td>
                                <td style="width: 110px" pEditableColumn pInitEditableRow>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <div class="p-field-checkbox">
                                                <p-checkbox [(ngModel)]="record.isLead" binary="true" class="mr-2"
                                                    inputId="isLead"></p-checkbox>
                                                <label for="isLead" *ngIf="record.isLead"
                                                    style="line-height:0.8">Yes</label>
                                                <label for="isLead" *ngIf="!record.isLead"
                                                    style="line-height:0.8">No</label>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <span class="p-column-title">{{'IsLead' | localize}}</span>
                                            <span class="ui-column-title">{{l('IsLead :')}}</span> <span
                                                class="label label-success label-inline"
                                                *ngIf="record.isLead">Yes</span>
                                            <span class="ui-column-title">{{l('IsLead :')}}</span> <span
                                                class="label label-dark label-inline"
                                                *ngIf="!record.isLead">No</span>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                 <td style="width: 110px" pEditableColumn pInitEditableRow>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <div class="p-field-checkbox">
                                                <p-checkbox [(ngModel)]="record.doNotSend" binary="true" class="mr-2"
                                                    inputId="doNotSend"></p-checkbox>
                                                <label for="doNotSend" *ngIf="record.doNotSend"
                                                    style="line-height:0.8">Yes</label>
                                                <label for="doNotSend" *ngIf="!record.doNotSend"
                                                    style="line-height:0.8">No</label>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <span class="p-column-title">{{'DoNotSend' | localize}}</span>
                                            <span class="ui-column-title">{{l('DoNotSend :')}}</span> <span
                                                class="label label-success label-inline"
                                                *ngIf="record.doNotSend">Yes</span>
                                            <span class="ui-column-title">{{l('DoNotSend :')}}</span> <span
                                                class="label label-dark label-inline"
                                                *ngIf="!record.doNotSend">No</span>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                              <!--  <td pEditableColumn pInitEditableRow>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <div class="p-field-checkbox">
                                                <p-checkbox [(ngModel)]="record.isPrefer" binary="true" class="mr-2"
                                                    inputId="isPrefer"></p-checkbox>
                                                <label for="isPrefer" *ngIf="record.isPrefer"
                                                    style="line-height:0.8">Yes</label>
                                                <label for="isPrefer" *ngIf="!record.isPrefer"
                                                    style="line-height:0.8">No</label>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <span class="p-column-title">{{'IsPrefer' | localize}}</span>
                                            <span class="ui-column-title">{{l('IsPrefer :')}}</span> <span
                                                class="label label-success label-inline"
                                                *ngIf="record.isPrefer">Yes</span>
                                            <span class="ui-column-title">{{l('IsPrefer :')}}</span> <span
                                                class="label label-dark label-inline"
                                                *ngIf="!record.isPrefer">No</span>
                                        </ng-template>
                                    </p-cellEditor>
                                </td> -->
                                <td style="width: 145px" data-toggle="tooltip"  data-placement="right">
                                    <span class="p-column-title">{{'LastTrainedDate' | localize}}</span>
                                    <span *ngIf="!editing"  >{{ record.lastTrainedDate | luxonFormat:'EEE MM/dd/yyyy'}}</span>
                                        <!-- <input  *ngIf="editing" class="form-control m-input" appendTo="body" type="date" [(ngModel)]="lastTrainedDate" value="2016-02-01" id="LastTrainedDate" name="LastTrainedDate" /> -->
                                        <p-calendar *ngIf="editing" [maxDate]="maxLastTrainedDate" showButtonBar="true"  baseZIndex=3 appendTo="body" [(ngModel)]="lastTrainedDate" dateFormat="mm/dd/yy" placeholder="Select date" [readonlyInput]="true"  #LastTrainedDate="ngModel"  class="p-fluid"  name="lastTrainedDate"  inputId="min-max"></p-calendar>
                                </td>
                                <td *ngIf="record.averageRating == 0" style="width: 130px" data-toggle="tooltip" title="{{ record.averageRating }}" data-placement="right">
                                    <span class="p-column-title">{{'AverageRating' | localize}}</span> 
                                </td>
                                <td *ngIf="record.averageRating > 0" style="width: 130px" data-toggle="tooltip" title="{{ record.averageRating }}" data-placement="right">
                                    <span class="p-column-title">{{'AverageRating' | localize}}</span> {{record.averageRating}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                        {{'NoData' | localize}}
                    </div>
                    <div class="primeng-paging-container">
                        <p-paginator [rows]="primengTableHelper.defaultRecordsCountPerPage" #paginator
                            (onPageChange)="getEmployeeLocations($event)"
                            [totalRecords]="primengTableHelper.totalRecordsCount"
                            [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage">
                        </p-paginator>
                        <span class="total-records-count">
                            {{'TotalRecordsCount' | localize:primengTableHelper.totalRecordsCount}}
                        </span>
                    </div>
                </div>
                <!--<Primeng-TurboTable-End>-->
        </div>
    </div>
</div>
