import { ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IBasicOrganizationUnitInfo } from '@app/admin/organization-units/basic-organization-unit-info';
import { IUserWithOrganizationUnit } from '@app/admin/organization-units/user-with-organization-unit';
import { IUsersWithOrganizationUnit } from '@app/admin/organization-units/users-with-organization-unit';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CustomerLocationContactsServiceProxy, EmployeeAverageRatingsServiceProxy, EmployeeLocationListDto, EmployeeProfileDetailServiceProxy } from '@shared/service-proxies/service-proxies';
import { DateTime } from 'luxon';
import { LazyLoadEvent } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'employeeCustomerLocations',
  templateUrl: './employee-customer-locations.component.html',
  styleUrls: ['./employee-customer-locations.component.css']
})
export class EmployeeCustomerLocationsComponent extends  AppComponentBase implements OnInit {

  @ViewChild('dataTable', {static: true}) dataTable: Table;
  @ViewChild('paginator', {static: true}) paginator: Paginator;
  lastTrainedDate : Date;
  maxLastTrainedDate = new Date();
  @Input() employeeId: number;
  private _organizationUnit: IBasicOrganizationUnitInfo = null;
  constructor(
      injector: Injector,
      private _changeDetector: ChangeDetectorRef,
      private _employeeAverageRatingService : EmployeeAverageRatingsServiceProxy,
      private _employeeProfileDetailservice: EmployeeProfileDetailServiceProxy,
      private _customerLocationContactsService: CustomerLocationContactsServiceProxy,
  ) {
      super(injector);
  }

  ngOnInit(){
  }
  
  reloadPage(): void {
  this.paginator.changePage(this.paginator.getPage());
  }
  refreshMembers(): void {
  this.reloadPage();
  }



  getEmployeeLocations(event?: LazyLoadEvent) {
    if (!this.employeeId) {
        return;
    }

    if (this.primengTableHelper.shouldResetPaging(event)) {
        this.paginator.changePage(0);

        return;
    }

    this.primengTableHelper.showLoadingIndicator();
    this._employeeProfileDetailservice.getEmployeeLocationByEmployeeId(
        this.employeeId,
        this.primengTableHelper.getSorting(this.dataTable),
        this.primengTableHelper.getSkipCount(this.paginator, event),
        this.primengTableHelper.getMaxResultCount(this.paginator, event)
    ).pipe(finalize(() => this.primengTableHelper.hideLoadingIndicator()
    ))
    .subscribe(result => {
        this.primengTableHelper.totalRecordsCount = result.totalCount;
        this.primengTableHelper.records = result.items;
        this.primengTableHelper.hideLoadingIndicator();
    });
}
// removeEmployeeLocation(record: EmployeeLocationListDto): void {
//         this.message.confirm(
//             this.l('RemoveUserFromCustomerLocationWarningMessage', record.employeeName),
//             this.l('AreYouSure'),
//             isConfirmed => {
//                 if (isConfirmed) {
//                     this._employeeAverageRatingService
//                         .removeEmployeeLocation(record.employeeId ,record.customerLocationId)
//                         .subscribe(() => {
//                             this.notify.success(this.l('SuccessfullyRemoved'));
//                             this.refreshMembers();
//                         });
//                 }
//             }
//         );
//     }

    updateEmployeeCustomerLocation(updateClc: EmployeeLocationListDto): void {
      if (this.lastTrainedDate) {
        updateClc.lastTrainedDate =DateTime.fromISO(this.lastTrainedDate?.toString()); 
      }
      else{
        updateClc.lastTrainedDate = undefined;
      }
         
        this._employeeProfileDetailservice
        .updateEmployeeCustomerLocation(updateClc)
        .subscribe(() => {
            this.notify.success(this.l('RecordSuccessfullyUpdated'));
            this.getEmployeeLocations()
        });
    }
    // convertDateTimeToDate(lastTrainedDate){
    //     this.lastTrainedDate = new Date(lastTrainedDate.toISO())
    // }

    dateExistingORNot(lastTrainedDate){
        if(lastTrainedDate){
            this.lastTrainedDate = new Date(lastTrainedDate.toISO())
        }
    }
}
