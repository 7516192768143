import { Component, OnInit, Injector } from '@angular/core';
import { GetAllCountStaffDashboard, StaffDashboardServiceProxy, TenantDashboardServiceProxy, ThemeSettingsDto } from '@shared/service-proxies/service-proxies';
import { DashboardChartBase } from '../dashboard-chart-base';
import { WidgetComponentBaseComponent } from '../widget-component-base';




@Component({
    selector: 'app-widget-top-stats',
    templateUrl: './widget-top-stats.component.html',
    styleUrls: ['./widget-top-stats.component.css']
})
export class WidgetTopStatsComponent extends WidgetComponentBaseComponent implements OnInit {
    openJobOrderCount: number = 0;
    callOutCount: number = 0;
    missingTimeEntriesCount: number = 0;
    missingCheckinCount: number = 0;
    timeCardReviewCount: number = 0;
    pendingTimeOffCount: number = 0;
    currentTime:any;
    storeTime:any;
    widgetStatsTime:any;
    clearTimeOutForWidgetStats:any;
    constructor(injector: Injector,
        private _staffDashboardService: StaffDashboardServiceProxy
    ) {
        super(injector);
    }

    ngOnInit() {
        this.widgetStatsTime="A moment"
        this.getWidgetStats();
        //if (this.isGranted('Pages.OpenJobOrderWidget'))
        //    this.loadJobOrderCount();
        //if (this.isGranted('Pages.CallOutWidget'))
        //    this.loadCallOutCount();
        //if (this.isGranted('Pages.MissingTimeEntriesWidget'))
        //    this.loadMissingTimeEntriesCount();
        //if (this.isGranted('Pages.TimeCardReviewWidget'))
        //    this.loadTimeCardReviewCount();
        //if (this.isGranted('Pages.PendingOffTimeWidget'))
        //    this.loadPendingTimeOffCount();
    }

    getWidgetStats()
    {
        this._staffDashboardService.getAllWidgetStats().subscribe((result)=>{
            clearTimeout(this.clearTimeOutForWidgetStats);
            this.storeTime = new Date().getTime();
            this.openJobOrderCount=result.jobOrderCount;
            this.callOutCount=result.callOut;
            this.missingTimeEntriesCount=result.missingTimeEntries;
            this.missingCheckinCount=result.missingCheckin;
            this.timeCardReviewCount=result.timeCardReview;
            this.pendingTimeOffCount=result.pendingOffTimeRequest;
            this.calculateTimeForWidgetStats();
        })
    }
   private calculateTimeForWidgetStats() {
     var humanizeDuration = require("humanize-duration");
     this.currentTime = new Date().getTime();
     let timeDifference = this.currentTime-this.storeTime;
     this.widgetStatsTime = humanizeDuration((timeDifference), {units: ["m"], maxDecimalPoints: 0, largest: 1 });
     if(timeDifference<=60000)
     {
        this.widgetStatsTime="A moment";
     }
     else if(timeDifference>=(60000*60))
     {
        this.widgetStatsTime="A long";
     }
     this.clearTimeOutForWidgetStats= setTimeout(() => {
           this.calculateTimeForWidgetStats(); 
       }, 4000);      
   } 
}
