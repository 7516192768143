<div bsModal #createOrEditModal="bs-modal" class="modal fade " tabindex="-1" role="dialog"
    aria-labelledby="createOrEditModal" aria-hidden="true" [config]="{ backdrop: 'static' }">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="content d-flex flex-column flex-column-fluid">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <span>{{ l('JobOrderShiftBreaks') }}</span>
                    </h4>
                    <!-- <button (click)="exportToExcel()"
                        class="btn btn-outline btn-outline-success btn-active-light-success me-1">
                        <i class="fa fa-file-excel"></i> {{ l('ExportToExcel') }}
                    </button> -->
                    <!-- <button type="button" class="close" (click)="close()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button> -->
                </div>

                <div [class]="containerClass">
                    <h6 *ngIf="breakRulesViolated.length > 0" class="mt-5">{{ l('BreakRulesViolated') }}</h6>
                    <div class="row" *ngIf="breakRulesViolated.length > 0">
                        <div class="col-6" *ngFor="let rule of breakRulesViolated; index as i">
                            <label class="col-form-label ">{{i+1}} : {{ l(rule.ruleName) }} </label>
                        </div>
                    </div>
                    <span *ngIf="breakRulesViolated.length > 0" class="d-flex justify-content-center">{{ l('TotalTimeSubtracted') }} :
                        {{totalBreakTimeSubtracted}} Minutes</span>
                    <div class="card card-custom gutter-b">
                        <div class="card-body">
                            <form class="form" autocomplete="off">
                                <div>
                                    <div class="row align-items-center">
                                        <div class="col-xl-12">
                                            <div class="form-group m-form__group align-items-center">
                                                <div class="input-group">
                                                    <input [(ngModel)]="filterText" name="filterText" autoFocus
                                                        class="form-control m-input"
                                                        [placeholder]="l('SearchWithThreeDot')" type="text" />
                                                    <span class="input-group-btn">
                                                        <button (click)="getJobOrderShiftBreaks()"
                                                            class="btn btn-primary" type="submit">
                                                            <i class="flaticon-search-1"></i>
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <div class="row align-items-center">
                                <!--<Primeng-Datatable-Start>-->
                                <div class="primeng-datatable-container col-12" [busyIf]="primengTableHelper.isLoading">
                                    <p-table #dataTable (onLazyLoad)="getJobOrderShiftBreaks($event)"
                                        [value]="primengTableHelper.records"
                                        rows="{{ primengTableHelper.defaultRecordsCountPerPage }}" [paginator]="false"
                                        [lazy]="true" [scrollable]="true" ScrollWidth="100%"
                                        [tableStyle]="{ 'min-width': '50rem' }"
                                        [resizableColumns]="primengTableHelper.resizableColumns">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th style="width: 150px">
                                                    {{ l('BreakLabel') }}
                                                </th>
                                                <th style="width: 150px">
                                                    {{ l('BreakTime') }}
                                                </th>
                                                <th style="width: 150px">
                                                    {{ l('BreakStartDateTime') }}
                                                </th>
                                                <th style="width: 150px">
                                                    {{ l('BreakEndDateTime') }}
                                                </th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-record="$implicit" let-i="rowIndex">
                                            <tr>
                                                <td style="width: 150px">
                                                    <!-- <span class="p-column-title"> {{ l('BreakDescription') }}</span> -->
                                                    {{ record.label }}
                                                </td>
                                                <td style="width: 150px">
                                                    <!-- <span class="p-column-title"> {{ l('BreakTime') }}</span> -->
                                                    {{ record.breakTime * 60000 | humanizeDuration:{ delimiter: ' ',
                                                    largest:2,round: true } }}
                                                </td>
                                                <td style="width: 150px" data-toggle="tooltip"  data-placement="right" title="Local: {{ record.tenantBreakStartDateTime | formatStringToDateTime : record.breakStartDateTimeLocal }}">
                                                    <!-- <span class="p-column-title"> {{ l('BreakTime') }}</span> -->
                                                    {{ record.locationBreakStartDateTime | formatStringToDateTime : record.breakStartDateTimeLocal }}
                                                </td>
                                                <td style="width: 150px" data-toggle="tooltip"  data-placement="right" title="Local: {{ record.tenantBreakEndDateTime | formatStringToDateTime : record.breakEndDateTimeLocal }}">
                                                    <!-- <span class="p-column-title"> {{ l('BreakTime') }}</span> -->
                                                    {{ record.locationBreakEndDateTime | formatStringToDateTime : record.breakEndDateTimeLocal }}
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                    <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                                        {{ l('NoData') }}
                                    </div>
                                    <div class="primeng-paging-container">
                                        <p-paginator [rows]="primengTableHelper.defaultRecordsCountPerPage" #paginator
                                            (onPageChange)="getJobOrderShiftBreaks($event)"
                                            [totalRecords]="primengTableHelper.totalRecordsCount"
                                            [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage"
                                            [showCurrentPageReport]="true" [currentPageReportTemplate]="
                                        'TotalRecordsCount' | localize: primengTableHelper.totalRecordsCount
                                    ">
                                        </p-paginator>
                                    </div>
                                </div>
                                <!--<Primeng-Datatable-End>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary blue" (click)="close()">
                        {{ l('Close') }}
                    </button>
                </div>
                <viewJobOrderShiftBreakModal #viewJobOrderShiftBreakModal></viewJobOrderShiftBreakModal>
            </div>
        </div>
    </div>
</div>