<div
    bsModal
    #createOrEditModal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="createOrEditModal"
    aria-hidden="true"
    [config]="{ backdrop: 'static' }"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    <span>{{ l('Details') }}</span>
                </h4>
<!-- 
                <button type="button" class="close" (click)="close()" aria-label="Close" [disabled]="saving">
                    <span aria-hidden="true">&times;</span>
                </button> -->
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-6 mb-3">
                        <h6>{{ l('BreakDescription') }} :</h6>
                        {{ item.jobOrderShiftBreak.breakDescription }}
                    </div>
                    <div class="col-6 mb-3">
                        <h6>{{ l('BreakTime') }} :</h6>
                        {{ item.jobOrderShiftBreak.breakTime }}
                    </div>

                    <div class="col-6 mb-3">
                        <h6>{{ l('Employee') }} {{ l('FullName') }} :</h6>
                        {{ item.employeeFullName }}
                    </div>
                    <div class="col-6 mb-3">
                        <h6>{{ l('JobOrderShift') }} {{ l('Id') }} :</h6>
                        {{ item.jobOrderShiftId }}
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="saving" type="button" class="btn btn-primary blue" (click)="close()">
                    {{ l('Close') }}
                </button>
            </div>
        </div>
    </div>
</div>
