import { Component, Injector, ViewChild, ViewEncapsulation } from '@angular/core';
import { StaffDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { WidgetComponentBaseComponent } from '../widget-component-base';
import { forEach as _forEach } from 'lodash-es';
import { finalize } from 'rxjs/operators';
import { LazyLoadEvent } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { appModuleAnimation } from '@shared/animations/routerTransition';
@Component({
    selector: 'app-widget-open-job-order-list',
    templateUrl: './widget-open-job-order-list.component.html',
    styleUrls: ['./widget-open-job-order-list.component.css'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
})

export class WidgetOpenJobOrderListComponent extends WidgetComponentBaseComponent {
    @ViewChild('dataTable', { static: true }) dataTable: Table;
    @ViewChild('paginator', { static: true }) paginator: Paginator;

    constructor(injector: Injector,
        private _staffDashboardService: StaffDashboardServiceProxy) {
        super(injector);
    }

    getOpenJobOrders(event?: LazyLoadEvent) {
        if (this.primengTableHelper.shouldResetPaging(event)) {
            this.paginator.changePage(0);
            return;
        }
        this.primengTableHelper.showLoadingIndicator();
        this._staffDashboardService.getAllOpenJobOrders(
            this.primengTableHelper.getSkipCount(this.paginator, event),
            this.primengTableHelper.getMaxResultCount(this.paginator, event),
            this.primengTableHelper.getSorting(this.dataTable),
            ).pipe(
                finalize(() => {
                this.primengTableHelper.hideLoadingIndicator();
            })
        ).subscribe((result) => {
            this.primengTableHelper.totalRecordsCount = result.totalCount;
            this.primengTableHelper.records = result.items;
        });
    }

    reloadPage(): void {
        this.paginator.changePage(this.paginator.getPage());
    }
}
