import { Component, Injector, ViewChild, ViewEncapsulation } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
    selector: 'employeeProfileModal',
    templateUrl: './employee-profile-modal.component.html',
    styleUrls: ['./employee-profile-modal.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
})
export class EmployeeProfileModalComponent extends AppComponentBase {

    @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;

    active = false;
    saving = false;
    geoPref = false;
    gma = false;
    invitation = false;
    feedback = false;
    shiftRejection = false;
    shiftAssignment = false;
    summary = false;
    shiftCompleted = false;
    timeOffRequest=false;
    employeeId: number;
    employeeLoction = false;

    constructor(injector: Injector) {
        super(injector);
    }

    show(employeeId: number): void {
        this.markFalseFlages();
        if (employeeId != undefined) {
            this.summary = true;
            this.shiftAssignment = true;
            this.employeeId = employeeId;
            this.active = true;
            this.modal.show();
        }
    }
    markFalseFlages() {
        this.geoPref = false;
        this.gma = false;
        this.invitation = false;
        this.feedback = false;
        this.shiftRejection = false;
        this.shiftAssignment = false;
        this.shiftCompleted = false;
        this.timeOffRequest=false;
        this.employeeLoction = false;
    }
    invitationView() {
        this.markFalseFlages();
        this.invitation = true;
    } geoPrefView() {
        this.markFalseFlages();
        this.geoPref = true;
    } gmaView() {
        this.markFalseFlages();
        this.gma = true;
    } feedbackView() {
        this.markFalseFlages();
        this.feedback = true;
    } shiftRejectionView() {
        this.markFalseFlages();
        this.shiftRejection = true;
    } shiftAssignmentView() {
        this.markFalseFlages();
        this.shiftAssignment = true;
    } shiftCompletedView() {
        this.markFalseFlages();
        this.shiftCompleted = true;
    }approvedtimeOffRequestView() {
        this.markFalseFlages();
        this.timeOffRequest = true;
    }employeeLocationView() {
        this.markFalseFlages();
        this.employeeLoction = true;
    } 
    close(): void {
        this.summary = false;
        this.shiftAssignment = false;
        this.employeeId = undefined;
        this.saving = false
        this.active = false;
        this.modal.hide();
    }
}
