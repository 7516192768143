import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'formatStringToTime'
})
export class FormatStringToTimePipe implements PipeTransform {
  format='hh:mm a';
  transform(value: string,fallback:any):string {
  if(value != null){ 
    let timePart = value.split(' ')[1];
    let timeZone = value.split(' ')[2];
    let hourPart = timePart.split(':')[0];
    let minutePart = timePart.split(':')[1];
    let period = parseInt(hourPart) >= 12 ? 'PM' : 'AM';
    let formattedTimeStr =  parseInt(hourPart) > 12 ? `${parseInt(hourPart) - 12}:${minutePart} ${period}` : parseInt(hourPart)== 0 ? `12:${minutePart} ${period}` : `${hourPart}:${minutePart} ${period}`;
    return  timeZone? formattedTimeStr + ` (${timeZone})`: formattedTimeStr;
  }
  else if(fallback != null){
    let date = DateTime.fromISO(fallback);
    return date.toFormat(this.format);
  }
  return "";
  }

}
