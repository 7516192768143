<div [@routerTransition]>
    <div class="card card-custom h-100">
        <div class="card-header align-items-center border-0">
            <h3 class="card-title align-items-start flex-column">
                <span class="font-weight-bolder text-dark">
                    {{l('MissingTimeEntries')}}
                </span>
            </h3>
            <div class="card-toolbar">
                <button class="btn btn-primary" (click)="reloadPage()"><i class="fa fa-sync"></i> </button>
            </div>
        </div>
        <div class="card-body" [perfectScrollbar]>
            <div class="row list-separated text-center" style="min-height: 565px !important;">
                <!--<Primeng-Datatable-start>-->
                <div class="primeng-datatable-container col-12" [busyIf]="primengTableHelper.isLoading">
                    <p-table #dataTable scrollHeight="400px" id="custom-yscrollable-td" (onLazyLoad)="getAllMissingTimeEntriesShifts($event)"
                        [value]="primengTableHelper.records" rows="{{ primengTableHelper.defaultRecordsCountPerPage }}"
                        [paginator]="false" [lazy]="true" [scrollable]="true" ScrollWidth="100%"
                        [responsive]="primengTableHelper.isResponsive"
                        [resizableColumns]="primengTableHelper.resizableColumns">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 130px" pSortableColumn="name">
                                    {{ l('JobOrderShift') }}
                                    <p-sortIcon field="jobOrderShift.jobOrderShift.id"></p-sortIcon>
                                </th>
                                <th style="width: 100px" pSortableColumn="employeeFk.fullName">
                                    {{ l('Employee') }}
                                    <p-sortIcon field="employeeFullName"></p-sortIcon>
                                </th>
                                <th style="width: 150px" pSortableColumn="jobOrderFk.customerLocationFk.name">
                                    {{ l('CustomerLocation') }}
                                    <p-sortIcon field="customerLocationName"></p-sortIcon>
                                </th>
                                <th style="width: 150px" pSortableColumn="jobOrderFk.standardShiftFk.name">
                                    {{ l('StandardShift') }}
                                    <p-sortIcon field="standardShiftName"></p-sortIcon>
                                </th>
                                <th style="width: 100px" pSortableColumn="shiftDate">
                                    {{ l('ShiftDate') }}
                                    <p-sortIcon field="jobOrderShift.shiftDate"></p-sortIcon>
                                </th>
                                <th style="width: 100px" pSortableColumn="shiftStartDateTime">
                                    {{ l('StartTime') }}
                                    <p-sortIcon field="jobOrderShift.shiftStartDateTime"></p-sortIcon>
                                </th>
                                <th style="width: 100px" pSortableColumn="shiftEndDateTime">
                                    {{ l('EndTime') }}
                                    <p-sortIcon field="jobOrderShift.shiftEndDateTime"></p-sortIcon>
                                </th>
                                <th style="width: 130px" pSortableColumn="assignedDate">
                                    {{ l('AssignedDate') }}
                                    <p-sortIcon field="jobOrderShift.assignedDate"></p-sortIcon>
                                </th>

                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-record="$implicit" let-i="rowIndex">
                            <tr>

                                <td style="width: 130px" class="short-string-column">
                                    <span class="ui-column-title"> {{ l('JobOrderShift') }}</span> #{{
                                    record.jobOrderShift.id }}
                                </td>
                                <td style="width: 100px" class="long-string-column">
                                    <span class="ui-column-title"> {{ l('Employee') }}</span>
                                    <ng-template #popTemplate>
                                        <div class="font-weight-bold">{{employeePopOverName}}</div>
                                        <div class="mt-3 symbol symbol-50 symbol-lg-120"><img class="symbol-label"
                                                [src]="employeeImage" alt="ImageMissing"></div>
                                    </ng-template>
                                    <a [routerLink]="['/app/main/employees/employees']"
                                        [queryParams]="{filterText:record.employeeFullName}" class="cursor-pointer">
                                        <span container="body"
                                            (mouseover)="getEmployeePopoverDetail(record.employeeFullName)"
                                            (mouseout)="clearEmployeePopoverDetail(employeePopOverTimeOut)"
                                            triggers="mouseenter:mouseleave" placement="right"
                                            [popover]="popTemplate">{{ record.employeeFullName }}</span> </a>
                                </td>
                                <td style="width: 150px" class="long-string-column" data-toggle="tooltip"
                                    title="{{ record.customerLocationName }}" data-placement="right">
                                    <span class="ui-column-title"> {{ l('CustomerLocation') }}</span> {{
                                    record.customerLocationName }}
                                </td>
                                <td style="width: 150px" class="long-string-column">
                                    <span class="ui-column-title"> {{ l('StandardShift') }}</span> {{
                                    record.standardShiftName }}
                                </td>
                                <td style="width: 100px" class="date-time-column">
                                    <span class="ui-column-title"> {{ l('ShiftDate') }}</span>
                                    <span *ngIf="record.jobOrderShift.shiftDate"
                                    title="{{record.localTenantStartDateTime | formatStringToDate : record.jobOrderShift.shiftDate}}">
                                        {{record.jobOrderShift.locationStartDateTime | formatStringToDate : record.jobOrderShift.shiftDate }} 
                                    </span>
                                    <span *ngIf="!record.jobOrderShift.shiftDate">-</span>
                                </td>
                                <td style="width: 100px" class="date-time-column">
                                    <span class="ui-column-title"> {{ l('StartTime') }}</span>
                                    <span *ngIf="record.jobOrderShift.shiftStartDateTime"  
                                    title="Local: {{record.localTenantStartDateTime | formatStringToTime : record.jobOrderShift.shiftStartDateTime}}">
                                        {{record.jobOrderShift.locationStartDateTime | formatStringToTime : record.jobOrderShift.shiftStartDateTime }} 
                                    </span>
                                    <span *ngIf="!record.jobOrderShift.shiftStartDateTime">-</span>
                                </td>
                                <td style="width: 100px" class="date-time-column">
                                    <span class="ui-column-title"> {{ l('EndTime') }}</span>
                                    <span *ngIf="record.jobOrderShift.shiftEndDateTime"
                                    title="Local: {{record.localTenantEndDateTime | formatStringToTime : record.jobOrderShift.shiftEndDateTime}}">
                                        {{record.jobOrderShift.locationEndDateTime | formatStringToTime : record.jobOrderShift.shiftEndDateTime }} 
                                    </span>
                                    <span *ngIf="!record.jobOrderShift.shiftEndDateTime">-</span>
                                </td>
                                <td style="width: 130px" class="date-time-column">
                                    <span class="ui-column-title"> {{ l('AssignedDate') }}</span>
                                    <span>
                                        {{ record.jobOrderShift.assignedDate | luxonFormat:'EEE MM/dd/yyyy' }}
                                    </span>
                                </td>

                            </tr>
                        </ng-template>
                    </p-table>
                    <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                        {{ l('NoData') }}
                    </div>
                    <div class="primeng-paging-container">
                        <p-paginator [rows]="primengTableHelper.defaultRecordsCountPerPage" #paginator
                            (onPageChange)="getAllMissingTimeEntriesShifts($event)"
                            [totalRecords]="primengTableHelper.totalRecordsCount"
                            [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage">
                        </p-paginator>
                        <span class="total-records-count">
                            {{ l('TotalRecordsCount', primengTableHelper.totalRecordsCount) }}
                        </span>
                    </div>
                </div>
                <!--<Primeng-Datatable-End>-->
            </div>
        </div>
    </div>
    <employeeProfileModal #employeeProfileModal> </employeeProfileModal>
</div>