<div [@routerTransition] class="mt-3">
    <div class="primeng-datatable-timecard" [busyIf]="primengTableHelper.isLoading">
    <p-table #dataTable (onLazyLoad)="getTimeCardApproval(dateRange, userId, tenantId, null)"  rows="{{ primengTableHelper?.defaultRecordsCountPerPage }}"
        [paginator]="false"  [lazy]="true" [scrollable]="true" ScrollWidth="100%" [responsive]="primengTableHelper?.isResponsive" [resizableColumns]="primengTableHelper?.resizableColumns" [value]="this.primengTableHelper?.records"
        [style]="{height: 'calc(100vh - 149px)'}" scrollHeight="flex">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 80px;" class="bg-light-dark" pSortableColumn="location">{{ l('CustomerLocation')
                    }}
                    <p-sortIcon field="location"></p-sortIcon>
                </th>
                <th style="width: 80px;" class="bg-light-dark" pSortableColumn="employeeName">{{ l('AssosiateName') }}
                    <p-sortIcon field="employeeName"></p-sortIcon>
                </th>
                <th style="width: 80px;" class="bg-light-dark" pSortableColumn="date">{{ l('Date') }}
                    <p-sortIcon field="date"></p-sortIcon>
                </th>
                <th style="width: 115px;" class="bg-light-dark">
                    <div class="d-flex justify-content-around"> <span>{{ l('ContractIn') }}</span><span>{{
                            l('ContractOut') }}</span></div>
                </th>
                <th style="width: 115px;" class="bg-light-dark">
                    <div class="d-flex justify-content-around"> <span>{{ l('ContractIn') }}</span><span>{{
                            l('ContractOut') }}</span></div>
                </th>
                <th style="width: 115px;" class="bg-light-dark">
                    <div class="d-flex justify-content-around"> <span>{{ l('ContractIn') }}</span><span>{{
                            l('ContractOut') }}</span></div>
                </th>
                <!-- <th style="width: 50px;" class="bg-light-dark" pSortableColumn="clockHours">{{ l('HoursWorked') }}
                    <p-sortIcon field="clockHours"></p-sortIcon>
                </th> -->
                <th style="width: 50px;" class="bg-light-dark" pSortableColumn="enteredHours">{{ l('HoursWorked')
                    }}
                    <p-sortIcon field="enteredHours"></p-sortIcon>
                </th>
                <th style="width: 47px;" class="bg-light-dark">{{ l('Notes') }}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-timeCard let-editing="editing" let-rowIndex="rowIndex">
            <tr >
                <td style="width: 80px;" data-toggle="tooltip" title="{{ timeCard.location }}"
                    data-placement="bottom">
                    <span class="p-column-title">{{'CustomerLocation' | localize}}</span>
                    {{timeCard.location}}
                </td>
                <td style="width: 80px;" class="text-truncate" data-toggle="tooltip" title="{{ timeCard.employeeName }}" data-placement="bottom">
                    <span class="p-column-title">{{'AssosiateName' | localize}}</span>
                    {{timeCard.employeeName}}
                </td>
                <td style="width: 80px;" data-toggle="tooltip" data-placement="right"
                    title="Local: {{timeCard.localTenantShiftDate | formatStringToDate : timeCard.date }}">
                    <span class="p-column-title">{{'Date' | localize}}</span>
                    {{timeCard.locationShiftDate | formatStringToDate : timeCard.date }}</td>
                <td style="width: 115px;">
                    <span class="p-column-title w-100">
                        <div class=" d-flex justify-content-around"> <span>{{ l('ContractIn') }}</span><span>
                                {{ l('ContractOut') }}</span></div>
                    </span>
                    <div class="d-flex justify-content-center">
                        <span *ngIf="timeCard.checkInTimeFirst || timeCard.checkOutTimeFirst" class="mr-1 border text-center w-50 py-2">
                            {{ timeCard.checkInTimeFirst | luxonFormat: 'hh:mm a'}}
                        </span>
                        <span  *ngIf="timeCard.checkInTimeFirst || timeCard.checkOutTimeFirst"  class="ml-1 border text-center w-50 py-2">
                            {{ timeCard.checkOutTimeFirst | luxonFormat: 'hh:mm a'}}
                        </span>
                    </div>
                </td>
                <td style="width: 115px;">
                    <span class="p-column-title w-100">
                        <div class=" d-flex justify-content-around"> <span>{{ l('ContractIn') }}</span><span>
                                {{ l('ContractOut') }}</span></div>
                    </span>
                    <div class="d-flex justify-content-center">
                        <span *ngIf="timeCard.checkInTimeSecond || timeCard.checkOutTimeSecond" class="mr-1 border text-center w-50 py-2">
                            {{ timeCard.checkInTimeSecond | luxonFormat: 'hh:mm a'}}
                        </span>
                        <span *ngIf="timeCard.checkInTimeSecond || timeCard.checkOutTimeSecond" class="ml-1 border text-center w-50 py-2">
                            {{ timeCard.checkOutTimeSecond | luxonFormat: 'hh:mm a'}}
                        </span>
                    </div>
                </td>
                <td style="width: 115px;">
                    <span class="p-column-title w-100">
                        <div class=" d-flex justify-content-around"> <span>{{ l('ContractIn') }}</span><span>
                                {{ l('ContractOut') }}</span></div>
                    </span>
                    <div class="d-flex justify-content-center">
                        <span *ngIf="timeCard.checkInTimeThird || timeCard.checkOutTimeThird" class="mr-1 border text-center w-50 py-2">
                            {{ timeCard.checkInTimeThird | luxonFormat: 'hh:mm a'}}
                        </span>
                        <span *ngIf="timeCard.checkInTimeThird || timeCard.checkOutTimeThird"  class="ml-1 border text-center w-50 py-2">
                            {{ timeCard.checkOutTimeThird | luxonFormat: 'hh:mm a'}}
                        </span>
                    </div>
                </td>
                <!-- <td style="width: 50px;">
                    <span class="p-column-title">{{'HoursWorked' | localize}}</span>
                    {{timeCard.clockHours | number : '1.2-2'}}
                </td> -->
                <td style="width: 50px;">
                    <span class="p-column-title">{{'PayableHours' | localize}}</span>
                    {{timeCard.enteredHours | number : '1.2-2'}}
                </td>
                <td style="width: 40px;">
                    <span class="p-column-title">{{'Notes' | localize}}</span>
                    <i class="fa fa-eye ms-1" *ngIf="timeCard.customerNote" style="color:#187de4;cursor:pointer"
                        (click)="onNotesEditClick(timeCard)" [attr.aria-label]="l('View')"></i>
                </td>
            </tr>
        </ng-template>
    </p-table>
    </div>
    <!-- <jobOrderShiftBreaksForContract  #JobOrderShiftBreaks > </jobOrderShiftBreaksForContract> -->
    <editNotesForContractTimeCardModal (modalSave)="onNotesSave($event)" #EditNotesForContractTimeCardModal > </editNotesForContractTimeCardModal>

</div>

