<div bsModal #editNotesModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="editNotesModal" aria-hidden="true" [config]="{ backdrop: 'static' }">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <form *ngIf="active" #editBreakTimeForm="ngForm" novalidate (ngSubmit)="save()"
                autocomplete="off">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <span>{{ l('EditNotes') }}</span>
                    </h4>

                    <button type="button" class="close" (click)="close()" aria-label="Close" [disabled]="saving">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label for="enteredBreakTimeInput">{{ l('Notes') }} *</label>
                        <input type="text" [disabled]="isDisabeld" #enterNotesInput="ngModel"
                            id="enteredBreakTimeInput" class="form-control" [(ngModel)]="notes"
                            name="Notes" required  [placeholder]="l('EnterNotes')"/>
                        <validation-messages [formCtrl]="enterNotesInput"></validation-messages>
                    </div>
                    <div class="modal-footer">
                        <button [disabled]="saving" type="button" class="btn btn-default" (click)="close()">
                            {{ l('Cancel') }}
                        </button>
                        <button type="submit" *ngIf="!isDisabeld" class="btn btn-primary blue"
                            [disabled]="!notes" [buttonBusy]="saving"
                            [busyText]="l('SavingWithThreeDot')">
                            <i class="fa fa-save"></i> <span>{{ l('Save') }}</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>