import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { EmployeeProfileDetailServiceProxy } from '@shared/service-proxies/service-proxies';
import { LazyLoadEvent } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'employeeApprovedTimeOffRequest',
    templateUrl: './employee-approved-time-off-request.component.html',
    styleUrls: ['./employee-approved-time-off-request.component.css']
})
export class EmployeeApprovedTimeOffRequest extends AppComponentBase {
    @ViewChild('dataTable', { static: true }) dataTable: Table;
    @ViewChild('paginator', { static: true }) paginator: Paginator;

    @Input() employeeId: number;
    constructor(
        injector: Injector,
        private _employeeProfileDetailservice: EmployeeProfileDetailServiceProxy,
    ) {
        super(injector);
    }

    getApproveTimeOffRequestJobOrder(event?: LazyLoadEvent) {
        if (this.primengTableHelper.shouldResetPaging(event)) {
            this.paginator.changePage(0);
            return;
        }

        this.primengTableHelper.showLoadingIndicator();

        this._employeeProfileDetailservice
            .getApprovedTimeOffRequestByEmployeeId(
                this.employeeId,
                this.primengTableHelper.getSorting(this.dataTable),
                this.primengTableHelper.getSkipCount(this.paginator, event),
                this.primengTableHelper.getMaxResultCount(this.paginator, event)
            ).pipe(finalize(() => {
                this.primengTableHelper.hideLoadingIndicator();
            }))
            .subscribe((result) => {
                this.primengTableHelper.totalRecordsCount = result.totalCount;
                this.primengTableHelper.records = result.items;
            });
    }
    deleteJobOrder(JobOrderId){
        this.message.confirm('', this.l('AreYouSure'), (isConfirmed) => {
            if (isConfirmed) {
                this._employeeProfileDetailservice.deleteApprovedTimeOffRequestJobOrder(JobOrderId).subscribe(() => {
                    this.reloadPage();
                    abp.event.trigger('EmployeeProfile.Updated');
                    this.notify.success(this.l('SuccessfullyDeleted'));
                });
            }
        });
    }
    reloadPage(): void {
        this.paginator.changePage(this.paginator.getPage());
    }

}
