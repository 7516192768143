import { Component, EventEmitter, Injector, Output, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-late-corresponds-message-model',
  templateUrl: './late-corresponds-message-model.component.html'
})
export class LateCorrespondsMessageModelComponent extends AppComponentBase {
  @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;
  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
  lateCorrespondenceMsg: string;
  active = false;
  saving = false;
  constructor(private injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
  }

  save() {
    this.saving = true;
    this.modalSave.emit(this.lateCorrespondenceMsg);
    this.close();
    this.saving = false;
  }
  show() {
    this.lateCorrespondenceMsg = "";
    this.active = true;
    this.modal.show();
  }
  close(): void {
    this.active = false;
    this.modal.hide();
  }
}
