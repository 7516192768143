<div bsModal #createOrEditModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="createOrEditModal" aria-hidden="true" [config]="{ backdrop: 'static' }">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    <span>{{ l('Details') }}</span>
                </h4>
                <button type="button" class="close" (click)="close()" aria-label="Close">
                    <i aria-hidden="true" class="ki ki-close"></i>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-6 mb-3">
                        <h6>{{ l('RejectedDateTime') }} :</h6>
                        {{ item.shiftRejection.rejectedDateTime | luxonFormat: 'EEE MM/dd/yyyy hh:mm a' }}
                    </div>
                    <div class="col-6 mb-3">
                        <h6>{{ l('RejectedByChannel') }} :</h6>
                        {{ l(communicationChannel[item.shiftRejection.rejectedByChannel]) }}
                    </div>
                    <div class="col-6 mb-3">
                        <h6>{{ l('Comments') }} :</h6>
                        {{ item.shiftRejection.comments }}
                    </div>
                    <div class="col-6 mb-3">
                        <h6>{{ l('RejectionType') }} :</h6>
                        {{ l(shiftRejectionType[item.shiftRejection.rejectionType]) }}
                    </div>

                    <div class="col-6 mb-3">
                        <h6>{{ l('JobOrderShift') }} :</h6>
                        {{ item.jobOrderShiftName }}
                    </div>
                    <div class="col-6 mb-3">
                        <h6>{{ l('Employee') }} :</h6>
                        {{ item.employeeFirstName }}
                    </div>
                    <div class="col-6 mb-3">
                        <h6>{{ l('JobOrder') }} :</h6>
                        {{ item.jobOrderName }}
                    </div>
                    <div class="col-6 mb-3">
                        <h6>{{ l('NoCallNoShow') }} :</h6>
                        <div *ngIf="item.shiftRejection.noCallNoShow" class="text-left">
                            <i class="fa fa-check-circle text-success" title="True"></i>
                        </div>
                        <div *ngIf="!item.shiftRejection.noCallNoShow" class="text-left">

                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="saving" type="button" class="btn btn-default" (click)="close()">
                    {{ l('Close') }}
                </button>
            </div>
        </div>
    </div>
</div>