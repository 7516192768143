import { WidgetMissingTimeEntriesListComponent } from './widgets/widget-missing-time-entries-list/widget-missing-time-entries-list.component';
import { WidgetCallOutListComponent } from './widgets/widget-call-out-list/widget-call-out-list.component';
import { Injectable, OnInit } from '@angular/core';
import { WidgetViewDefinition, WidgetFilterViewDefinition } from './definitions';
import { DashboardCustomizationConst } from './DashboardCustomizationConsts';
import { WidgetGeneralStatsComponent } from './widgets/widget-general-stats/widget-general-stats.component';
import { WidgetDailySalesComponent } from './widgets/widget-daily-sales/widget-daily-sales.component';
import { WidgetProfitShareComponent } from './widgets/widget-profit-share/widget-profit-share.component';
import { WidgetMemberActivityComponent } from './widgets/widget-member-activity/widget-member-activity.component';
import { WidgetRegionalStatsComponent } from './widgets/widget-regional-stats/widget-regional-stats.component';
import { WidgetIncomeStatisticsComponent } from './widgets/widget-income-statistics/widget-income-statistics.component';
import { WidgetRecentTenantsComponent } from './widgets/widget-recent-tenants/widget-recent-tenants.component';
import { WidgetEditionStatisticsComponent } from './widgets/widget-edition-statistics/widget-edition-statistics.component';
import { WidgetSubscriptionExpiringTenantsComponent } from './widgets/widget-subscription-expiring-tenants/widget-subscription-expiring-tenants.component';
import { WidgetHostTopStatsComponent } from './widgets/widget-host-top-stats/widget-host-top-stats.component';
import { FilterDateRangePickerComponent } from './filters/filter-date-range-picker/filter-date-range-picker.component';
import { WidgetTopStatsComponent } from './widgets/widget-top-stats/widget-top-stats.component';
import { WidgetOpenJobOrderListComponent } from './widgets/widget-open-job-order-list/widget-open-job-order-list.component';
import { WidgetSalesSummaryComponent } from './widgets/widget-sales-summary/widget-sales-summary.component';
import { WidgetTimeCardReviewListComponent } from './widgets/widget-time-card-review-list/widget-time-card-review-list.component';
import { WidgetPendingOffTimeRequestsListComponent } from './widgets/widget-pending-off-time-requests-list/widget-pending-off-time-requests-list.component';
import { WidgetMissingCheckInsListComponent } from './widgets/widget-missing-check-ins-list/widget-missing-check-ins-list.component';
import { WidgetInvitationAcceptanceListComponent } from './widgets/widget-invitation-acceptance-list/widget-invitation-acceptance-list';
@Injectable({
    providedIn: 'root'
})
export class DashboardViewConfigurationService {
    public WidgetViewDefinitions: WidgetViewDefinition[] = [];
    public widgetFilterDefinitions: WidgetFilterViewDefinition[] = [];

    constructor(
    ) {
        this.initializeConfiguration();
    }

    private initializeConfiguration() {
        let filterDateRangePicker = new WidgetFilterViewDefinition(
            DashboardCustomizationConst.filters.filterDateRangePicker,
            FilterDateRangePickerComponent
        );
        //add your filters here
        this.widgetFilterDefinitions.push(filterDateRangePicker);

        let generalStats = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.generalStats,
            WidgetGeneralStatsComponent,
            6,
            4
        );

        let dailySales = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.dailySales,
            WidgetDailySalesComponent,
        );

        let profitShare = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.profitShare,
            WidgetProfitShareComponent
        );

        let memberActivity = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.memberActivity,
            WidgetMemberActivityComponent,
        );

        let regionalStats = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.regionalStats,
            WidgetRegionalStatsComponent
        );

        let salesSummary = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.salesSummary,
            WidgetSalesSummaryComponent,
        );

        let topStats = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.topStats,
            WidgetTopStatsComponent,
        );
        //add your tenant side widgets here

        let incomeStatistics = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.host.incomeStatistics,
            WidgetIncomeStatisticsComponent,
        );

        let editionStatistics = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.host.editionStatistics,
            WidgetEditionStatisticsComponent,
        );

        let recentTenants = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.host.recentTenants,
            WidgetRecentTenantsComponent,
        );

        let subscriptionExpiringTenants = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.host.subscriptionExpiringTenants,
            WidgetSubscriptionExpiringTenantsComponent
        );

        let hostTopStats = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.host.topStats,
            WidgetHostTopStatsComponent,
        );

        let openJobOrderList = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.openJobOrderList,
            WidgetOpenJobOrderListComponent,
        );

        let callOutList = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.callOutList,
            WidgetCallOutListComponent,
        );

        let missingTimeEntriesList = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.missingTimeEntriesList,
            WidgetMissingTimeEntriesListComponent,
        );
        let missingCheckInsList = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.missingCheckInsList,
            WidgetMissingCheckInsListComponent,
        );

        let timCardReviewList = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.timCardReviewList,
            WidgetTimeCardReviewListComponent,
        );

        let pendingOffTimeList = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.pendingOffTimeList,
            WidgetPendingOffTimeRequestsListComponent,
        );
        let invitationAcceptanceList = new WidgetViewDefinition(
            DashboardCustomizationConst.widgets.tenant.invitationAcceptanceList,
            WidgetInvitationAcceptanceListComponent,
        );

        //add your host side widgets here

        this.WidgetViewDefinitions.push(openJobOrderList);
        this.WidgetViewDefinitions.push(missingTimeEntriesList);
        this.WidgetViewDefinitions.push(callOutList);
        this.WidgetViewDefinitions.push(timCardReviewList);
        this.WidgetViewDefinitions.push(pendingOffTimeList);
        this.WidgetViewDefinitions.push(generalStats);
        this.WidgetViewDefinitions.push(dailySales);
        this.WidgetViewDefinitions.push(profitShare);
        this.WidgetViewDefinitions.push(memberActivity);
        this.WidgetViewDefinitions.push(regionalStats);
        this.WidgetViewDefinitions.push(salesSummary);
        this.WidgetViewDefinitions.push(topStats);
        this.WidgetViewDefinitions.push(incomeStatistics);
        this.WidgetViewDefinitions.push(editionStatistics);
        this.WidgetViewDefinitions.push(recentTenants);
        this.WidgetViewDefinitions.push(subscriptionExpiringTenants);
        this.WidgetViewDefinitions.push(hostTopStats);
        this.WidgetViewDefinitions.push(missingCheckInsList);
        this.WidgetViewDefinitions.push(invitationAcceptanceList);
    }
}
