import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'formatStringToDateTime'
})
export class FormatStringToDateTimePipe implements PipeTransform {
  format='EEE MM/dd/yyyy hh:mm a';
  transform(value: string,fallback:any): string {
    if(value !=null){
    let datePart = value.split(' ')[0];
    let timePart = value.split(' ')[1];
    let timeZone = value.split(' ')[2];
    let year = datePart.split('-')[0];
    let month = datePart.split('-')[1];
    let day = datePart.split('-')[2];
    let hourPart = timePart.split(':')[0];
    let minutePart = timePart.split(':')[1];
    let formattedTimeStr = `${this.getDayOfWeek(year, month, day)} ${this.formatDate(month, day, year)} ${this.formatTime(hourPart, minutePart)}`;
    return timeZone? formattedTimeStr + ` (${timeZone})`: formattedTimeStr;
    }
    else if(fallback != null){
      let date = DateTime.fromISO(fallback);
      return date.toFormat(this.format);
    }
    return "";
  }

  getDayOfWeek(year: string, month: string, day: string): string {
    const days = ['Sun','Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const date = new Date(Date.UTC(parseInt(year),parseInt(month)-1,parseInt(day)));
    return days[date.getUTCDay()];
  }

  formatDate(month: string, day: string, year: string): string {
    return `${parseInt(month)}/${parseInt(day)}/${year}`;
  }

  formatTime(hour: string, minute: string): string {
    let formattedHour = parseInt(hour) > 12 ? `${parseInt(hour) - 12}` : parseInt(hour)==0 ? '12':hour ;
    let period = parseInt(hour) >= 12 ? 'PM' : 'AM';
    return `${formattedHour.padStart(2, '0')}:${minute} ${period}`;
  }
}
