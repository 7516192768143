<div class="row row-no-padding row-col-separator-xl h-110" [perfectScrollbar]>

    <div class="col pr-0" *ngIf="isGranted('Pages.OpenJobOrderWidget')">
        <div class="card card-custom bgi-no-repeat card-stretch new-subscription-statistics h-100">
            <div class="card-body">
                <div class="d-inline" [routerLink]="['/app/main/job-orders/job-Orders']">
                    <a href="javascript:;" class="text-left font-weight-bolder text-dark font-size-h6 text-hover-state-dark">
                        {{l('OpenJobOrders')}}
                    </a>
                </div>
                <span class="text-muted font-weight-bold text-left float-left col-12 p-0 mb-4">{{l('NeedAssignment')}}</span>
                <div class="text-left text-primary font-weight-bolder font-size-h2 mr-2">
                    <span counto [step]="30" [duration]="1" [countFrom]="1" [countTo]="openJobOrderCount">
                                {{openJobOrderCount}}
                            </span>
                </div>
                <div class="text-muted">
                    <span>{{widgetStatsTime+" "+l('Ago')}}</span>
                </div>
                <!-- <div class="progress progress-xs bg-primary-o-60">
                    <div class="progress-bar bg-primary" role="progressbar" style="width: 76%;" aria-valuenow="76" aria-valuemin="0" aria-valuemax="100"></div>
                </div> -->
                <!-- <div class="d-flex align-items-center justify-content-between mb-2">
                    <span class="text-muted mr-2 font-size-sm font-weight-bold">
                        Change
                    </span>
                    <span class="text-muted font-size-sm font-weight-bold">
                        <span class="counterup">{{dashboardTopStats.totalProfitChange}}</span>%
                    </span>timeCard
                </div> -->
            </div>
        </div>
    </div>

    <div class="col pr-0" *ngIf="isGranted('Pages.TimeCardReviewWidget')">
        <div class="card card-custom bgi-no-repeat card-stretch new-subscription-statistics h-100">
            <div class="card-body">
                <div class="d-inline" [routerLink]="['/app/main/timeCard']">
                    <a href="javascript:;" class="text-left font-weight-bolder text-dark font-size-h6 text-hover-state-dark">
                        {{l('TimeCardReview')}}
                     </a>
                </div>
                <span class="text-muted font-weight-bold text-left float-left col-12 p-0 mb-4">{{l('CustomerDisputed')}}</span>
                <div class="text-left text-info font-weight-bolder font-size-h2 mr-2">
                    <span counto [step]="300" [duration]="1" [countFrom]="1" [countTo]="timeCardReviewCount">
                            {{timeCardReviewCount}}
                            </span>
                </div>
                <div class="text-muted">
                    <span>{{widgetStatsTime+" "+l('Ago')}}</span>
                </div>
                <!-- <div class="progress progress-xs bg-info-o-60">
                    <div class="progress-bar bg-info" role="progressbar" style="width: 85%;" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                </div> -->
                <!-- <div class="d-flex align-items-center justify-content-between mb-2">
                    <span class="text-muted mr-2 font-size-sm font-weight-bold">
                        Change
                    </span>
                    <span class="text-muted font-size-sm font-weight-bold">
                        <span class="counterup">{{dashboardTopStats.newFeedbacks}}</span>%
                    </span>
                </div> -->
            </div>
        </div>
    </div>

    <!-- <div class="col pr-0" *ngIf="isGranted('Pages.MissingTimeEntriesWidget')">
        <div class="card card-custom bgi-no-repeat card-stretch new-subscription-statistics h-150">
            <div class="card-body">
                <div class="d-inline" [routerLink]="['/app/main/timeCard']">
                    <a href="javascript:;" class="text-left font-weight-bolder text-dark font-size-h6 text-hover-state-dark">
                        {{l('MissingTimeEntries')}}
                     </a>
                </div>
                <span class="text-muted font-weight-bold text-left float-left col-12 p-0 mb-4">{{l('MissingCheckIns')}}</span>
                <div class="text-left text-info font-weight-bolder font-size-h2 mr-2"> -->
                    <!-- <span counto [step]="30" [duration]="1" [countFrom]="0" [countTo]="missingTimeEntriesCount" (countoChange)="missingTimeEntriesCount = $event"> -->
                    <!-- <span counto [step]="30" [duration]="1" [countFrom]="0" [countTo]="missingTimeEntriesCount">
                            {{missingTimeEntriesCount}}
                            </span>
                </div>
                <div class="text-muted">
                    <span>{{widgetStatsTime+" "+l('Ago')}}</span>
                </div> -->
                <!-- <div class="progress progress-xs bg-info-o-60">
                    <div class="progress-bar bg-info" role="progressbar" style="width: 85%;" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                </div> -->
                <!-- <div class="d-flex align-items-center justify-content-between mb-2">
                    <span class="text-muted mr-2 font-size-sm font-weight-bold">
                        Change
                    </span>
                    <span class="text-muted font-size-sm font-weight-bold">
                        <span class="counterup">{{dashboardTopStats.newFeedbacks}}</span>%
                    </span>
                </div> -->
            <!-- </div>
        </div>
    </div> -->
    <div class="col pr-0" *ngIf="isGranted('Pages.MissingTimeEntriesWidget')">
        <div class="card card-custom bgi-no-repeat card-stretch new-subscription-statistics h-150">
            <div class="card-body">
                <div class="d-inline" [routerLink]="['/app/main/timeCard']">
                    <a href="javascript:;" class="text-left font-weight-bolder text-dark font-size-h6 text-hover-state-dark">
                        {{l('MissingCheckIns')}}
                     </a>
                </div>
                <span class="text-muted font-weight-bold text-left float-left col-12 p-0 mb-4">{{l('Last8Hours')}}</span>
                <div class="text-left text-danger font-weight-bolder font-size-h2 mr-2">
                    <!-- <span counto [step]="30" [duration]="1" [countFrom]="0" [countTo]="missingTimeEntriesCount" (countoChange)="missingTimeEntriesCount = $event"> -->
                    <span counto [step]="30" [duration]="1" [countFrom]="0" [countTo]="missingCheckinCount">
                            {{missingCheckinCount}}
                            </span>
                </div>
                <div class="text-muted">
                    <span>{{widgetStatsTime+" "+l('Ago')}}</span>
                </div>
                <!-- <div class="progress progress-xs bg-info-o-60">
                    <div class="progress-bar bg-info" role="progressbar" style="width: 85%;" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                </div> -->
                <!-- <div class="d-flex align-items-center justify-content-between mb-2">
                    <span class="text-muted mr-2 font-size-sm font-weight-bold">
                        Change
                    </span>
                    <span class="text-muted font-size-sm font-weight-bold">
                        <span class="counterup">{{dashboardTopStats.newFeedbacks}}</span>%
                    </span>
                </div> -->
            </div>
        </div>
    </div>

    <div class="col pr-0" *ngIf="isGranted('Pages.CallOutWidget')">
        <div class="card card-custom bgi-no-repeat card-stretch new-subscription-statistics h-100">
            <div class="card-body">
                <div class="d-inline" [routerLink]="['/app/main/job-orders/job-Orders']">
                    <a href="javascript:;" class="text-left font-weight-bolder text-dark font-size-h6 text-hover-state-dark">
                        {{l('CallOuts')}}
                     </a>
                </div>
                <span class="text-muted font-weight-bold text-left float-left col-12 p-0 mb-4">{{l('Last24Hours')}}</span>
                <div class="text-left text-danger font-weight-bolder font-size-h2 mr-2">
                    <span counto [step]="30" [duration]="1" [countFrom]="1" [countTo]="callOutCount">
                                {{callOutCount}}
                    </span>
                </div>
                <div class="text-muted">
                    <span>{{widgetStatsTime+" "+l('Ago')}}</span>
                </div>
                <!-- <div class="progress progress-xs bg-danger-o-60">
                    <div class="progress-bar bg-danger" role="progressbar" style="width: 45%;" aria-valuenow="45" aria-valuemin="0" aria-valuemax="100"></div>
                </div> -->
                <!-- <div class="d-flex align-items-center justify-content-between mb-2">
                    <span class="text-muted mr-2 font-size-sm font-weight-bold">
                        Change
                    </span>
                    <span class="text-muted font-size-sm font-weight-bold">
                        <span class="counterup">{{dashboardTopStats.newOrdersChange}}</span>%
                    </span>
                </div> -->
            </div>
        </div>
    </div>

    <div class="col" *ngIf="isGranted('Pages.PendingOffTimeWidget')">
        <div class="card card-custom bgi-no-repeat card-stretch new-subscription-statistics h-100" >
            <div class="card-body">
                <div class="d-inline" [routerLink]="['/app/main/offTimeRequests/offTimeRequests']" [queryParams]="{filterText:1}">
                    <a href="javascript:;" class="text-left font-weight-bolder text-dark font-size-h6 text-hover-state-dark">
                        {{l('PendingOffTimeRequests')}}
                     </a>
                </div>
                <div class="d-inline cursor-pointer float-right refresh-button"> <i class="fa fa-sync" (click)="getWidgetStats()"></i> </div>
                <span class="text-muted font-weight-bold text-left float-left col-12 p-0 mb-4">{{l('NeedApproval')}}</span>
                <div class="text-left text-success font-weight-bolder font-size-h2 mr-2">
                    <span counto [step]="30" [duration]="1" [countFrom]="1" [countTo]="pendingTimeOffCount">
                            {{pendingTimeOffCount}}
                        </span>
                </div>
                <div class="text-muted">
                    <span>{{widgetStatsTime+" "+l('Ago')}}</span>
                </div>
                <!-- <div class="progress progress-xs bg-success-o-60">
                    <div class="progress-bar bg-success" role="progressbar" style="width: 57%;" aria-valuenow="57" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-2">
                    <span class="text-muted mr-2 font-size-sm font-weight-bold">
                        Change
                    </span>
                    <span class="text-muted font-size-sm font-weight-bold">
                        <span class="counterup">{{dashboardTopStats.newUsersChange}}</span>%
                    </span>
                </div> -->
            </div>
        </div>
    </div>

</div>