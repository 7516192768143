<div [@routerTransition]>
    <div class="card card-custom h-100">
        <div class="card-header align-items-center border-0">
            <h3 class="card-title align-items-start flex-column">
                <span class="font-weight-bolder text-dark">
                    {{l('CallOut')}}
                </span>
            </h3>
            <div class="card-toolbar">
                <button class="btn btn-primary" (click)="reloadPage()"><i class="fa fa-sync"></i> </button>
            </div>
        </div>
        <div class="card-body" [perfectScrollbar]>
            <div class="row list-separated text-center" style="min-height: 565px !important;">
                <!--<Primeng-Datatable-start>-->
                <div class="primeng-datatable-container col-12" [busyIf]="primengTableHelper.isLoading">
                    <p-table #dataTable  scrollHeight="400px" id="custom-yscrollable-td" (onLazyLoad)="getCallOutJobOrderShifts($event)"
                        [value]="primengTableHelper.records" rows="{{ primengTableHelper.defaultRecordsCountPerPage }}"
                        [paginator]="false" [lazy]="true" [scrollable]="true" ScrollWidth="100%"
                        [responsive]="primengTableHelper.isResponsive"
                        [resizableColumns]="primengTableHelper.resizableColumns">
                        <ng-template pTemplate="header">
                            <tr>
                                <!-- <th style="width: 130px" [hidden]="
                                            !isGrantedAny(

                                                'Pages.ShiftRejections.Delete'
                                            )
                                        ">
                                    {{ l('Actions') }}
                                </th> -->
                                <th style="width: 120px" pSortableColumn="jobOrderFk.id">
                                    {{ l('JobOrderId') }}
                                    <p-sortIcon field="id"></p-sortIcon>
                                </th>
                                <th style="width: 120px" pSortableColumn="jobOrderFk.sourceId">
                                    {{ l('NewJobOrder') }}
                                    <p-sortIcon field="sourceId"></p-sortIcon>
                                </th>
                                <th style="width: 150px" pSortableColumn="employeeFk.fullName">
                                    {{ l('Employee') }}
                                    <p-sortIcon field="employeeFullName"></p-sortIcon>
                                </th>

                                <th style="width: 150px" pSortableColumn="jobOrderShiftFk.shiftDate">
                                    {{ l('ShiftDate') }}
                                    <p-sortIcon field="shiftDate"></p-sortIcon>
                                </th>

                                <th style="width: 150px" pSortableColumn="comments">
                                    {{ l('Comments') }}
                                    <p-sortIcon field="shiftRejection.comments"></p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <!-- Comment Table data of Edit Action  'Pages.ShiftRejections.Edit', -->
                        <ng-template pTemplate="body" let-record="$implicit" let-i="rowIndex">
                            <tr>
                                <!-- <td style="width: 130px" [hidden]="
                                            !isGrantedAny(

                                                'Pages.ShiftRejections.Delete'
                                            )
                                        ">
                                    <div class="btn-group dropdown" dropdown container="body">
                                        <button class="dropdown-toggle btn btn-sm btn-primary" dropdownToggle>
                                                <i class="fa fa-cog"></i><span class="caret"></span>
                                                {{ l('Actions') }}
                                            </button>
                                        <ul class="dropdown-menu" *dropdownMenu>
                                            <li>
                                                <a href="javascript:;" class="dropdown-item" (click)="viewShiftRejectionModal.show(record)">{{ l('View') }}</a
                                                    >
                                                </li>
                                            </ul>
                                        </div>
                                    </td> -->
                                    <td style="width: 120px" class="long-string-column">
                                        <a *ngIf="record.jobOrderName !== null" class="cursor-pointer short-string-column"
                                          [routerLink]="['/app/main/job-Order/job-Order-detail']"
                                          [queryParams]="{jobID:record.jobOrderName}">
                                          <span class="ui-column-title">{{ l('JobOrder') }}</span> #{{ record.jobOrderName }}
                                        </a>
                                        <a *ngIf="record.jobOrderName === null" class="cursor-pointer short-string-column"
                                          [routerLink]="['/app/main/job-Order/job-Order-detail']"
                                          [queryParams]="{jobID:record.jobOrderName}">
                                          <span class="ui-column-title">{{ l('JobOrder') }}</span>
                                        </a>
                                      </td>
                                <td style="width: 120px" class="long-string-column">
                                    <a class="cursor-pointer short-string-column"
                                        [routerLink]="['/app/main/job-Order/job-Order-detail']"
                                        [queryParams]="{jobID:record.sourceName}"
                                        *ngIf="record.sourceName!=null||record.sourceName!=''"> <span
                                            class="ui-column-title"> {{ l('NewJobOrder') }}</span> #{{ record.sourceName
                                        }}
                                    </a>
                                </td>
                                <td style="width: 150px" class="long-string-column">
                                    <ng-template #popTemplate>
                                        <div class="font-weight-bold">{{employeePopOverName}}</div>
                                        <div class="mt-3 symbol symbol-50 symbol-lg-120"><img class="symbol-label"
                                                [src]="employeeImage" alt="ImageMissing"></div>
                                    </ng-template>
                                    <a [routerLink]="['/app/main/employees/employees']"
                                        [queryParams]="{filterText:record.employeeFirstName}" class="cursor-pointer">
                                        <span class="ui-column-title"> {{ l('Employee') }}</span> <span container="body"
                                            (mouseover)="getEmployeePopoverDetail(record.employeeFirstName)"
                                            (mouseout)="clearEmployeePopoverDetail(employeePopOverTimeOut)"
                                            triggers="mouseenter:mouseleave" placement="right"
                                            [popover]="popTemplate">{{ record.employeeFirstName }}</span>
                                    </a>
                                <td style="width: 150px" class="date-time-column" data-toggle="tooltip"
                                    title="{{  record.shiftRejection.shiftDate | luxonFormat:'EEE MM/dd/yyyy hh:mm a' }}"
                                    data-placement="right">
                                    <span class="ui-column-title"> {{ l('RejectionDateTime') }}</span>
                                    <span *ngIf="record.shiftRejection.rejectedDateTime"
                                    title="Local: {{record.shiftRejection.locationStartDateTime | formatStringToDateTime : record.shiftRejection.shiftDate}}">
                                        {{record.shiftRejection.locationStartDateTime | formatStringToDateTime : record.shiftRejection.shiftDate }} 
                                        {{getTimeDifference(record.shiftRejection.locationStartDateTime,record.shiftRejection.rejectedDateTime)}}
                                        before
                                    </span>
                                    <span *ngIf="!record.shiftRejection.rejectedDateTime">-</span>
                                </td>
                                <td style="width: 150px" class="multiline-text-column" data-toggle="tooltip"
                                    title="{{ record.shiftRejection.comments }}" data-placement="right">
                                    <span class="ui-column-title"> {{ l('Comments') }}</span> {{
                                    record.shiftRejection.comments }}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                        {{ l('NoData') }}
                    </div>
                    <div class="primeng-paging-container">
                        <p-paginator [rows]="primengTableHelper.defaultRecordsCountPerPage" #paginator
                            (onPageChange)="getCallOutJobOrderShifts($event)"
                            [totalRecords]="primengTableHelper.totalRecordsCount"
                            [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage">
                        </p-paginator>
                        <span class="total-records-count">
                            {{ l('TotalRecordsCount', primengTableHelper.totalRecordsCount) }}
                        </span>
                    </div>
                </div>
                <!--<Primeng-Datatable-End>-->
            </div>
        </div>
    </div>
    <employeeProfileModal #employeeProfileModal> </employeeProfileModal>
    <viewShiftRejectionModal #viewShiftRejectionModal></viewShiftRejectionModal>
</div>