<div class="container-fluid pt-5 pb-5">

    <div>
        <div class="card card-custom gutter-b">
            <div class="card-body card-body pb-5 pt-5">
                <div class="row">
                    <div class="filtersRow ">
                        <div class="row">
                            </div>
                            <div class="col-xl-6 pr-12">
                                <label for="" class="form-label">{{ 'PayPeriod' }}</label>
                                <div class="d-flex">
                                    <i class="col-1 custom-border-color p-0 d-flex p-inputgroup-addon rounded-left flaticon-calendar"
                                        data-toggle="tooltip" placement="bottom" tooltip="Filter pay period"></i>
                                    <select autoFocus size="1" (change)="applyChanges()"
                                        class="customBorderRadius col-10 form-control" name="payRollPeriodId"
                                        [(ngModel)]="payRollPeriodId">
                                        <option *ngFor="let payrollPeriod of payrollPeriods"
                                            [value]="payrollPeriod.value">
                                            {{payrollPeriod.label}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--begin::Entry-->
        <!--begin::Entry-->
        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div id="timeCarWizard">
                <div class="card card-custom">
                    <div class="card-body p-0">
                        <div #wizard class="wizard wizard-3" id="kt_wizard_v3" data-wizard-state="step-first">
                            <div class="kt-grid__item">
                                <!--begin: Form Wizard Nav -->
                                <div class="wizard-nav border-bottom">
                                    <div class="wizard-steps  px-15 row flex-row flex-sm-nowrap align-items-end">
                                            <a class="wizard-step col-md-6" href="javascript:;" data-wizard-type="step"
                                                data-wizard-state="current">
                                                <div class="wizard-label" (click)="wizardStepChange(1)">
                                                    <div class="wizard-title">{{l('Pending')}}<span
                                                            class="font-size-h6-xxl font-size-h6-xxl label label-warning"
                                                            *ngIf="staffReviewcount>0">{{staffReviewcount}}</span></div>
                                                    <div class="wizard-bar"></div>
                                                </div>
                                            </a>
                                            <a class="wizard-step col-md-6 " href="javascript:;" data-wizard-type="step"
                                                (click)="wizardStepChange(2)">
                                                <div class="wizard-label">
                                                    <div class="wizard-title">{{l('Approved')}}&nbsp;<span
                                                            class="font-size-h6-xxl font-size-h6-xxl label label-primary"
                                                            *ngIf="readyForCustomercount>0">{{readyForCustomercount}}</span>
                                                    </div>
                                                    <div class="wizard-bar"></div>
                                                </div>
                                            </a>
                                        </div>
                                </div>
                                <!--end: Form Wizard Nav -->
                            </div>
                            <div class="justify-content-center px-lg-10">
                                <div class="col-xl-12">
                                    <!--begin: Form Wizard Form-->
                                    <form class="form" id="kt_form">

                                        <!--begin: Form Wizard Step 2-->
                                        <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                            <customerStaffPendingContractV2TimeCards #pendingContractTimeCard
                                                (totalCount)="$event.checkTabCount &&(setTabsCount(2));staffReviewcount=$event.count;"
                                                >
                                            </customerStaffPendingContractV2TimeCards>
                                        </div>
                                        <!--end: Form Wizard Step 2-->

                                        <!--begin: Form Wizard Step 2-->
                                        <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                            <contractApprovedTimeCardsV2 #approvedContractTimeCard
                                                (totalCount)="$event.checkTabCount &&(setTabsCount(2));readyForCustomercount=$event.count;"
                                                >
                                            </contractApprovedTimeCardsV2>
                                        </div>
                                        <!--end: Form Wizard Step 2-->

                                        <!--end: Form Actions -->
                                    </form>
                                    <!--end: Form Wizard Form-->
                                </div>
                            </div>

                        </div>
                        <!--end::Wizard-->
                    </div>
                </div>
                <!--end::Container-->
            </div>
        </div>
    </div>
    <!--end::Entry-->
