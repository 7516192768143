import { Component, Injector, Input, Output, EventEmitter, ViewChild, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ContractEmailApprovalTimeCardsServiceProxy, CreateOrEditShiftBreakConfigurationDto, CustomerApprovalStatus, SendAssociateReminderInputDto } from '@shared/service-proxies/service-proxies';
import { DateTime } from 'luxon';
import { LazyLoadEvent } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { finalize } from 'rxjs/operators';
import { AppConsts } from '@shared/AppConsts';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { JobOrderShiftBreaksForContractComponent } from '@app/shared/common/shift-breaks/jobOrderShiftBreaks/jobOrderShiftBreaksForContract.component';
import { EditNotesForContractTimeCardModal } from '@app/staff/edit-notes-for-contract-time-card-modal/edit-notes-for-contract-time-card-modal.component';

@Component({
    selector: 'contractApprovedTimeCardsV2',
    templateUrl: './contract-approved-v2-time-card.component.html',
    styleUrls: ['./contract-approved-v2-time-card.component.css'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()]
})
export class ContractApprovedTimeCardsV2 extends AppComponentBase {
    @Output() totalCount = new EventEmitter<any>();
    @ViewChild('dataTable', { static: true }) dataTable: Table;
    @ViewChild('paginator', { static: true }) paginator: Paginator;
    @ViewChild('JobOrderShiftBreaks', { static: true }) JobOrderShiftBreaks: JobOrderShiftBreaksForContractComponent;
    @ViewChild('EditNotesForContractTimeCardModal', { static: true }) editNotesForContractTimeCardModal: EditNotesForContractTimeCardModal;

    @Input() selectedPayRollPeriod: DateTime;
    @Input() missingTimeEntries: string;
    @Input() showSelectedPayRollPeriod: boolean = false;

    payrollPeriodId: number = undefined;
    employeePopOverTimeOut: any;
    sendReminderToEmployee: SendAssociateReminderInputDto = new SendAssociateReminderInputDto();
    customerApprovalStatus: CustomerApprovalStatus;
    employeeDetailForPopOver: any;
    employeeImage = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
    expanded: false;
    employeePopOverName: any;
    selectedAll = false;
    userId: number;
    jobOrderId: number;
    tenantId: number;
    payCodeFilter:string;
    cutOffFilterEnabled: boolean = true;
    businessUnitId: number;
    checkedRecordLength:number;
    showOnlyMyJobOrders: boolean=false;
    public dateRange: DateTime[] = [this._dateTimeService.getStartOfDayMinusDays(30), this._dateTimeService.getEndOfDay()];

    constructor(
        injector: Injector,
        private readonly _timecardService: ContractEmailApprovalTimeCardsServiceProxy,
        private _dateTimeService: DateTimeService,
        private cd: ChangeDetectorRef
    ) {
        super(injector);
    }

    getTimeCardApproval(dateRange: DateTime[], userId?: number, tenantId?:number, clockIssue?:string, payPeriodId?: number, selectedPayRollPeriod?: DateTime) {
        this.userId = userId;
        this.tenantId = tenantId;
        this.primengTableHelper.records = [];
        this.dateRange = dateRange;
        if (payPeriodId != undefined)this.payrollPeriodId = payPeriodId;
        this.selectedPayRollPeriod = selectedPayRollPeriod;
        this.selectedAll=false;
        if (this.userId && this.tenantId) {
            this.primengTableHelper.showLoadingIndicator();
            this._timecardService.getApprovedTimeCardsV2(
                this.payrollPeriodId,
                this.selectedPayRollPeriod,
                this.primengTableHelper?.getSorting(this.dataTable),
                this.userId,
                this.tenantId,
                clockIssue
            ).pipe(finalize(() => {
                this.primengTableHelper.hideLoadingIndicator();
            })).subscribe((result) => {
                this.primengTableHelper.records = result.items;
                this.primengTableHelper.records.map(item=>{
                    item.breakConfigurations.map(b=>{
                        b.breakTime = item.breakTimeOfSifts.find(item2 => item2.shiftBreakConfigurationId == b.id )?.breakTime ;
                    })
                });
                this.cd.markForCheck();
                this.totalCount.emit({
                    count:this.primengTableHelper.records.length,
                    checkTabCount:false
                });
            });
        }
    }

    tabCountChange(shiftId: number | number[]){
        this.selectedAll=false;
        let shiftIds = Array.isArray(shiftId) ? shiftId : [shiftId];
        this.primengTableHelper.records = this.primengTableHelper.records.filter(item => !shiftIds.includes(item.shiftId));
        this.totalCount.emit({
            count:this.primengTableHelper.records.length,
            checkTabCount:true
        });
    }

    openShiftBreaksDetails(jobOrderShiftId:number, breakConfigurations: CreateOrEditShiftBreakConfigurationDto[], employeeId: number){
        this.JobOrderShiftBreaks.show(jobOrderShiftId, breakConfigurations, employeeId, false);
       }

       onNotesEditClick(record){
        this.editNotesForContractTimeCardModal.show(record.associateNote,record.customerNote,record.staffNote,record.shiftId, true);
       }

       onNotesSave(obj){
        if(obj.shiftId && obj.customerNote){
          let index = this.primengTableHelper.records.findIndex(f=>f.shiftId == obj.shiftId);
          if(index > -1){
             this.primengTableHelper.records[index].customerNote = obj.customerNote;
          }
        }
    }
}
