<div [@routerTransition]>
    <div class="card card-custom h-100">
        <div class="card-header align-items-center border-0">
            <h3 class="card-title align-items-start flex-column">
                <span class="font-weight-bolder text-dark">
                    {{l('OpenJobOrders')}}
                </span>
            </h3>
            <div class="card-toolbar">
                <button class="btn btn-primary" (click)="reloadPage()"><i class="fa fa-sync"></i> </button>
            </div>
        </div>
        <div class="card-body" [perfectScrollbar]>
            <div class="row list-separated text-center" style="min-height: 565px !important;">
                <!--<Primeng-Datatable-start>-->
                <div class="primeng-datatable-container col-12" [busyIf]="primengTableHelper.isLoading">
                    <p-table #dataTable scrollHeight="400px" id="custom-yscrollable-td" (onLazyLoad)="getOpenJobOrders($event)" [value]="primengTableHelper.records"
                        rows="{{ primengTableHelper.defaultRecordsCountPerPage }}" [paginator]="false" [lazy]="true"
                        [scrollable]="true" ScrollWidth="100%" [responsive]="primengTableHelper.isResponsive"
                        [resizableColumns]="primengTableHelper.resizableColumns">
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="id" style="width: 100px">
                                    {{ l('JobOrder') }}
                                    <p-sortIcon field="jobOrder.id"></p-sortIcon>
                                </th>

                                <th pSortableColumn="businessUnitFk.name" style="width: 130px">
                                    {{ l('BusinessUnit') }}
                                    <p-sortIcon field="businessUnitName"></p-sortIcon>
                                </th>
                                <!-- <th pSortableColumn="employeeFk.fullName" style="width: 120px">
                                    {{ l('Employee') }}
                                    <p-sortIcon field="employeeName"></p-sortIcon>
                                </th> -->
                                <th pSortableColumn="customerLocationFk.name" style="width: 150px">
                                    {{ l('Location') }}
                                    <p-sortIcon field="customerLocationName"></p-sortIcon>
                                </th>
                                <th pSortableColumn="standardShiftFk.name" style="width: 125px">
                                    {{ l('StandardShift') }}
                                    <p-sortIcon field="standardShiftName"></p-sortIcon>
                                </th>
                                <th pSortableColumn="frequencyType" style="width: 80px">
                                    {{ l('Shifts') }}
                                    <p-sortIcon field="jobOrder.frequencyType"></p-sortIcon>
                                </th>

                                <th pSortableColumn="startDate" style="width: 110px">
                                    {{ l('StartDate') }}
                                    <p-sortIcon field="jobOrder.startDate"></p-sortIcon>
                                </th>
                                <th pSortableColumn="endTime" style="width: 110px">
                                    {{ l('EndDate') }}
                                    <p-sortIcon field="jobOrder.endTime"></p-sortIcon>
                                </th>
                                <th pSortableColumn="spreadValue" style="width: 120px">
                                    {{ l('SpreadValue') }}
                                    <p-sortIcon field="jobOrder.spreadValue"></p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-record="$implicit" let-i="rowIndex">
                            <tr>
                                <td style="width: 100px">
                                    <a class="cursor-pointer short-string-column"
                                        [routerLink]="['/app/main/job-Order/job-Order-detail']"
                                        [queryParams]="{jobID:record.jobOrder.id}"> <span class="ui-column-title"> {{
                                            l('JobOrder') }}</span> #{{ record.jobOrder.id }}
                                    </a>
                                    <i class="fa-square far text-warning font-size-h3-lg" data-toggle="tooltip"
                                        title="Open job order" data-placement="right" aria-hidden="true"
                                        *ngIf="record.jobOrder.jobOrderStatus==0"></i>
                                    <i class="flaticon-interface-5 text-primary font-size-h3-lg" data-toggle="tooltip"
                                        title="Assigned job order" data-placement="right" aria-hidden="true"
                                        *ngIf="record.jobOrder.jobOrderStatus==1"></i>
                                    <i class="fas fa-check-double text-success font-size-h5" data-toggle="tooltip"
                                        title="Completed job order" data-placement="right" aria-hidden="true"
                                        *ngIf="record.jobOrder.jobOrderStatus==2"></i>
                                    <i class="fa fa-ban text-danger font-size-h5" data-toggle="tooltip"
                                        title="Canceled job order" data-placement="right" aria-hidden="true"
                                        *ngIf="record.jobOrder.jobOrderStatus==3"></i>

                                </td>

                                <td style="width: 130px" class="long-string-column" data-toggle="tooltip"
                                    title="{{ record.businessUnitName }}" data-placement="right">
                                    <span class="ui-column-title"> {{ l('BusinessUnit') }}</span> {{
                                    record.businessUnitName }}
                                </td>
                                <!-- <td style="width: 120px" class="long-string-column" data-toggle="tooltip"
                                    title="{{ record.employeeName }}" data-placement="right">
                                    <a (click)="employeeProfileModal.show(record?.jobOrder?.employeeId)"
                                        class="cursor-pointer">
                                        <span class="ui-column-title"> {{ l('Employee') }}</span> {{ record.employeeName
                                        }}
                                    </a>
                                </td> -->
                                <td style="width: 150px" class="long-string-column" data-toggle="tooltip"
                                    title="{{ record.customerLocationName }}" data-placement="right">
                                    <span class="ui-column-title"> {{ l('Location') }}</span> {{
                                    record.customerLocationName }}
                                </td>
                                <td style="width: 125px" class="long-string-column">
                                    <span class="ui-column-title"> {{ l('StandardShift') }}</span> {{
                                    record.standardShiftName }}
                                </td>
                                <td style="width: 80px" class="long-string-column">
                                    <span *ngIf="record?.jobOrder?.recurringType==1" data-toggle="tooltip"
                                        title=" End Date" data-placement="right" data-original-title="Repeat forever"><i
                                            class="flaticon-infinity" data-toggle="tooltip" title="Forever job order"
                                            data-placement="right" aria-hidden="true"></i> </span>
                                    <span *ngIf="record?.jobOrder?.recurringType!=1"><span class="ui-column-title"> {{
                                            l('Shifts') }}</span> {{record.jobOrder.shiftCount}}</span>
                                </td>

                                <td style="width: 110px" class="date-time-column">
                                    <span class="ui-column-title"> {{ l('StartDate') }}</span>
                                   <span  title="Local: {{record.localTenantStartDateTime | formatStringToDate : record.jobOrder.startDate}}">
                                       {{record.jobOrder.locationStartDateTime | formatStringToDate : record.jobOrder.startDate}} 
                                   </span> 
                                </td>
                                <td style="width: 110px" class="date-time-column">
                                    <span class="ui-column-title"> {{ l('EndDate') }}</span>
                                    <span *ngIf="record?.jobOrder?.recurringType==1" data-toggle="tooltip"
                                        title=" End Date" data-placement="right" data-original-title=" End Date">
                                    </span>
                                    <span *ngIf="record?.jobOrder?.recurringType!=1">    </span>
                                    <span title="Local: {{record.localTenantEndDateTime | formatStringToDate : record.jobOrder.endDate}}">
                                        {{record.jobOrder.locationEndDateTime | formatStringToDate : record.jobOrder.endDate}}
                                    </span>

                                </td>
                                <td style="width: 120px" class="number-column">
                                    <span class="ui-column-title"> {{ l('SpreadValue') }}</span>
                                    ${{record.jobOrder.spreadValue| number:'1.2-2'}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                        {{ l('NoData') }}
                    </div>
                    <div class="primeng-paging-container">
                        <p-paginator [rows]="primengTableHelper.defaultRecordsCountPerPage" #paginator
                            (onPageChange)="getOpenJobOrders($event)"
                            [totalRecords]="primengTableHelper.totalRecordsCount"
                            [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage">
                        </p-paginator>
                        <span class="total-records-count">
                            {{ l('TotalRecordsCount', primengTableHelper.totalRecordsCount) }}
                        </span>
                    </div>
                </div>
                <!--<Primeng-Datatable-End>-->
            </div>
        </div>
    </div>
    <employeeProfileModal #employeeProfileModal> </employeeProfileModal>
</div>