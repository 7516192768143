import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerTimeCardsRoutingModule } from './customer-time-cards-routing.module';
import { FormsModule } from '@angular/forms';
import { AppCommonModule } from '@app/shared/common/app-common.module';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { CustomerTimeCardsComponent } from './customer-time-cards.component';
import { CustomerStaffPendingContractV2TimeCards } from './customer-staff-pending-contract-time-cards-v2/customer-staff-pending-contract-time-cards-v2.component';
import { ContractApprovedTimeCardsV2 } from './contract-approved-v2-time-card/contract-approved-v2-time-card.component';
import { AppSharedModule } from '@app/shared/app-shared.module';
import { EditNotesForContractTimeCardModal } from './edit-notes-for-contract-time-card-modal/edit-notes-for-contract-time-card-modal.component';
import { TimeFormatPublicDirective } from './directives/time-format-public.directive';




@NgModule({
  declarations: [
    CustomerTimeCardsComponent,
    ContractApprovedTimeCardsV2,
    CustomerStaffPendingContractV2TimeCards,
    EditNotesForContractTimeCardModal,
    TimeFormatPublicDirective
],
  imports: [
    CommonModule,
    CustomerTimeCardsRoutingModule,
    AppCommonModule,
    TooltipModule,
    AutoCompleteModule,
    FormsModule,
    TableModule,
    AppSharedModule
  ]
})
export class CustomerTimeCardsModule { }
