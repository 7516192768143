import { AppComponentBase } from '@shared/common/app-component-base';
import { OnDestroy, Injector, Component } from '@angular/core';
import { timer, Subscription } from 'rxjs';
import { CommonLookupServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppConsts } from '@shared/AppConsts';

@Component({ template: '' })
export abstract class WidgetComponentBaseComponent extends AppComponentBase implements OnDestroy {
    commonLookup: CommonLookupServiceProxy;
    delay = 300;
    timer: Subscription;

    constructor(injector: Injector) {
        super(injector);
        this.commonLookup=injector.get(CommonLookupServiceProxy);
    }

    /**
     * Run methods delayed. If runDelay called multiple time before its delay, only run last called.
     * @param method Method to call
     */
     getEmployeeProfileForPopover(employeeId) {
        return new Promise(resolve=>{
            this.commonLookup.getEmployeeUserPicture(employeeId)
             .subscribe((result) => {
                resolve(result);
             })
        })
    }
    clearEmployeePopoverDetail(employeePopOverTimeOut){
        clearTimeout(employeePopOverTimeOut);
    }
    getEmployeeProfilePictureForPopover(result){
        if(result.profilePicture=="")
        {
            return AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
        }
        else{
            return 'data:image/jpeg;base64,' +result.profilePicture;
        }
    }
    runDelayed(method: () => void) {
        if (this.timer && !this.timer.closed) {
            this.timer.unsubscribe();
        }

        this.timer = timer(this.delay).subscribe(() => {
            method();
        });
    }

    ngOnDestroy(): void {
        if (this.timer && !this.timer.closed) {
            this.timer.unsubscribe();
        }
    }
}
