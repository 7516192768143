<div [@routerTransition]>
    <div class="card card-custom h-100" style="max-height: 90vh; overflow: hidden!important;">
        <div class="card-header align-items-center border-0">
            <h3 class="card-title align-items-start flex-column">
                <span class="font-weight-bolder text-dark">
                    {{l('TimeCardReview')}}
                </span>
            </h3>
            <div class="card-toolbar">
                <div class="d-flex form-group justify-content-center mb-0 pb-0 px-8 px-lg-15 py-8 py-lg-3">
                    <button class="btn btn-primary" name="ReadyForCustomer"
                        [disabled]="readyForCustomerReviewInputDto?.jobOrderShiftIds?.length == 0"
                        *ngIf="isGranted('Pages.ReadyForCustomer')" (click)="setReadyForCustomer()">
                        {{l('ReadyForCustomer')}}
                    </button>
                </div>
                <button class="btn btn-primary" (click)="getDisputedTimeCard()"><i class="fa fa-sync"></i> </button>
            </div>
        </div>
        <div class="card-body" [perfectScrollbar] [busyIf]="primengTableHelper?.isLoading">
            <div class="row list-separated text-center">
                <!-- <div class="row list-separated text-center" style="min-height: 565px !important;"> -->
                <!--<Primeng-Datatable-start>-->

                <p-table dataKey="uniqueColumn" [rows]="10" [paginator]="true" [showCurrentPageReport]="true"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    [rowsPerPageOptions]="[5,10,25,50,100,250,500]" [value]="primengTableHelper?.records" [tableStyle]="{'min-width': '100%'}">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 70px;">{{ l('Details') }}</th>
                            <th style="width: 70px;" pSortableColumn="childCount">{{ l('Shifts') }}
                                <p-sortIcon field="childCount"></p-sortIcon>
                            </th>
                            <th style="width: 70px;" pSortableColumn="customerDisputed">{{ l('Disputed') }}
                                <p-sortIcon field="customerDisputed"></p-sortIcon>
                            </th>
                            <th style="width: 100px;" pSortableColumn="clockIssues">{{ l('ClockIssues') }}
                                <p-sortIcon field="clockIssues"></p-sortIcon>
                            </th>
                            <th style="width: 100px;" pSortableColumn="hoursDiff">{{ l('hoursDiff') }}
                                <p-sortIcon field="hoursDiff"></p-sortIcon>
                            </th>
                            <!-- <th style="width: 100px;" pSortableColumn="missingTimEntries">{{ l('MissingTimEntries') }}
                                    <p-sortIcon field="MissingTimEntries"></p-sortIcon>
                                </th> -->
                            <th style="width: 100px;" pSortableColumn="employeeName">{{ l('Associate') }}
                                <p-sortIcon field="employeeName"></p-sortIcon>
                            </th>
                            <th style="width: 100px;" pSortableColumn="location">{{ l('Location') }}
                                <p-sortIcon field="location"></p-sortIcon>
                            </th>
                            <th style="width: 100px;" pSortableColumn="oldestDate">{{ l('OldestShift') }}
                                <p-sortIcon field="oldestDate"></p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-timeCard let-expanded="expanded">
                        <tr>
                            <td style="width: 70px;">
                                <button type="button" pButton pRipple [pRowToggler]="timeCard"
                                    class="p-button-text p-button-rounded p-button-plain"
                                    [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                            </td>
                            <td style="width: 70px;">{{timeCard.childCount}}</td>
                            <td style="width: 70px;">{{timeCard.customerDisputed}}</td>
                            <td style="width: 100px;">
                                {{timeCard.clockIssues}}
                            </td>
                            <td style="width: 100px;" [ngClass]="{'text-danger' :timeCard.hoursDiff>0}">
                                {{timeCard.hoursDiff| number : '1.2-2'}}</td>
                            <!-- <td style="width: 100px;">{{timeCard.missingTimeEntries}}</td> -->
                            <ng-template #popTemplate>
                                <div class="font-weight-bold">{{employeePopOverName}}</div>
                                <div class="mt-3 symbol symbol-50 symbol-lg-120"><img class="symbol-label"
                                        [src]="employeeImage" alt="ImageMissing"></div>
                            </ng-template>
                            <td style="width: 100px;" class="text-primary text-decoration-underline cursor-pointer"
                                [routerLink]="['/app/main/employees/employees']"
                                [queryParams]="{filterText:timeCard.employeeName}"><span container="body"
                                    (mouseover)="getEmployeePopoverDetail(timeCard.employeeId)"
                                    (mouseout)="clearEmployeePopoverDetail(employeePopOverTimeOut)"
                                    triggers="mouseenter:mouseleave" placement="right"
                                    [popover]="popTemplate">{{timeCard.employeeName}}</span></td>
                            <td style="width: 100px;" data-toggle="tooltip" title="{{ timeCard.location }}"
                                data-placement="right">{{timeCard.location}}</td>
                            <td style="width: 100px;">{{timeCard.oldestDate| luxonFormat: 'EEE MM/dd/yyyy'}}</td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="rowexpansion" let-timeCard>
                        <tr>
                            <td colspan="8">
                                <div class="p-p-3">
                                    <p-table [value]="timeCard.staffTimeCardApprovalDetailDto" dataKey="id">
                                        <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 120px;" pSortableColumn="date">{{ l('Date') }}
                                <p-sortIcon field="date"></p-sortIcon>
                            </th>
                            <th style="width: 120px;">{{ l('AssignedHours') }}
                                <!-- <p-sortIcon field="assignedHours"></p-sortIcon> -->
                            </th>
                            <th style="width: 120px;">{{ l('EnteredHours') }}
                                <!-- <p-sortIcon field="enteredHours"></p-sortIcon> -->
                            </th>
                            <th style="width: 120px;">{{ l('ClockHours') }}
                                <!-- <p-sortIcon field="clockHours"></p-sortIcon> -->
                            </th>
                            <th style="width: 100px;">{{ l('LateArrival') }}
                                <!-- <p-sortIcon field="lateArrival"></p-sortIcon> -->
                            </th>
                            <th style="width: 150px;">{{ l('Location') }}
                                <!-- <p-sortIcon field="location"></p-sortIcon> -->
                            </th>
                            <th>
                                {{ l('DisputeMessage') }}
                                <!-- <p-sortIcon field="message"></p-sortIcon> -->
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-timeCard2 let-rowIndex="rowIndex">
                        <tr>
                            <td style="width: 120px;">
                                {{timeCard2.date| luxonFormat: 'EEE MM/dd/yyyy'}}
                                <i *ngIf="timeCard2.customerApprovalStatus==2" data-toggle="tooltip"
                                    title="Customer disputed" data-placement="right"
                                    class="flaticon-warning-sign text-warning font-size-h5" aria-hidden="true"></i>
                            </td>
                            <td style="width: 120px;">
                                {{timeCard2.assignedHours| number : '1.2-2'}}
                            </td>
                            <td style="width: 120px;">
                                {{timeCard2.enteredHours| number : '1.2-2'}}
                            </td>
                            <td style="width: 100px;">
                                <span *ngIf="timeCard2.enteredHours!=undefined && timeCard2.clockHours!=undefined">
                                    {{ timeCard2.clockHours| number : '1.2-2' }}
                                </span>
                                <span *ngIf="timeCard2.enteredHours!=undefined && timeCard2.clockHours==undefined">
                                    Manual
                                </span>
                            </td>
                            <td *ngIf="timeCard2.lateDuration!=0 &&timeCard2.lateDuration!=null" style="width: 100px;"
                                data-toggle="tooltip"
                                title="{{ timeCard2.lateDuration | humanizeDuration:{ delimiter: ' ', largest:2,round: true } }}"
                                data-placement="right">
                                <span *ngIf="timeCard2.lateDuration!=null && timeCard2.lateDuration<0">{{l('
                                    Before')}}</span>
                                <span *ngIf="timeCard2.lateDuration!=null && timeCard2.lateDuration>0">{{ l('
                                    Late')}}</span>
                                <span *ngIf="timeCard2.lateDuration!=null && timeCard2.lateDuration!=0"> {{
                                    timeCard2.lateDuration | humanizeDuration:{ delimiter: ' ', largest:2,round: true }
                                    }}</span>
                            </td>
                            <td *ngIf="timeCard2.lateDuration==0" style="width: 100px;" data-toggle="tooltip"
                                title="On time" data-placement="right">
                                <span *ngIf="timeCard2.lateDuration==0 ">{{l('OnTime')}}</span>
                            </td>
                            <td style="width: 100px;" *ngIf="timeCard2.lateDuration==null">
                                <span *ngIf="timeCard2.lateDuration==null"></span>
                            </td>
                            <td style="width: 150px; " data-toggle="tooltip" title="{{timeCard2.location}}"
                                data-placement="right">
                                {{timeCard2.location}}
                            </td>
                            <td data-toggle="tooltip" title="{{timeCard2.customerNote}}" data-placement="right">
                                {{timeCard2.customerNote}}
                            </td>
                        </tr>
                        <tr *ngIf="rowIndex==timeCard.staffTimeCardApprovalDetailDto?.length-1 ">
                            <td></td>
                            <td style="font-weight:bold ">{{timeCard.totalAssignedHours| number : '1.2-2'}}</td>
                            <td style="font-weight:bold ">{{timeCard.totalEnteredHours| number : '1.2-2'}}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            </td>

            </tr>
            </ng-template>
            </p-table>
            <!-- <timeEntityMessageAssociate #timeEntityMessageAssociate (modalSave)="getDisputedTimeCard() "></timeEntityMessageAssociate> -->
            <!-- <employeeProfileModal #employeeProfileModal> </employeeProfileModal> -->
            <!--<Primeng-Datatable-End>-->
        </div>
    </div>
</div>
<employeeProfileModal #employeeProfileModal> </employeeProfileModal>
</div>