import { AbpHttpInterceptor, RefreshTokenService, AbpHttpConfigurationService } from 'abp-ng2-module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';
import { ZeroRefreshTokenService } from '@account/auth/zero-refresh-token.service';
import { ZeroTemplateHttpConfigurationService } from './zero-template-http-configuration.service';

@NgModule({
    providers: [
        ApiServiceProxies.JobOrderShiftNotesServiceProxy,
        ApiServiceProxies.PaperTimeCardsServiceProxy,
        ApiServiceProxies.KioskDeviceConfigurationsServiceProxy,
        ApiServiceProxies.JODemandBoardCategoryGroupsServiceProxy,
        ApiServiceProxies.StateOvertimeRulesServiceProxy,
        ApiServiceProxies.StateServiceProxy,
        ApiServiceProxies.JobOrderDemandsServiceProxy,
        ApiServiceProxies.ShiftBreakConfigurationsServiceProxy,
        ApiServiceProxies.JobOrderShiftBreaksServiceProxy,
        ApiServiceProxies.BusinessUnitsEmployableBUsServiceProxy,
        ApiServiceProxies.BusinessUnitJobCategoriesServiceProxy,
        ApiServiceProxies.JobOrderShiftEventLogsServiceProxy,
        ApiServiceProxies.JobOrderRequestsServiceProxy,
        ApiServiceProxies.DynamicQueriesServiceProxy,
        ApiServiceProxies.JobOrderRatesServiceProxy,
        ApiServiceProxies.JORateHistoriesServiceProxy,
        ApiServiceProxies.MinimumWageRulesServiceProxy,
        ApiServiceProxies.CalendarTimezoneOffsetsServiceProxy,
        ApiServiceProxies.CalendarServiceProxy,
        ApiServiceProxies.TimeCardsProcessedServiceProxy,
        ApiServiceProxies.BlastNotificationsServiceProxy,
        ApiServiceProxies.CustomerTimeCardApprovalServiceProxy,
        ApiServiceProxies.StaffDashboardServiceProxy,
        ApiServiceProxies.WorkerCompsServiceProxy,
        ApiServiceProxies.JobCategoriesServiceProxy,
        ApiServiceProxies.OffTimeRequestsServiceProxy,
        ApiServiceProxies.EmailTemplatesServiceProxy,
        ApiServiceProxies.QueuedEmailsServiceProxy,
        ApiServiceProxies.CompanyEmployeesServiceProxy,
        ApiServiceProxies.TimeCardsServiceProxy,
        ApiServiceProxies.PayRollPeriodsServiceProxy,
        ApiServiceProxies.PayPeriodTypesesServiceProxy,
        ApiServiceProxies.JobOrderShiftAssignmentServiceProxy,
        ApiServiceProxies.ShiftCalendarServiceProxy,
        ApiServiceProxies.EmployeeProfileDetailServiceProxy,
        ApiServiceProxies.StaffTimeCardApprovalServiceProxy,
        ApiServiceProxies.AutoCompleteServiceProxy,
        ApiServiceProxies.TenantLocationLookupsServiceProxy,
        ApiServiceProxies.JobOrderInvitesServiceProxy,
        ApiServiceProxies.FeedbacksServiceProxy,
        ApiServiceProxies.ShiftAssignmentServiceProxy,
        ApiServiceProxies.ShiftAvailabilityTemplatesServiceProxy,
        ApiServiceProxies.InvitesServiceProxy,
        ApiServiceProxies.CustomerLocationContactsServiceProxy,
        ApiServiceProxies.JobOrderContactsServiceProxy,
        ApiServiceProxies.TimeEntriesServiceProxy,
        ApiServiceProxies.EmployeeRatingsServiceProxy,
        ApiServiceProxies.ShiftRejectionsServiceProxy,
        ApiServiceProxies.ShiftAvailabilitiesServiceProxy,
        ApiServiceProxies.AvailabilityPatternsServiceProxy,
        ApiServiceProxies.GeoAvailabilitiesServiceProxy,
        ApiServiceProxies.JobOrderShiftsServiceProxy,
        ApiServiceProxies.EmployeesServiceProxy,
        ApiServiceProxies.JobOrdersServiceProxy,
        ApiServiceProxies.StandardShiftsServiceProxy,
        ApiServiceProxies.CustomerRolesServiceProxy,
        ApiServiceProxies.ReferenceRolesServiceProxy,
        ApiServiceProxies.CustomerLocationsServiceProxy,
        ApiServiceProxies.LocalAreasServiceProxy,
        ApiServiceProxies.CustomerContactsServiceProxy,
        ApiServiceProxies.CustomersServiceProxy,
        ApiServiceProxies.BusinessUnitsServiceProxy,
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.ChatServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.DemoUiComponentsServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.PayPalPaymentServiceProxy,
        ApiServiceProxies.StripePaymentServiceProxy,
        ApiServiceProxies.DashboardCustomizationServiceProxy,
        ApiServiceProxies.WebhookEventServiceProxy,
        ApiServiceProxies.WebhookSubscriptionServiceProxy,
        ApiServiceProxies.WebhookSendAttemptServiceProxy,
        ApiServiceProxies.UserDelegationServiceProxy,
        ApiServiceProxies.DynamicPropertyServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyDefinitionServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyServiceProxy,
        ApiServiceProxies.DynamicPropertyValueServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyValueServiceProxy,
        ApiServiceProxies.TwitterServiceProxy,
        ApiServiceProxies.ErrorReportsServiceProxy,
        ApiServiceProxies.EmployeeAverageRatingsServiceProxy,
        ApiServiceProxies.ExternalJobOrderRequestsServiceProxy,
        ApiServiceProxies.ContractTimeCardApprovalServiceProxy,
        ApiServiceProxies.ContractTimeCardsV2ServiceProxy,
        ApiServiceProxies.ContractEmailApprovalTimeCardsServiceProxy,
        { provide: RefreshTokenService, useClass: ZeroRefreshTokenService },
        { provide: AbpHttpConfigurationService, useClass: ZeroTemplateHttpConfigurationService },
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }
    ]
})
export class ServiceProxyModule { }
