import { Component, Injector, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppConsts } from '@shared/AppConsts';
import {
    CommonLookupServiceProxy,
    StaffTimeCardApprovalDto,
    ReadyForCustomerReviewInputDto,
    StaffDashboardServiceProxy,
    StaffTimeCardApprovalServiceProxy,
    StaffTimeCardApprovalDetailDto,
} from '@shared/service-proxies/service-proxies';
import { LazyLoadEvent } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { finalize } from 'rxjs/operators';
import { WidgetComponentBaseComponent } from '../widget-component-base';

@Component({
    selector: 'app-widget-time-card-review-list',
    templateUrl: './widget-time-card-review-list.component.html',
    styleUrls: ['./widget-time-card-review-list.component.css'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
})
export class WidgetTimeCardReviewListComponent extends WidgetComponentBaseComponent implements OnInit {
    @ViewChild('dataTable', { static: true }) dataTable: Table;
    @ViewChild('paginator', { static: true }) paginator: Paginator;
    selectedAll = false;
    employeePopOverTimeOut: any;
    employeeImage = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
    employeeDetailForPopOver: any;
    expanded: false;
    readyForCustomerReviewInputDto: ReadyForCustomerReviewInputDto = new ReadyForCustomerReviewInputDto();
    employeePopOverName: string;

    constructor(
        injector: Injector,
        private readonly _timecardService: StaffTimeCardApprovalServiceProxy,
        private _commonLookupServiceProxy: CommonLookupServiceProxy,
        private _staffDashboardService: StaffDashboardServiceProxy
    ) {
        super(injector);
    }
    ngOnInit(): void {
        this.getDisputedTimeCard();
    }
    getEmployeePopoverDetail(employeeId): void {
        this.employeePopOverName = '';
        this.employeeImage = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
        this.employeePopOverTimeOut = setTimeout(() => {
            this.employeeDetailForPopOver = this.getEmployeeProfileForPopover(employeeId).then((result) => {
                this.employeeDetailForPopOver = result;
                this.employeePopOverName = this.employeeDetailForPopOver.userName;
                this.employeeImage = this.getEmployeeProfilePictureForPopover(result);
            });
        }, 1000);
    }

    getDisputedTimeCard() {
        this.primengTableHelper.showLoadingIndicator();
        this.primengTableHelper.records = [];
        this.readyForCustomerReviewInputDto.jobOrderShiftIds = [];

        this._staffDashboardService
            .getAllDisputedTimeCardReview(undefined, undefined, undefined)
            .pipe(
                finalize(() => {
                    this.primengTableHelper.hideLoadingIndicator();
                })
            )
            .subscribe((result) => {
                this.primengTableHelper.totalRecordsCount = result.totalCount;
                this.primengTableHelper.records = result.items;
            });
    }

    reloadPage(): void {
        this.paginator.changePage(this.paginator.getPage());
    }
    // showTimeEntryDetail(shiftId: number) {
    //     this.timeEntityMessageAssociate.show(shiftId, 'staff');
    // }

    checkUnCheckManual(record: StaffTimeCardApprovalDto, item: StaffTimeCardApprovalDetailDto) {
        var recordIndex = this.primengTableHelper.records.find(
            (x) =>
                x.employeeId == record.employeeId &&
                x.locationId == record.locationId &&
                x.employeeName == record.employeeName
        );
        recordIndex.isSelected = true;
        var subRecord = recordIndex.staffTimeCardApprovalDetailDto.find((a) => a.shiftId == item.shiftId);
        subRecord.isSelected = !item.isSelected;
        this.primengTableHelper.records.findIndex[recordIndex] = recordIndex;

        var obj = this.readyForCustomerReviewInputDto.jobOrderShiftIds.findIndex((x) => x == subRecord.shiftId);
        if (obj == -1) {
            this.readyForCustomerReviewInputDto?.jobOrderShiftIds.push(subRecord.shiftId);
        } else {
            this.readyForCustomerReviewInputDto?.jobOrderShiftIds?.splice(obj, 1);
        }
        if (!recordIndex.staffTimeCardApprovalDetailDto.find((x) => x.isSelected)) {
            recordIndex.isSelected = false;
        }
    }

    checkUncheckRecord(record: StaffTimeCardApprovalDto) {
        var recordIndex = this.primengTableHelper.records.find(
            (x) =>
                x.employeeId == record.employeeId &&
                x.locationId == record.locationId &&
                x.employeeName == record.employeeName
        );
        this.primengTableHelper.records.findIndex[recordIndex] = recordIndex;
        recordIndex.isSelected = !recordIndex.isSelected;

        recordIndex.staffTimeCardApprovalDetailDto.forEach((element) => {
            if (recordIndex.isSelected) {
                element.isSelected = true;
                this.readyForCustomerReviewInputDto?.jobOrderShiftIds.push(element.shiftId);
            } else {
                element.isSelected = false;
                var obj = this.readyForCustomerReviewInputDto.jobOrderShiftIds.findIndex((x) => x == element.shiftId);
                this.readyForCustomerReviewInputDto?.jobOrderShiftIds?.splice(obj, 1);
            }
        });
    }

    selectAll() {
        this.selectedAll = !this.selectedAll;
        this.readyForCustomerReviewInputDto.jobOrderShiftIds = [];
        this.primengTableHelper.records.forEach((record) => {
            record.isSelected = !this.selectedAll;
            this.checkUncheckRecord(record);
        });
    }
    setReadyForCustomer() {
        this.primengTableHelper.showLoadingIndicator();
        this._timecardService
            .setReadyForCustomerApproval(this.readyForCustomerReviewInputDto)
            .pipe(
                finalize(() => {
                    this.primengTableHelper.hideLoadingIndicator();
                    this.getDisputedTimeCard();
                })
            )
            .subscribe((result) => {
                this.notify.success(this.l('SuccessfullSatatusChanged'));
            });
    }
}
