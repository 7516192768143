<div [@routerTransition]>
    <div role="actions" class="buttons">
        <!-- <button class="btn btn-outline-primary" [disabled]="checkedRecordLength == 0" (click)="applyToAll()">
            {{l('ApplyToAll')}}</button> -->
        <button class="btn btn-success"  [disabled]="checkedRecordLength == 0" (click)="setReadyForApproval()">{{l('Approve')}}</button>
        <button class="btn btn-danger"  [disabled]="checkedRecordLength == 0" (click)="sendToCustomerDispute()">{{l('Reject')}}</button>
    </div>
    <div class="primeng-datatable-timecard" [busyIf]="primengTableHelper.isLoading">
    <p-table #dataTable [busyIf]="primengTableHelper?.isLoading" (onLazyLoad)="getTimeCardApproval(dateRange, userId, tenantId, null)" dataKey="uniqueColumn" rows="{{ primengTableHelper?.defaultRecordsCountPerPage }}"
        [paginator]="false"  [lazy]="true" [scrollable]="true" [responsive]="primengTableHelper?.isResponsive" [value]="this.primengTableHelper?.records" responsiveLayout="scroll"
        [style]="{height: 'calc(100vh - 149px)'}" scrollHeight="flex">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 30px;"  class="bg-light-dark">
                    <label for="record_job" class="checkbox d-inline-flex" checkbox d-inline-flex (click)="selectAll()">
                        <input *ngIf="!selectedAll"  id="SelectedAll" class="md-check" [(ngModel)]="selectedAll"
                        type="checkbox" name="SelectedAll" />
                        <input id="SelectedAll" *ngIf="selectedAll" class="md-check" [(ngModel)]="selectedAll"
                        type="checkbox" name="SelectedAll" checked/>
                        <span></span>
                </label>
                </th>
                <th style="width: 80px;" class="bg-light-dark" pSortableColumn="location">{{ l('CustomerLocation') }}
                    <p-sortIcon field="location"></p-sortIcon>
                </th>
                <th style="width: 80px;" class="bg-light-dark" pSortableColumn="employeeName">{{ l('AssosiateName') }}
                    <p-sortIcon field="employeeName"></p-sortIcon>
                </th>
                <th style="width: 80px;" class="bg-light-dark" pSortableColumn="date">{{ l('Date') }}
                    <p-sortIcon field="date"></p-sortIcon>
                </th>
                <th style="width: 115px;" class="bg-light-dark"><div class="d-flex justify-content-around"> <span>{{ l('ContractIn') }}</span><span>{{
                    l('ContractOut') }}</span></div>
                </th>
                <th style="width: 115px;" class="bg-light-dark"><div class="d-flex justify-content-around"> <span>{{ l('ContractIn') }}</span><span>{{
                    l('ContractOut') }}</span></div>
                </th>
                <th style="width: 115px;" class="bg-light-dark"><div class="d-flex justify-content-around"> <span>{{ l('ContractIn') }}</span><span>{{
                    l('ContractOut') }}</span></div>
                </th>
                <th style="width: 50px;" class="bg-light-dark" pSortableColumn="enteredHours">{{ l('HoursWorked') }}
                    <p-sortIcon field="enteredHours"></p-sortIcon>
                </th>
                <th style="width: 47px;" class="bg-light-dark">{{ l('Notes') }}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-timeCard>
            <tr [ngClass]="{'bg-light-danger':timeCard.customerApprovalStatus>0}">
                <td style="width: 30px;">
                    <label for="record_job" class="checkbox d-inline-flex" checkbox d-inline-flex (click)="checkUncheckRecord(timeCard)">
                        <input *ngIf="timeCard.isSelected" id="isSelected" class="md-check" checked type="checkbox" name="isSelected" />
                        <input *ngIf="!timeCard.isSelected" id="isSelected" class="md-check" type="checkbox" name="isSelected"/>
                        <span></span>
                    </label>
                </td>
                <td style="width: 80px;" data-toggle="tooltip" title="{{ timeCard.location }}" data-placement="bottom">
                    <span class="p-column-title">{{'CustomerLocation' | localize}}</span>
                    {{timeCard.location}}
                </td>
                <td style="width: 80px;" class="text-truncate" data-toggle="tooltip" title="{{ timeCard.employeeName }}" data-placement="bottom">
                    <span class="p-column-title">{{'AssosiateName' | localize}}</span>
                    {{timeCard.employeeName}}
                </td>
                <td style="width: 80px;" data-toggle="tooltip"  data-placement="right" title="Local: {{timeCard.localTenantShiftDate | formatStringToDate : timeCard.date }}">
                    <span class="p-column-title">{{'Date' | localize}}</span>
                    {{timeCard.locationShiftDate | formatStringToDate : timeCard.date }}
                </td>
                <td style="width: 115px;" data-toggle="tooltip"  data-placement="right">
                    <span class="p-column-title w-100">
                        <div class=" d-flex justify-content-around"> <span>{{ l('ContractIn') }}</span><span>
                                {{ l('ContractOut') }}</span></div>
                    </span>
                     <div class="d-flex">
                        <span class="mr-1">
                            <p-calendar appTimeFormatPublic  appendTo="body"
                            [(ngModel)]="timeCard.checkInJsTimeFirst" (onBlur)="onBlurInFirst($event, timeCard)" (onSelect)="onCheckinDataChangeFirst($event, timeCard)" hourFormat="12" #checkInJsTimeFirst="ngModel"
                            class="p-fluid form-control calendar-icon"  name="checkInJsTimeFirst" [timeOnly]="true" inputId="min-max" [showIcon]="true" [showOnFocus]="false"></p-calendar>
                            <span *ngIf="checkInJsTimeFirst.invalid && (checkInJsTimeFirst.dirty || checkInJsTimeFirst.touched)" class="error-message">
                                Invalid
                            </span>
                        </span>
                         <span class="ml-1">
                            <p-calendar appTimeFormatPublic appendTo="body"
                         [(ngModel)]="timeCard.checkOutJsTimeFirst" (onBlur)="onBlurOutFirst($event, timeCard)" (onSelect)="onCheckOutDataChangeFirst($event, timeCard)" hourFormat="12" #checkOutJsTimeFirst="ngModel"
                         class="p-fluid form-control calendar-icon"  name="checkOutJsTimeFirst" [timeOnly]="true" inputId="min-max" [showIcon]="true" [showOnFocus]="false"></p-calendar>
                         <span *ngIf="checkOutJsTimeFirst.invalid && (checkOutJsTimeFirst.dirty || checkOutJsTimeFirst.touched)" class="error-message">
                            Invalid
                        </span>
                         </span>
                     </div>
                </td>
                <td style="width: 115px;" data-toggle="tooltip"  data-placement="right">
                    <span class="p-column-title w-100">
                        <div class=" d-flex justify-content-around"> <span>{{ l('ContractIn') }}</span><span>
                                {{ l('ContractOut') }}</span></div>
                    </span>
                    <div class="d-flex">
                        <span class="mr-1">
                            <p-calendar appTimeFormatPublic appendTo="body"
                            [(ngModel)]="timeCard.checkInJsTimeSecond" (onBlur)="onBlurInSecond($event, timeCard)" (onSelect)="onCheckinDataChangeSecond($event, timeCard)" hourFormat="12" #checkInJsTimeSecond="ngModel"
                            class="p-fluid form-control calendar-icon" name="checkInJsTimeSecond" [timeOnly]="true" inputId="min-max" [showIcon]="true" [showOnFocus]="false"></p-calendar>
                            <span *ngIf="checkInJsTimeSecond.invalid && (checkInJsTimeSecond.dirty || checkInJsTimeSecond.touched)" class="error-message">
                                Invalid
                            </span>
                        </span>
                         <span class="ml-1">
                            <p-calendar appTimeFormatPublic appendTo="body"
                         [(ngModel)]="timeCard.checkOutJsTimeSecond" (onBlur)="onBlurOutSecond($event, timeCard)"  (onSelect)="onCheckOutDataChangeSecond($event, timeCard)" hourFormat="12" #checkOutJsTimeSecond="ngModel"
                         class="p-fluid form-control calendar-icon" name="checkOutJsTimeSecond" [timeOnly]="true" inputId="min-max" [showIcon]="true" [showOnFocus]="false"></p-calendar>
                         <span *ngIf="checkOutJsTimeSecond.invalid && (checkOutJsTimeSecond.dirty || checkOutJsTimeSecond.touched)" class="error-message">
                            Invalid
                        </span>
                         </span>
                     </div>
                </td>

                <td style="width: 115px;" data-toggle="tooltip"  data-placement="right">
                    <span class="p-column-title w-100">
                        <div class=" d-flex justify-content-around"> <span>{{ l('ContractIn') }}</span><span>
                                {{ l('ContractOut') }}</span></div>
                    </span>
                    <div class="d-flex">
                        <span class="mr-1">
                            <p-calendar appTimeFormatPublic appendTo="body"
                            [(ngModel)]="timeCard.checkInJsTimeThird" (onSelect)="onCheckinDataChangeThird($event, timeCard)" (onBlur)="onBlurInThird($event, timeCard)"  hourFormat="12" #checkInJsTimeThird="ngModel"
                            class="p-fluid form-control calendar-icon"  name="checkInJsTimeThird" [timeOnly]="true" inputId="min-max" [showIcon]="true" [showOnFocus]="false"></p-calendar>
                            <span *ngIf="checkInJsTimeThird.invalid && (checkInJsTimeThird.dirty || checkInJsTimeThird.touched)" class="error-message">
                                Invalid
                            </span>
                        </span>
                         <span class="ml-1">
                            <p-calendar appTimeFormatPublic appendTo="body"
                         [(ngModel)]="timeCard.checkOutJsTimeThird"  (onSelect)="onCheckOutDataChangeThird($event, timeCard)" (onBlur)="onBlurOutThird($event, timeCard)"  hourFormat="12" #checkOutJsTimeThird="ngModel"
                         class="p-fluid form-control calendar-icon"  name="checkOutJsTimeThird" [timeOnly]="true" inputId="min-max" [showIcon]="true" [showOnFocus]="false"></p-calendar>
                         <span *ngIf="checkOutJsTimeThird.invalid && (checkOutJsTimeThird.dirty || checkOutJsTimeThird.touched)" class="error-message">
                            Invalid
                        </span>
                         </span>
                     </div>
                </td>
                <td class="text-center" style="width: 50px;">
                    <span class="p-column-title">{{'HoursWorked' | localize}}</span>
                    {{ timeCard.enteredHours | number: '1.2-2' }}
                </td>
                <td style="width: 40px;">
                    <span class="p-column-title">{{'Notes' | localize}}</span>
                    <i class="fa fa-eye ms-1" *ngIf="timeCard.customerNote" style="color:#187de4;cursor:pointer" (click)="onNotesEditClick(timeCard)" [attr.aria-label]="l('Edit')"></i>
                    <i class="fa fa-edit ms-1" *ngIf="!timeCard.customerNote"  style="color:#187de4;cursor:pointer" (click)="onNotesEditClick(timeCard)" [attr.aria-label]="l('Edit')"></i>
                 </td>
            </tr>
        </ng-template>
    </p-table>
    </div>
    <!-- <jobOrderShiftBreaksForContract (modalSave)="onBreaksSave()" #JobOrderShiftBreaks > </jobOrderShiftBreaksForContract> -->
    <editNotesForContractTimeCardModal (modalSave)="onNotesSave($event)" #EditNotesForContractTimeCardModal > </editNotesForContractTimeCardModal>
</div>
