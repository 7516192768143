<div bsModal #createOrEditModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="createOrEditModal" aria-hidden="true" [config]="{ backdrop: 'static' }">
    <div class="modal-dialog view">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    <span>{{ l('TimeEntryDetailView') }}</span>
                </h4>
                <button type="button" class="close" (click)="close()" aria-label="Close">
                    <i aria-hidden="true" class="ki ki-close"></i>
                </button>
            </div>
            <div class="modal-body py-0" [busyIf]="primengTableHelper.isLoading">
                <div class="row position-relative">
                    <div class="background"></div>
                    <div class="col-12 mb-3 profile-container">
                        <div class="profile-picture">
                            <a [href]="item?.timeEntry?.selfieUrl" target="_blank">
                                <img class="cursor-pointer" [src]="item?.timeEntry?.selfieUrl ? item?.timeEntry?.selfieUrl : '../../../../assets/common/images/default-profile-picture.png'">
                            </a>
                         </div>

                        <div class="profile-info">
                            <div class="d-flex align-items-center">
                                <h3> {{ item.employeeFullName }}</h3>
                                <span *ngIf="item?.timeEntry?.isSystemCheckOut && !isManual" class="manual-status-indicator">System Checkout</span>
                                <span *ngIf="isManual" class="manual-status-indicator">Manual</span>

                            </div>
                            <div class="d-flex align-items-center">
                                <span class="flaticon2-location mr-2"
                                data-toggle="tooltip" placement="bottom" ></span>
                                <span>{{ item.customerLocationName }}</span>
                            </div>
                            <div *ngIf="item.shiftBreaksCount > 0">
                                <span class="ml-lg-6">{{ l('Breaks') }}:</span>
                                <a class="link-opacity-50-hover cursor-pointer" a-toggle="tooltip"
                                    title="{{ l('TotalBreakDuration') }} / {{ l('BreaksCount') }}"
                                    data-placement="bottom"  (click)="openShiftBreaksDetails(item.jobOrderShiftId)"> {{item.shiftTotalBreakTime +"/"+ item.shiftBreaksCount}} </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 mb-3" >
                        <div class="row">
                            <div class="pl-4">
                                <h6>{{ l('ClockInLocationTime') }}: </h6>
                            </div>
                            <div *ngIf="item?.timeEntry?.clockInDateTime && !item?.isContract">
                                <span *ngIf="item?.timeEntry?.isCheckInOnline" class="online-status-indicator">Online</span>
                                <span *ngIf="!item?.timeEntry?.isCheckInOnline" class="offline-status-indicator">Offline</span>
                            </div>
                        </div>
                        <div class="input-group">
                            <i class="icon col-1 p-0 d-flex p-inputgroup-addon rounded-left flaticon-calendar "
                            data-toggle="tooltip" placement="bottom" ></i>
                            <span class="value col-10 p-0 form-group">
                                <span class="ml-2 text" *ngIf="item?.timeEntry?.locationClockInDateTime || item?.timeEntry?.clockInDateTime">{{ item?.timeEntry?.locationClockInDateTime  | formatStringToDateTime : item?.timeEntry?.clockInDateTime}}</span>
                            </span>
                        </div>
                    </div>

                    <div class="col-4 mb-3">
                        <div class="row">
                            <div class="pl-4">
                                <h6>{{ l('ClockOutLocationTime') }}: </h6>
                            </div>
                            <div *ngIf="item?.timeEntry?.clockOutDateTime && !item?.isContract">
                                <span *ngIf="item?.timeEntry?.isCheckOutOnline" class="online-status-indicator">Online</span>
                                <span *ngIf="!item?.timeEntry?.isCheckOutOnline && !item?.timeEntry?.isSystemCheckOut" class="offline-status-indicator">Offline</span>
                            </div>
                        </div>
                           <div class="input-group">
                            <i class="icon col-1 p-0 d-flex p-inputgroup-addon rounded-left flaticon-calendar "
                            data-toggle="tooltip" placement="bottom" ></i>
                            <span class="value col-10 p-0 form-group">
                                <span class="ml-2 text" *ngIf="item?.timeEntry?.locationClockOutDateTime || item?.timeEntry?.clockOutDateTime">{{ item?.timeEntry?.locationClockOutDateTime  | formatStringToDateTime : item?.timeEntry?.clockOutDateTime}}</span>
                            </span>
                        </div>
                    </div>

                    <div class="col-4 mb-3">
                        <h6 >{{ l('JobOrderId') }}:</h6>
                        <div class="input-group">
                            <span class="icon p-inputgroup-addon rounded-left form-group">#</span>
                            <span class="value col-10 p-0 form-group">
                                <span class="ml-2">{{ item.jobOrderId }}</span>
                            </span>
                        </div>
                    </div>

                    <div class="col-4 mb-3">
                        <h6>{{ l('ClockInServerTime') }}:</h6>
                        <div class="input-group">
                            <i class="icon col-1 p-0 d-flex p-inputgroup-addon rounded-left flaticon-calendar "
                            data-toggle="tooltip" placement="bottom" ></i>
                            <span class="value col-10 p-0 form-group">
                                <span class="ml-2 text">{{ item?.clockInServerTimeString | formatStringToDateTime: item?.timeEntry?.clockInServerTime }}</span>
                            </span>
                        </div>

                    </div>
                    <div class="col-4 mb-3">
                        <h6>{{ l('ClockOutServerTime') }}:</h6>
                        <div class="input-group">
                            <i class="icon col-1 p-0 d-flex p-inputgroup-addon rounded-left flaticon-calendar "
                            data-toggle="tooltip" placement="bottom" ></i>
                            <span class="value col-10 p-0 form-group">
                                <span class="ml-2 text">{{ item?.clockOutServerTimeString  | formatStringToDateTime: item?.timeEntry?.clockOutServerTime }}</span>
                            </span>
                        </div>
                    </div>
                    <div class="col-4 mb-3">
                        <h6>{{ l('JobOrderShiftId') }}:</h6>
                        <div class="input-group">
                            <span class="icon p-inputgroup-addon rounded-left form-group">#</span>
                            <span class="value col-10 p-0 form-group">
                                <span class="ml-2">{{ item.jobOrderShiftId }}</span>
                            </span>
                        </div>
                    </div>

                    <div class="col-4 mb-3">
                        <h6>{{ l('NormalTime') }}:</h6>
                        <div class="input-group">
                            <i class="icon col-1 p-0 d-flex p-inputgroup-addon rounded-left flaticon2-crisp-icons-1 "
                            data-toggle="tooltip" placement="bottom" ></i>
                            <span class="value col-10 p-0 form-group">
                                <span class="ml-2">{{item?.timeEntry?.normalHours | number : '1.2-2'}}</span>
                            </span>
                        </div>

                    </div>

                    <div class="col-4 mb-3">
                        <h6>{{ l('ClockTime') }}:</h6>
                        <div class="input-group">
                            <i class="icon col-1 p-0 d-flex p-inputgroup-addon rounded-left flaticon2-crisp-icons-1"
                            data-toggle="tooltip" placement="bottom" ></i>
                            <span class="value col-10 p-0 form-group">
                                <span class="ml-2">{{ item.clockHours |  number: '1.2-2' }}</span>
                            </span>
                        </div>
                    </div>
                    <div class="col-4 mb-3">
                        <h6>{{ l('AssignedHours') }}:</h6>
                        <div class="input-group">
                            <i class="icon col-1 p-0 d-flex p-inputgroup-addon rounded-left flaticon2-crisp-icons-1"
                            data-toggle="tooltip" placement="bottom" ></i>
                            <span class="value col-10 p-0 form-group">
                                <span class="ml-2">{{ item.assignedHours |  number: '1.2-2' }}</span>
                            </span>
                        </div>
                    </div>
                    <div class="col-6 mb-3">
                        <h6>{{ l('CheckInDistance') }}:</h6>
                        <div class="input-group">
                            <i class="icon col-1 p-0 d-flex p-inputgroup-addon rounded-left flaticon2-location "
                            data-toggle="tooltip" placement="bottom"  ></i>
                            <span class="value col-10 p-0 form-group">
                                <span class="ml-2">{{ item.timeEntry?.checkInDistance |  number: '1.2-2' }}</span>
                            </span>
                        </div>

                    </div>
                    <div class="col-6 mb-3">
                        <h6>{{ l('CheckOutDistance') }}:</h6>
                        <div class="input-group">
                            <i class="icon col-1 p-0 d-flex p-inputgroup-addon rounded-left flaticon2-location "
                            data-toggle="tooltip" placement="bottom" ></i>
                            <span class="value col-10 p-0 form-group">
                                <span class="ml-2">{{ item.timeEntry?.checkOutDistance |  number: '1.2-2' }}</span>
                            </span>
                        </div>

                    </div>
                    <!-- <div class="col-6 mb-3">
                        <h6>{{ l('OverTimeHours') }} :</h6>
                        {{item?.timeEntry?.overTimeHours}}
                    </div> -->

                    <div class="col-6 mb-3">
                        <h6>{{ l('CheckInGeoCode') }}:</h6>
                        <div class="input-group">
                            <i class="icon col-1 p-0 d-flex p-inputgroup-addon rounded-left flaticon2-location "
                            data-toggle="tooltip" placement="bottom" ></i>
                            <span class="value col-10 p-0 form-group">
                                <span class="ml-2 cursor-pointer text-primary" (click)="openMap(item?.timeEntry?.checkInGeoCode)">{{ item?.timeEntry?.checkInGeoCode }}</span>
                            </span>
                        </div>
                    </div>
                    <div class="col-6 mb-3">
                        <h6>{{ l('CheckOutGeoCode') }}:</h6>
                        <div class="input-group">
                            <i class="icon col-1 p-0 d-flex p-inputgroup-addon rounded-left flaticon2-location "
                            data-toggle="tooltip" placement="bottom" ></i>
                            <span class="value col-10 p-0 form-group">
                                <span class="ml-2 cursor-pointer text-primary" (click)="openMap(item?.timeEntry?.checkOutGeoCode)">{{ item?.timeEntry?.checkOutGeoCode }}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="saving" type="button" class="btn btn-default" (click)="close()">
                    {{ l('Close') }}
                </button>
            </div>
        </div>
        <JobOrderShiftBreaks #JobOrderShiftBreaks> </JobOrderShiftBreaks>
    </div>
</div>
