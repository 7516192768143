<div class="card">
    <!--begin::Header-->
    <div class="card-header pb-1">
        <div class="card-title">
            <h3 class="">{{l('Feedback')}}</h3>
        </div>
    </div>
    <!--end::Header-->
    <div class="card-body py-1">
        <div class="row align-items-center">
            <!--<Primeng-Datatable-Start>-->
            <div class="primeng-datatable-container col-12" [busyIf]="primengTableHelper.isLoading">
                <p-table #dataTable (onLazyLoad)="getFeedbacks($event)" [value]="primengTableHelper.records"
                    rows="{{ primengTableHelper.defaultRecordsCountPerPage }}" [paginator]="false" [lazy]="true"
                    [scrollable]="true" ScrollWidth="100%" [responsive]="primengTableHelper.isResponsive"
                    [resizableColumns]="primengTableHelper.resizableColumns">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 7%" *ngIf="permission.isGranted('Pages.Feedbacks.Delete')">

                            </th>
                            <th style="width: 15%" pSortableColumn="jobOrderFk.id">
                                {{ l('JobOrder') }}
                                <p-sortIcon field="jobOrderName"></p-sortIcon>
                            </th>
                            <th style="width: 15%" pSortableColumn="customerLocationFk.name">
                                {{ l('Location') }}
                                <p-sortIcon field="customerLocationName"></p-sortIcon>
                            </th>

                            <th style="width: 17%" pSortableColumn="isDoNotSend">
                                {{ l('DoNotSend') }}
                                <p-sortIcon field="feedback.isDoNotSend"></p-sortIcon>
                            </th>
                            <th style="width: 15%" pSortableColumn="isPrefer">
                                {{ l('Preferred') }}
                                <p-sortIcon field="feedback.isPrefer"></p-sortIcon>
                            </th>
                            <th style="width: 15%" pSortableColumn="rating">
                                {{ l('Rating') }}
                                <p-sortIcon field="feedback.rating"></p-sortIcon>
                            </th>
                            <th style="width: 15%" pSortableColumn="notes">
                                {{ l('Note') }}
                                <p-sortIcon field="feedback.notes"></p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-record="$implicit" let-i="rowIndex">
                        <tr>
                            <td class="text-center" style="width: 7%;"
                                *ngIf="permission.isGranted('Pages.Feedbacks.Delete')">
                                <a (click)="deleteFeedback(record.feedback)"><i class="fa fa-trash "></i>
                                </a>
                            </td>
                            <td class="border-1" style="width: 15%" class="long-string-column">
                                <span class="p-column-title"> {{ l('jobOrder') }}</span> {{ record.jobOrderName }}
                            </td>
                            <td class="border-1" style="width: 15%" class="long-string-column" data-toggle="tooltip"
                                title="{{ record.customerLocationName }}" data-placement="right">
                                <span class="p-column-title"> {{ l('CustomerLocation') }}</span> {{
                                record.customerLocationName }}
                            </td>
                            <td class="border-1" style="width: 17%" class="bool-column">
                                <span class="p-column-title"> {{ l('IsDoNotSend') }}</span>
                                <div *ngIf="record.feedback.isDoNotSend" class="text-center">
                                    <i class="fa fa-check-circle text-success" title="True"></i>
                                </div>
                                <!-- <div *ngIf="!record.feedback.isDoNotSend" class="text-center">

                                    </div> -->
                            </td>
                            <td class="border-1" style="width: 15%" class="bool-column">
                                <span class="p-column-title"> {{ l('IsPrefer') }}</span>
                                <div *ngIf="record.feedback.isPrefer" class="text-center">
                                    <i class="fa fa-check-circle text-success" title="True"></i>
                                </div>
                                <!-- <div *ngIf="!record.feedback.isPrefer" class="text-center">

                                    </div> -->
                            </td>
                            <td class="border-1" style="width: 15%" class="number-column">
                                <span class="p-column-title"> {{ l('Rating') }}</span> {{ record.feedback.rating }}
                            </td>
                            <td class="border-1" style="width: 15%" class="multiline-text-column" data-toggle="tooltip"
                                title="{{ record.feedback.notesF }}" data-placement="right">
                                <span data-toggle="tooltip" title="{{ record.feedback.notes }}" data-placement="right">
                                    <span class="p-column-title"> {{ l('Note') }}</span> {{ record.feedback.notes }}
                                </span>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                    {{ l('NoData') }}
                </div>
                <div class="primeng-paging-container">
                    <p-paginator [rows]="primengTableHelper.defaultRecordsCountPerPage" #paginator
                        (onPageChange)="getFeedbacks($event)" [totalRecords]="primengTableHelper.totalRecordsCount"
                        [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage"
                        [showCurrentPageReport]="true" [currentPageReportTemplate]="
                                    'TotalRecordsCount' | localize: primengTableHelper.totalRecordsCount
                                ">
                    </p-paginator>
                </div>
            </div>
            <!--<Primeng-Datatable-End>-->
        </div>
    </div>
</div>