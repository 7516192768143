<div class="card " id="availablity-control">
    <!-- <div class="" > -->
    <!--begin::Header-->
    <!-- <div class="card-header ">
            <div class="card-title ">
                <h3 class="">{{l('Availability')}}</h3>
            </div>
        </div> -->
    <div class="card-header pb-1 d-flex justify-content-between align-items-center">
        <div class="card-title">
            <h3 class="">{{l('Availability')}}</h3>
        </div>
        <div class="card-title d-flex align-items-center">
            <label class="mt-3" *ngIf="!isLockTimeAvailability" for="GeoAvailability">{{ l('UnlockedTimeAvailability') }}</label>
            <label class="text-danger mt-3" *ngIf="isLockTimeAvailability" for="GeoAvailability">{{ l('LockedTimeAvailability') }}</label>
            <span class="ml-3 switch switch-icon" data-toggle="tooltip" placement="bottom"
                tooltip="{{l(isLockTimeAvailability? 'LockedTimeAvailability' : 'UnlockedTimeAvailability') }}">
                <label>
                    <input id="LockGeoAvailability" type="checkbox" (change)="lockEmployeeTimeAvailability()"
                        [(ngModel)]="isLockTimeAvailability" name="LockGeoAvailability" />
                    <span></span>
                </label>
            </span>
        </div>
    </div>
    <!--end::Header-->
    <div class="card-body bg-white py-4 " [busyIf]="primengTableHelper.isLoading">
        <div class="">

            <p-table id="custom-shift-td" [columns]="stardardShifts" [frozenColumns]="frozenCols" [value]="days" [scrollable]="true" frozenWidth="9rem" *ngIf="loadTable">
                <ng-template pTemplate="frozencolgroup" let-columns>
                    <colgroup>
                        <col class="w-12rem">
                    </colgroup>
                </ng-template>
                <ng-template pTemplate="colgroup" let-columns>
                    <colgroup>
                        <col class="w-9rem" *ngFor="let col of stardardShifts">
                    </colgroup>
                </ng-template>
                <ng-template pTemplate="frozenheader" let-columns>
                    <tr id="headerHeightCustomiz">
                        <th class="border-2 view-font-body p-3">Date</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="header" let-columns="stardardShifts">
                    <tr id="headerHeight">
                        <th *ngFor="let col of stardardShifts" class="border-2 view-font-body p-3">
                            {{col.standardShift.name}}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="frozenbody" let-day="$implicit">
                    <tr class="h-60px">
                        <td class="border-2 view-font-body p-1 h-60px">
                            {{ day | luxonFormat:'cccc'}}<br/>
                            <!-- <span style="font-size:12px">{{ day | luxonFormat:'EEE MM/dd/yyyy'}}</span> -->
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-day="$implicit">
                    <tr class="h-60px">
                        <td (click)="addOrUpdateTempate(day, stardardShift)" [ngClass]="{
                                            'is-available-color':templateByDayOfWeelAndStandardShift(day, stardardShift)?.shiftAvailabilityTemplate?.isAvailable}" class="h-60px border-2 slot" *ngFor="let stardardShift of stardardShifts">
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <!-- </div> -->
</div>