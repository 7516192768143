import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CommunicationChannel, EmployeeProfileDetailServiceProxy, ShiftRejectionType } from '@shared/service-proxies/service-proxies';
import { LazyLoadEvent } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'employeeShiftRejections',
    templateUrl: './employee-shift-rejections.component.html',
    styleUrls: ['./employee-shift-rejections.component.css']
})
export class EmployeeShiftRejectionsComponent extends AppComponentBase {

    @Input() employeeId: number;

    @ViewChild('dataTable', { static: true }) dataTable: Table;
    @ViewChild('paginator', { static: true }) paginator: Paginator;

    communicationChannel = CommunicationChannel;
    shiftRejectionType = ShiftRejectionType;

    constructor(
        injector: Injector,
        private _employeeProfileDetailservice: EmployeeProfileDetailServiceProxy,
    ) {
        super(injector);
    }

    getShiftRejections(event?: LazyLoadEvent) {
        if (this.primengTableHelper.shouldResetPaging(event)) {
            this.paginator.changePage(0);
            return;
        }

        this.primengTableHelper.showLoadingIndicator();

        this._employeeProfileDetailservice
            .getShftRejectionsByEmployeeId(
                this.employeeId,
                this.primengTableHelper.getSorting(this.dataTable),
                this.primengTableHelper.getSkipCount(this.paginator, event),
                this.primengTableHelper.getMaxResultCount(this.paginator, event)
            ).pipe(finalize(() => {
                this.primengTableHelper.hideLoadingIndicator();
            }))
            .subscribe((result) => {
                this.primengTableHelper.totalRecordsCount = result.totalCount;
                this.primengTableHelper.records = result.items;
            });
    }

    reloadPage(): void {
        this.paginator.changePage(this.paginator.getPage());
    }
}
