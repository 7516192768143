import { ResetPasswordModel } from '@account/password/reset-password.model';
import { Component, ElementRef, Injector, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { SelectItemDto, ContractEmailApprovalTimeCardsServiceProxy, ResolveTenantIdInput } from '@shared/service-proxies/service-proxies';
import { DateTime } from 'luxon';
import { finalize } from 'rxjs/operators';
import { ContractApprovedTimeCardsV2 } from './contract-approved-v2-time-card/contract-approved-v2-time-card.component';
import { CustomerStaffPendingContractV2TimeCards } from './customer-staff-pending-contract-time-cards-v2/customer-staff-pending-contract-time-cards-v2.component';

@Component({
    selector: 'app-customer-time-cards',
    templateUrl: `./customer-time-cards.component.html`,
    styleUrls: ['./customer-time-cards.component.less'],
})
export class CustomerTimeCardsComponent extends AppComponentBase{
    @ViewChild('pendingContractTimeCard', { static: true }) pendingContractTimeCard: CustomerStaffPendingContractV2TimeCards;
    @ViewChild('approvedContractTimeCard', { static: true }) approvedContractTimeCard: ContractApprovedTimeCardsV2;
    @ViewChild('wizard', { static: true }) el: ElementRef;

    payrollPeriods: SelectItemDto[] = [];
    locations: SelectItemDto[] = [];

    //Filters
    public dateRange: DateTime[] = [this._dateTimeService.getStartOfDayMinusDays(30), this._dateTimeService.getEndOfDay()];

    selectedPayrollPeriod: SelectItemDto = new SelectItemDto();
    selectedLocation: SelectItemDto = new SelectItemDto();
    wizard: any;

    staffReviewcount: number;
    empReviewcount: number;
    readyForCustomercount: number;
    customerApprovecount: number;
    wizardStep: number;
    customerLocationId: number;
    employeeId: number;
    clockIssue = "customer";
    @Input() onlyActive: boolean = true;
    payRollPeriodId: number;
    selectedPayRollPeriodDate: DateTime;
    userId: number;
    params: string;
    model: ResetPasswordModel = new ResetPasswordModel();
    tenantId: number;
    constructor(
        injector: Injector,
        private _dateTimeService: DateTimeService,
        private _timecardService: ContractEmailApprovalTimeCardsServiceProxy,
        private _activatedRoute: ActivatedRoute,
    ) {
        super(injector);
    }

    applyChanges() {
        this.payRollPeriodId = this.payrollPeriods.find(x => x.value == this.payRollPeriodId).value;
        this.selectedPayRollPeriodDate = this.payrollPeriods.find(x => x.value == this.payRollPeriodId).endDate;
        this.pendingContractTimeCard.getTimeCardApproval(this.dateRange, this.userId, this.tenantId, this.clockIssue, this.payRollPeriodId, this.selectedPayRollPeriodDate);
        this.approvedContractTimeCard.getTimeCardApproval(this.dateRange, this.userId, this.tenantId, this.clockIssue, this.payRollPeriodId, this.selectedPayRollPeriodDate);
    }

    parseTenantId(tenantIdAsStr?: string): number {
        let tenantId = !tenantIdAsStr ? undefined : parseInt(tenantIdAsStr);
        if (Number.isNaN(tenantId)) {
            tenantId = undefined;
        }

        return tenantId;
    }

    ngOnInit(): void {
        if (this._activatedRoute.snapshot.queryParams['c']) {
            this.model.c = this._activatedRoute.snapshot.queryParams['c'];
            this._timecardService.resolveTenantAndUserId(new ResolveTenantIdInput({ c: this.model.c })).subscribe((result) => {
                this.userId = result.userId;
                this.tenantId = result.tenantId;
                this.appSession.changeTenantIfNeeded(
                    result.tenantId
                );
            this.getPayRollPeriods(this.tenantId);
            });
        } else {
            this.userId = this._activatedRoute.snapshot.queryParams['userId'];
            this.tenantId = this._activatedRoute.snapshot.queryParams['resetCode'];

            this.appSession.changeTenantIfNeeded(
                this.parseTenantId(
                    this._activatedRoute.snapshot.queryParams['tenantId']
                )
            );
            this.getPayRollPeriods(this.tenantId);
        }
    }

    getPayRollPeriods(tenantId) {
        var date = this._dateTimeService.getUTCDate().plus({ days: -7 }).toLocal();
        this.primengTableHelper.showLoadingIndicator();
        this._timecardService.getPayRollPeriod(tenantId).pipe(finalize(() => {
            this.primengTableHelper.hideLoadingIndicator();
        })).subscribe(result => {
            this.payrollPeriods = result;
            this.selectedPayRollPeriodDate = this.payrollPeriods.find(x => x.startDate.toISODate() <= date.toISODate() && x.endDate.toISODate() >= date.toISODate())?.endDate;
            this.payRollPeriodId = this.payrollPeriods.find(x => x.startDate.toISODate() <= date.toISODate() && x.endDate.toISODate() >= date.toISODate())?.value;
            if (!this.selectedPayRollPeriodDate && !this.payRollPeriodId) {
                this.selectedPayRollPeriodDate = this.payrollPeriods[0].endDate;
                this.payRollPeriodId = this.payrollPeriods[0].value;
            }
            this.applyChanges();
        });
    }

    wizardStepChange(step: number) {
        this.wizardStep = step;
        if (this.wizardStep == 1) {
            this.pendingContractTimeCard.getTimeCardApproval(this.dateRange, this.userId, this.tenantId, this.clockIssue, this.payRollPeriodId, this.selectedPayRollPeriodDate);
        } else if (this.wizardStep == 2) {
            this.approvedContractTimeCard.getTimeCardApproval(this.dateRange, this.userId, this.tenantId, this.clockIssue, this.payRollPeriodId, this.selectedPayRollPeriodDate);
        }
    }

    ngAfterViewInit(): void {
        this.wizard = new KTWizard(this.el.nativeElement, {
            startStep: 1,
            clickableSteps: true,
        });
        this.wizardStep = this.wizard.currentStep;
        this.wizardStepChange(1);
        this.wizard.on('beforeNext', (wizardObj) => {
        });

        this.wizard.on('change', () => {
            setTimeout(() => {
                KTUtil.scrollTop();
            }, 500);
        });
    }
    setTabsCount(step: number) {
        if (step == 2) {
            this.pendingContractTimeCard.getTimeCardApproval(this.dateRange, this.userId, this.tenantId, this.clockIssue, this.payRollPeriodId, this.selectedPayRollPeriodDate);
            this.approvedContractTimeCard.getTimeCardApproval(this.dateRange, this.userId, this.tenantId, this.clockIssue, this.payRollPeriodId, this.selectedPayRollPeriodDate);
        }
    }

    onDateChange(dateRange) {
          this.dateRange[0] = dateRange[0];
          this.dateRange[1] = dateRange[1];
          this.applyChanges();
    }
 }
