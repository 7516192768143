import { Injectable } from '@angular/core';
import { AbpHttpConfigurationService, LogService, MessageService } from 'abp-ng2-module';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ZeroTemplateHttpConfigurationService extends AbpHttpConfigurationService {

    constructor(
        messageService: MessageService,
        logService: LogService,
        private _route: Router) {
        super(messageService, logService);
    }

    // Override handleUnAuthorizedRequest so it doesn't refresh the page during failed login attempts.
    handleUnAuthorizedRequest(messagePromise: any, targetUrl?: string) {

        const userType = abp.utils.getCookieValue(
            'userType'
        );
        if (userType == 'host') {
            if (this._route.url === '/account/host-login') {
                return;
            }
        } else if (userType == 'admin') {
            if (this._route.url === '/account/admin-login') {
                return;
            }
        } else if (userType == 'staff') {
            if (this._route.url === '/account/staff-login') {
                return;
            }
        } else if (userType == 'employee') {
            if (this._route.url === '/account/associate-login') {
                return;
            }
        } else if (userType == 'customer') {
            if (this._route.url === '/account/customer-login') {
                return;
            }
        } else {
            if (this._route.url === '/account/login') {
                return;
            }
        }
        const self = this;

        if (messagePromise) {
            messagePromise.done(() => {
                this.handleTargetUrl(targetUrl || '/');
            });
        } else {
            self.handleTargetUrl(targetUrl || '/');
        }
    }
}
