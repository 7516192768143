<div appBsModal #changePasswordModal="bs-modal" (onShown)="onShown()" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form *ngIf="active" #changePasswordModalForm="ngForm" (ngSubmit)="save()">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{"ChangePassword" | localize}}</span>
                    </h5>
                    <button type="button" class="close" [attr.aria-label]="l('Close')" (click)="close()">
                         <i aria-hidden="true" class="ki ki-close"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label for="CurrentPassword">{{"CurrentPassword" | localize}}</label>
                        <input id="CurrentPassword" #currentPasswordInput="ngModel" type="password" name="CurrentPassword" class="form-control" [(ngModel)]="currentPassword" required>
                        <validation-messages [formCtrl]="currentPasswordInput"></validation-messages>
                    </div>
                    <div class="form-group">
                        <label for="NewPassword">{{"NewPassword" | localize}}</label>
                        <input id="NewPassword" type="password" name="NewPassword" class="form-control" [(ngModel)]="password" #NewPassword="ngModel" validateEqual="NewPasswordRepeat"
                               reverse="true" [requireDigit]="passwordComplexitySetting.requireDigit" [requireLowercase]="passwordComplexitySetting.requireLowercase"
                               [requireUppercase]="passwordComplexitySetting.requireUppercase" [requireNonAlphanumeric]="passwordComplexitySetting.requireNonAlphanumeric" [requiredLength]="passwordComplexitySetting.requiredLength"
                               required>
                    </div>
                    <div [hidden]="changePasswordModalForm.form.valid || changePasswordModalForm.form.pristine">
                        <ul class="help-block text-danger" *ngIf="NewPassword.errors">
                            <li [hidden]="!NewPassword.errors.requireDigit">{{"PasswordComplexity_RequireDigit_Hint" | localize}}</li>
                            <li [hidden]="!NewPassword.errors.requireLowercase">{{"PasswordComplexity_RequireLowercase_Hint" | localize}}</li>
                            <li [hidden]="!NewPassword.errors.requireUppercase">{{"PasswordComplexity_RequireUppercase_Hint" | localize}}</li>
                            <li [hidden]="!NewPassword.errors.requireNonAlphanumeric">{{"PasswordComplexity_RequireNonAlphanumeric_Hint" | localize}}</li>
                            <li [hidden]="!NewPassword.errors.requiredLength">{{"PasswordComplexity_RequiredLength_Hint" | localize:passwordComplexitySetting.requiredLength}}</li>
                        </ul>
                    </div>
                    <div class="form-group">
                        <label for="NewPasswordRepeat">{{"NewPasswordRepeat" | localize}}</label>
                        <input id="NewPasswordRepeat" type="password" name="NewPasswordRepeat" class="form-control" [ngModel]="confirmPassword" #NewPasswordRepeat="ngModel" validateEqual="NewPassword"
                               [requireDigit]="passwordComplexitySetting.requireDigit" [requireLowercase]="passwordComplexitySetting.requireLowercase"
                               [requireUppercase]="passwordComplexitySetting.requireUppercase" [requireNonAlphanumeric]="passwordComplexitySetting.requireNonAlphanumeric" [requiredLength]="passwordComplexitySetting.requiredLength"
                               reverse="false" required>
                    </div>
                    <div [hidden]="changePasswordModalForm.form.valid || changePasswordModalForm.form.pristine">
                        <ul class="help-block text-danger" *ngIf="NewPasswordRepeat.errors">
                            <li [hidden]="!NewPasswordRepeat.errors.requireDigit">{{"PasswordComplexity_RequireDigit_Hint" | localize}}</li>
                            <li [hidden]="!NewPasswordRepeat.errors.requireLowercase">{{"PasswordComplexity_RequireLowercase_Hint" | localize}}</li>
                            <li [hidden]="!NewPasswordRepeat.errors.requireUppercase">{{"PasswordComplexity_RequireUppercase_Hint" | localize}}</li>
                            <li [hidden]="!NewPasswordRepeat.errors.requireNonAlphanumeric">{{"PasswordComplexity_RequireNonAlphanumeric_Hint" | localize}}</li>
                            <li [hidden]="!NewPasswordRepeat.errors.requiredLength">{{"PasswordComplexity_RequiredLength_Hint" | localize:passwordComplexitySetting.requiredLength}}</li>
                            <li [hidden]="NewPasswordRepeat.valid">{{"PasswordsDontMatch" | localize}}</li>
                        </ul>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default font-weight-bold" (click)="close()" [disabled]="saving">{{"Cancel" | localize}}</button>
                    <button type="submit" class="btn btn-primary font-weight-bold" [disabled]="!changePasswordModalForm.form.valid || saving"><i class="fa fa-save"></i> <span>{{"Save" | localize}}</span></button>
                </div>
            </form>
        </div>
    </div>
</div>
