import { PermissionCheckerService, RefreshTokenService } from 'abp-ng2-module';
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { Observable } from 'rxjs/internal/Observable';
import { of, Subject } from 'rxjs';
import { LocalStorageService } from '@shared/utils/local-storage.service';

@Injectable()
export class AppRouteGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(
        private _permissionChecker: PermissionCheckerService,
        private _router: Router,
        private _sessionService: AppSessionService,
        private _refreshTokenService: RefreshTokenService
    ) {}

    canActivateInternal(data: any, state: RouterStateSnapshot, queryParams?: any): Observable<boolean> {
        if (this._sessionService.user) {
            new LocalStorageService().getItem('Auth', (e, q) => {
                if (q && q.redirectUrl) {
                    this._router.navigate([q.redirectUrl]);
                }
            });
        }

        if (UrlHelper.isInstallUrl(location.href)) {
            return of(true);
        }
        if (!this._sessionService.user) {
            let sessionObservable = new Subject<any>();

            this._refreshTokenService.tryAuthWithRefreshToken().subscribe(
                (autResult: boolean) => {
                    if (autResult) {
                        sessionObservable.next(true);
                        sessionObservable.complete();
                        location.reload();
                    } else {
                        sessionObservable.next(false);
                        sessionObservable.complete();
                        //is pending timecard url
                        let isCustomerSpecificUrl = UrlHelper.isCustomerSpecificUrl(location.href);
                        let email = '';
                        let isUser = '';
                        queryParams = queryParams || {};
                        if (isCustomerSpecificUrl) {
                            isUser = queryParams.iu;

                            email = queryParams.ue;
                            let userType = queryParams.ut;

                            if (userType) {
                                abp.utils.setCookieValue('userType', userType);
                            }
                        }
                        //end is pending timecard url
                        const userType = abp.utils.getCookieValue('userType');
                        if (userType == 'host') {
                            this._router.navigate(['account/host-login']);
                        } else if (userType == 'admin') {
                            this._router.navigate(['account/admin-login']);
                        } else if (userType == 'staff') {
                            this._router.navigate(['account/staff-login']);
                        } else if (userType == 'employee') {
                            this._router.navigate(['account/associate-login']);
                        } else if (userType == 'customer') {
                            if (isUser == 'true')
                                this._router.navigate(['account/customer-login'], {
                                    queryParams: {
                                        userEmail: email,
                                        redirectUrl: location.href,
                                    },
                                });
                            if (isUser == 'false')
                                this._router.navigate(['account/register'], {
                                    queryParams: {
                                        userType: userType,
                                        userEmail: email,
                                        redirectUrl: location.href,
                                    },
                                });
                            if (isUser == '') this._router.navigate(['account/customer-login']);
                        } else {
                            this._router.navigate(['account/login']);
                        }
                    }
                },
                (error) => {
                    sessionObservable.next(false);
                    sessionObservable.complete();
                    const userType = abp.utils.getCookieValue('userType');
                    if (userType == 'host') {
                        this._router.navigate(['account/host-login']);
                    } else if (userType == 'staff') {
                        this._router.navigate(['account/staff-login']);
                    } else if (userType == 'employee') {
                        this._router.navigate(['account/associate-login']);
                    } else if (userType == 'customer') {
                        this._router.navigate(['account/customer-login']);
                    } else {
                        this._router.navigate(['account/login']);
                    }
                }
            );
            return sessionObservable;
        }

        if (!data || !data['permission']) {
            return of(true);
        }

        if (this._permissionChecker.isGranted(data['permission'])) {
            return of(true);
        }

        this._router.navigate([this.selectBestRoute()]);
        return of(false);
    }
    isCustomerSpecificUrl(location, queryParams?: any) {
        queryParams = queryParams || {};
        if (UrlHelper.isCustomerSpecificUrl(location.href)) {
            let isUser = queryParams.iu;

            let email = queryParams.ue;
            if (isUser == 'true') {
                this._router.navigate(['account/customer-login'], {
                    queryParams: {
                        userEmail: email,
                        redirectUrl: location.href,
                    },
                });
            }
            let userType = queryParams.ut;
            if (!userType) return;

            if (isUser == 'false' && userType) {
                abp.utils.setCookieValue('userType', userType);
            }
            this._router.navigate(['account/register'], {
                queryParams: {
                    userType:userType,
                    userEmail: email,
                    redirectUrl: location.href,
                },
            });
        }
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivateInternal(route.data, state, route.queryParams);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(route, state);
    }

    canLoad(route: any): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivateInternal(route.data, null, route.queryParams);
    }

    selectBestRoute(): string {
        if (!this._sessionService.user) {
            const userType = abp.utils.getCookieValue('userType');
            if (userType == 'host') {
                this._router.navigate(['account/host-login']);
            } else if (userType == 'staff') {
                this._router.navigate(['account/staff-login']);
            } else if (userType == 'employee') {
                this._router.navigate(['account/associate-login']);
            } else if (userType == 'customer') {
                this._router.navigate(['account/customer-login']);
            } else {
                this._router.navigate(['account/login']);
            }
        }
        if (this._permissionChecker.isGranted('Pages.CustomerTimeCardApproval')) {
            return '/app/main/customer-time-card-approve';
        }
        if (this._permissionChecker.isGranted('Pages.StaffRedirectAfterLogin')) {
            return '/app/main/job-orders/job-Orders';
        }

        if (this._permissionChecker.isGranted('Pages.Administration.Host.Dashboard')) {
            return '/app/admin/hostDashboard';
        }

        if (this._permissionChecker.isGranted('Pages.Tenant.Dashboard')) {
            return '/app/main/dashboard';
        }

        if (this._permissionChecker.isGranted('Pages.Tenants')) {
            return '/app/admin/tenants';
        }

        if (this._permissionChecker.isGranted('Pages.Administration.Users')) {
            return '/app/admin/users';
        }
        return '/app/notifications';
    }
}
