import { Component, ViewChild, Injector, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AddOrUpdateNotesInputDto, ContractEmailApprovalTimeCardsServiceProxy } from '@shared/service-proxies/service-proxies';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'editNotesForContractTimeCardModal',
    templateUrl: './edit-notes-for-contract-time-card-modal.component.html',
})
export class EditNotesForContractTimeCardModal extends AppComponentBase implements OnInit {
    @ViewChild('editNotesModal', { static: true }) modal: ModalDirective;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    active = false;
    saving = false;
    notes='';
    shiftID:number;
    isDisabeld:boolean;
    constructor(
        injector: Injector,
        private readonly _timecardService: ContractEmailApprovalTimeCardsServiceProxy
    ) {
        super(injector);
    }

    show(notes:string,shiftID: number,isDisabeld:boolean=false): void {
           this.notes=notes;
            this.active = true;
            this.isDisabeld = isDisabeld;
            this.shiftID=shiftID;
            this.modal.show();
        }
    save(): void {
        this.saving = true;
        if(this.IsNullOrEmpty(this.notes)){
            this.notify.error(this.l('NotesCanNotBeNull'));
            this.saving = false;
            return;
        }
        var input = new AddOrUpdateNotesInputDto();
        input.shiftId=this.shiftID;
        input.customerNote=this.notes;
        this._timecardService
            .addOrUpdateNote(input)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe(() => {
                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit(
                    {
                    shiftId:this.shiftID,
                    customerNote:this.notes
                   }
                );
            });
    }

    close(): void {
        this.active = false;
        this.modal.hide();
    }

    ngOnInit(): void {
    }
}
