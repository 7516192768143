<div class="card ">
    <!--begin::Header-->
    <div class="card-header pb-1">
        <div class="card-title ">
            <h3 class="">{{l('ApprovedTimeOffRequest')}}</h3>
        </div>
    </div>
    <!--end::Header-->
    <div class="card-body py-1">
        <div class="row align-items-center">
            <!--<Primeng-Datatable-Start>-->
            <div class="primeng-datatable-container col-12" [busyIf]="primengTableHelper.isLoading">
                <p-table #dataTable (onLazyLoad)="getApproveTimeOffRequestJobOrder($event)" [value]="primengTableHelper.records" rows="{{ primengTableHelper.defaultRecordsCountPerPage }}" [paginator]="false" [lazy]="true" [scrollable]="true" ScrollWidth="100%" [responsive]="primengTableHelper.isResponsive"
                [resizableColumns]="primengTableHelper.resizableColumns">
                <ng-template pTemplate="header">
                    <tr>
                            <th style="width: 10%">
                                {{ l('Action') }}
                            </th>
                            <th style="width: 20%" pSortableColumn="Id">
                                {{ l('#') }}
                                <p-sortIcon field="Id"></p-sortIcon>
                            </th>
                            <th style="width: 20%" pSortableColumn="startDate">
                                {{ l('From') }}
                                <p-sortIcon field="startDate"></p-sortIcon>
                            </th>
                            <th style="width: 20%" pSortableColumn="endDate">
                                {{ l('To') }}
                                <p-sortIcon field="endDate"></p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-record="$implicit" let-i="rowIndex">
                        <tr>
                            <td class="border-1" style="width: 10%" class="long-string-column">
                                <button class="btn btn-danger" (click)="deleteJobOrder(record.jobOrderId)">{{l('Delete')}}</button>
                            </td>
                            <td class="border-1" style="width: 20%" class="long-string-column">
                                <span class="ui-column-title"> {{ l('#') }}</span>
                                <span>{{ record.jobOrderId}}</span>
                            </td>
                            <td class="border-1" style="width: 20%" class="long-string-column">
                                <span class="ui-column-title"> {{ l('startDate') }}</span>
                                <span>{{ record.startDate | luxonFormat: 'EEE MM/dd/yyyy' }}</span>
                            </td>
                            <td class="border-1" style="width: 20%" class="long-string-column">
                                <span class="ui-column-title"> {{ l('endDate') }}</span>
                                <span>{{ record.endDate | luxonFormat: 'EEE MM/dd/yyyy' }}</span>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                    {{ l('NoData') }}
                </div>
                <div class="primeng-paging-container">
                    <p-paginator [rows]="primengTableHelper.defaultRecordsCountPerPage" #paginator (onPageChange)="getApproveTimeOffRequestJobOrder($event)" [totalRecords]="primengTableHelper.totalRecordsCount" [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage">
                    </p-paginator>
                    <span class="total-records-count">
                                        {{ l('TotalRecordsCount', primengTableHelper.totalRecordsCount) }}
                                    </span>
                </div>
            </div>
            <!--<Primeng-Datatable-End>-->
        </div>
    </div>
</div>
