<div class="card ">
    <!--begin::Header-->
    <div class="card-header pb-1 d-flex justify-content-between align-items-center">
        <div class="card-title ">
            <h3 class="">{{l('GeoPreferences')}}</h3>
        </div>
        <div class="card-title d-flex align-items-center">
            <label class="mt-3" *ngIf="!isLockAvailability" for="GeoPreference">{{ l('UnlockedGeoAvailability') }}</label>
            <label class="text-danger mt-3" *ngIf="isLockAvailability" for="GeoPreference">{{ l('LockedGeoAvailability') }}</label>
            <span class="ml-3 switch switch-icon" data-toggle="tooltip" placement="bottom"
                tooltip="{{l(isLockAvailability ?  'LockedGeoAvailability'  :  'UnlockedGeoAvailability') }}">
                <label>
                    <input id="LockGeoPreference" type="checkbox" (change)="lockEmployeeGeoAvailability()"
                        [(ngModel)]="isLockAvailability" name="LockGeoPreference" />
                    <span></span>
                </label>
            </span>
        </div>
    </div>
    <!--end::Header-->
    <div class="card-body py-1 ">

        <div class="row align-items-center">
            <!--<Primeng-Datatable-Start>-->
            <div class="primeng-datatable-container col-12" [busyIf]="primengTableHelper.isLoading">
                <p-table #dataTable (onLazyLoad)="getLocalAreas($event)" [value]="primengTableHelper.records"
                    rows="{{ primengTableHelper.defaultRecordsCountPerPage }}" [paginator]="false" [lazy]="true"
                    [scrollable]="true" ScrollWidth="100%" [responsive]="primengTableHelper.isResponsive"
                    [resizableColumns]="primengTableHelper.resizableColumns">
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="cursor-pointer" (click)="assignUnassignlocalArea()" style="width: 20px">
                                <input id="localArea" type="checkbox" [(ngModel)]="allAssignLocalAreas"
                                    name="IsActive" />
                            </th>
                            <th style="width: 150px" pSortableColumn="name">
                                {{ l('LocalArea') }}
                                <p-sortIcon field="localArea.name"></p-sortIcon>
                            </th>

                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-record="$implicit" let-i="rowIndex">
                        <tr class="cursor-pointer" (click)="assignUnAssignArea(record.localArea.id)">
                            <td style="width: 20px;">
                                <input *ngIf="record.isAssigned>0" id="localArea" type="checkbox" name="IsActive"
                                    checked />
                                <input *ngIf="record.isAssigned==0" id="localArea" type="checkbox" name="IsActive" />
                            </td>
                            <td style="width: 150px" class="long-string-column">
                                <span class="ui-column-title"> {{ l('Name') }}</span> {{ record.localArea.name }}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                    {{ l('NoData') }}
                </div>
                <div class="primeng-paging-container">
                    <p-paginator [rows]="primengTableHelper.defaultRecordsCountPerPage" #paginator
                        (onPageChange)="getLocalAreas($event)" [totalRecords]="primengTableHelper.totalRecordsCount"
                        [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage">
                    </p-paginator>
                    <span class="total-records-count">
                        {{ l('TotalRecordsCount', primengTableHelper.totalRecordsCount) }}
                    </span>
                </div>
            </div>
            <!--<Primeng-Datatable-End>-->
        </div>
    </div>
</div>