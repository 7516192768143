import { PermissionCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';

@Injectable()
export class AppNavigationService {

    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) {

    }

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'flaticon-line-graph', '/app/admin/hostDashboard'),
            new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'flaticon-line-graph', '/app/main/dashboard'),
            new AppMenuItem('MyShiftCalendar', 'Pages.MyShiftCalendar', 'flaticon-calendar', '/app/main/shift/my-shift-calendar'),
            new AppMenuItem('JobOrders', 'Pages.JobOrders', 'fa fa-tasks', '/app/main/job-orders/job-Orders'),
            new AppMenuItem('JobOrderDemandMenuHeader', 'Pages.JobOrderDemands', 'flaticon2-layers-1', '',[],[
                new AppMenuItem('JobOrderDemandList', 'Pages.JobOrderDemands', 'flaticon2-layers-1', '/app/main/jobOrderDemands/jobOrderDemands'),
                new AppMenuItem('JobOrderDemandBoard', 'Pages.JobOrderDemands.Board', 'fa fa-user-clock', '/app/main/jobOrderDemands/jobOrderDemandsBoard'),
                new AppMenuItem('JobOrderDemandBoardByCategoryGroups', 'Pages.JobOrderDemands.Board.By.Category.Groups', 'fa fa-object-group', '/app/main/jobOrderDemands/jobOrderDemandsBoardByGroup'),
                new AppMenuItem('JODemandBoardCategoryGroups', 'Pages.Administration.JODemandBoardCategoryGroups', 'flaticon-more', '/app/main/joDemandBoardCategoryGroups/demandBoardCategoryGroupsMapping')
            ]),
            new AppMenuItem('JobOrderRequests', 'Pages.JobOrderRequests', 'flaticon-pie-chart-1', '/app/main/joborders/requests'),
            new AppMenuItem('TimeCards', '', 'flaticon-interface-8', '', [], [
            new AppMenuItem('Shift', 'Pages.ShiftTimeCard', 'flaticon-time-3', '/app/main/timeCard'),
            new AppMenuItem('Contract', 'Pages.AssociateTimeCard', 'flaticon-time-3', '/app/main/timeCardAssociate'),
            new AppMenuItem('Contract', 'Pages.StaffTimeCard', 'flaticon-time-3', '/app/main/timeCardStaff'),
            new AppMenuItem('Contract', 'Pages.CustomerTimeCard', 'flaticon-time-3', '/app/main/timeCardCustomer'),
            new AppMenuItem('Contract', 'Pages.AssociateTimeCardV2', 'flaticon-time-3', '/app/main/timeCardAssociateV2'),
            new AppMenuItem('Contract', 'Pages.StaffTimeCardV2', 'flaticon-time-3', '/app/main/timeCardStaffV2'),
            new AppMenuItem('Contract', 'Pages.CustomerTimeCardV2', 'flaticon-time-3', '/app/main/timeCardCustomerV2'),
            new AppMenuItem('PaperTimeCards', 'Pages.PaperTimeCards', 'flaticon-open-box', '/app/main/timeCards/paperTimeCards'),
            ]),
            new AppMenuItem('DailyTimeCards', 'Pages.CustomerDailyTimeCardApproval', 'flaticon-time-3', '/app/main/customer/timecards/daily'),
            new AppMenuItem('TimeCards', 'Pages.CustomerTimeCardApproval', 'flaticon-time-3', '/app/main/customer-time-card-approve'),
            new AppMenuItem('Payroll', 'Pages.Payroll', 'flaticon-time-3', '/app/main/payroll'),
            new AppMenuItem('Employee', '', 'flaticon-interface-8', '', [], [
                new AppMenuItem('Employees', 'Pages.Employees', 'flaticon-share', '/app/main/employees/employees'),
                new AppMenuItem('TimeOffRequests', 'Pages.OffTimeRequests', 'fa fa-user-clock', '/app/main/offTimeRequests/offTimeRequests'),
                new AppMenuItem('Feedbacks', 'Pages.Feedbacks', 'flaticon-light', '/app/main/feedbacks/feedbacks'),
            ]),
            new AppMenuItem('Customers', '', 'flaticon-interface-8', '', [], [
                new AppMenuItem('CustomerLocations', 'Pages.CustomerLocations', 'flaticon-bus-stop', '/app/main/customerLocations/customerLocations'),
                new AppMenuItem('CustomerContacts', 'Pages.CustomerContacts', 'flaticon-presentation-1', '/app/main/customerContacts/customerContacts'),
                new AppMenuItem('Customers', 'Pages.Customers', 'flaticon-customer', '/app/main/customers/customers'),
                new AppMenuItem('CustomerLocationContacts', 'Pages.CustomerLocationContacts', 'flaticon2-location', '/app/admin/customer-contacts'),
                new AppMenuItem('CustomerLocationRates', 'Pages.JobOrderRates', 'flaticon-map-location', '/app/main/jobOrders/customerLocationRates'),
            ]),
            new AppMenuItem('BusinessSettings', '', 'flaticon-interface-8', '', [], [
                new AppMenuItem('BusinessUnitsEmployableBUs', 'Pages.BusinessUnitsEmployableBUs', 'flaticon-interface-6', '/app/main/employableBusinessUnits'),
                new AppMenuItem('BusinessUnitsJobCategories', 'Pages.BusinessUnitJobCategories', 'flaticon-interface-6', '/app/main/businessUnitsJobCategories'),
                new AppMenuItem('BusinessUnits', 'Pages.Administration.BusinessUnits', 'flaticon-interface-6', '/app/admin/businessUnits/businessUnits'),
                new AppMenuItem('StandardShifts', 'Pages.Administration.StandardShifts', 'flaticon-rocket', '/app/admin/standardShifts/standardShifts'),
                new AppMenuItem('LocalAreas', 'Pages.Administration.LocalAreas', 'flaticon-lifebuoy', '/app/admin/localAreas/localAreas'),
                new AppMenuItem('PayPeriodTypeses', 'Pages.PayPeriodTypeses', 'far fa-calendar-check', '/app/main/payPeriodType/payPeriodTypeses'),
                new AppMenuItem('PayRollPeriods', 'Pages.PayRollPeriods', 'fas fa-receipt', '/app/main/payRollPeriods/payRollPeriods'),
                new AppMenuItem('JobCategories', 'Pages.JobCategories', 'flaticon-open-box', '/app/main/jobOrders/jobCategories'),
                new AppMenuItem('WorkersCompCodes', 'Pages.WorkerComps', 'flaticon2-graph-1', '/app/main/jobOrders/workerComps'),
                new AppMenuItem('EmailTemplates', 'Pages.EmailTemplates', 'flaticon-multimedia-2', '/app/main/emailTemplates/emailTemplates'),
            ]),
            new AppMenuItem('Calendar', '', 'flaticon-calendar', '', [], [
                new AppMenuItem('ShiftCalendarEvents', 'Pages.ShiftCalendarEvents', 'flaticon-calendar', '/app/main/shift/ShiftCalendarEvents'),
                new AppMenuItem('ShiftCalendar', 'Pages.CustomerShiftCalendar', 'flaticon-calendar', '/app/main/shift/calendar'),
            ]),
            new AppMenuItem('Tenants', 'Pages.Tenants', 'flaticon-list-3', '/app/admin/tenants'),

            new AppMenuItem('DynamicQueries', 'Pages.DynamicQueries', 'flaticon-file-1', '/app/main/reporting/dynamicQueries'),





            //new AppMenuItem('StateOvertimeRules', 'Pages.StateOvertimeRules', 'flaticon-more', '/app/main/stateOvertimeRules/stateOvertimeRules'),
             new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'),
            new AppMenuItem('AvailabilityArea', 'Pages.EmployeeAvailbilty', 'flaticon-globe', '/app/main/employees/area-availability'),
            new AppMenuItem('availabilityCalendar', 'Pages.ShiftAvailabilityTemplates', 'flaticon2-help', '/app/main/shiftAvailabilityTemplates/shiftAvailabilityTemplates'),
            new AppMenuItem('CompanyEmployees', 'Pages.CompanyEmployees', 'flaticon-share', '/app/customer/my-employees-list'),
            new AppMenuItem('MyJobOrderShifts', 'Pages.MyJobOrderShift', 'fa fa-adjust', '/app/main/jobOrderShifts/myJobOrderShifts'),
            new AppMenuItem('TimeEntries', 'Pages.TimeEntries', 'flaticon2-crisp-icons-1', '/app/main/timeEntries/timeEntries'),
            new AppMenuItem('TechAdministration', '', 'flaticon-interface-8', '', [], [
                // new AppMenuItem('Feedbacks', 'Pages.Feedbacks', 'flaticon-more', '/app/main/feedbacks/feedbacks'),
                // new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'flaticon-map', '/app/admin/organization-units'),
                new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles'),
                new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
            new AppMenuItem('KioskDeviceConfigurations', 'Pages.Administration.KioskDeviceConfigurations', 'flaticon-more', '/app/admin/kioskDeviceConfigurations/kioskDeviceConfigurations'),

            new AppMenuItem('StateConfigurations', 'Pages.Administration.StateConfigurations', 'flaticon-placeholder-3', '/app/admin/stateConfigurations/stateConfigurations'),

                // new AppMenuItem('ShiftBreakConfigurations', 'Pages.Administration.ShiftBreakConfigurations', 'flaticon-more', '/app/admin/shiftBreakConfigurations/shiftBreakConfigurations'),

                new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', 'flaticon-folder-1', '/app/admin/auditLogs'),
                new AppMenuItem('QueuedEmails', 'Pages.QueuedEmails', 'flaticon2-indent-dots', '/app/main/queuedEmails/queuedEmails'),
                new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
                new AppMenuItem('WebhookSubscriptions', 'Pages.Administration.WebhookSubscription', 'flaticon2-world', '/app/admin/webhook-subscriptions'),
                new AppMenuItem('DynamicProperties', 'Pages.Administration.DynamicProperties', 'flaticon-interface-8', '/app/admin/dynamic-property'),
                new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'flaticon-settings', '/app/admin/hostSettings'),
                new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'flaticon-settings', '/app/admin/tenantSettings'),
            ]),
            new AppMenuItem('Others', '', 'flaticon-interface-8', '', [], [
                new AppMenuItem('JobOrderShiftEventLogs', 'Pages.JobOrderShiftEventLogs', 'fa fa-history', '/app/main/jobOrderShiftEventLogs/jobOrderShiftEventLogs'),
                new AppMenuItem('GeoAvailabilities', 'Pages.GeoAvailabilities', 'flaticon-map-location', '/app/main/geoAvailabilities/geoAvailabilities'),
                new AppMenuItem('ShiftRejections', 'Pages.ShiftRejections', 'fa fa-exclamation-triangle', '/app/main/shiftRejections/shiftRejections'),
                // // new AppMenuItem('CustomerRoles', 'Pages.CustomerRoles', 'flaticon-squares', '/app/main/customerRoles/customerRoles'),
                new AppMenuItem('Invites', 'Pages.Invites', 'flaticon2-tools-and-utensils', '/app/main/invites/invites'),
                // new AppMenuItem('JobOrderContacts', 'Pages.JobOrderContacts', 'flaticon2-contract', '/app/main/jobOrderContacts/jobOrderContacts'),
                new AppMenuItem('JobOrderShifts', 'Pages.JobOrderShifts', 'fa fa-tasks', '/app/main/jobOrderShifts/jobOrderShifts'),
                // new AppMenuItem('ShiftAvailabilities', 'Pages.ShiftAvailabilities', 'fa fa-address-card', '/app/main/shiftAvailabilities/shiftAvailabilities'),
                // new AppMenuItem('EmployeeRatings', 'Pages.EmployeeRatings', 'flaticon-presentation-1', '/app/main/employeeRatings/employeeRatings'),
                new AppMenuItem('TenantLocationLookups', 'Pages.TenantLocationLookups', 'flaticon-globe', '/app/main/tenantLocationLookups/tenantLocationLookups'),
                new AppMenuItem('TimeCards', 'Pages.TimeCards', 'flaticon2-file-1', '/app/main/timeCards/timeCards'),
                new AppMenuItem('BlastNotifications', 'Pages.BlastNotifications', '	flaticon-alert-2', '/app/main/blastNotifications/blastNotifications'),
                new AppMenuItem('ErrorReports', 'Pages.ErrorReport', 'la la-bug', '/app/main/errorReport'),

                // new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'flaticon-map', '/app/admin/organization-units'),
                //new AppMenuItem('ReferenceRoles', 'Pages.Administration.ReferenceRoles', 'flaticon-network', '/app/admin/referenceRoles/referenceRoles'),
                new AppMenuItem('Languages', 'Pages.Administration.Languages', 'flaticon-tabs', '/app/admin/languages', ['/app/admin/languages/{name}/texts']),
                new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'flaticon-lock', '/app/admin/maintenance'),
                new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
                new AppMenuItem('TimeCardsProcessed', 'Pages.TimeCardsProcessed', 'flaticon2-crisp-icons-1', '/app/main/timeCards/timeCardsProcessed'),
                new AppMenuItem('Calendar', 'Pages.Calendar', 'flaticon-calendar', '/app/main/holidayCalendar/calendar'),
                new AppMenuItem('CalendarTimezoneOffsets', 'Pages.CalendarTimezoneOffsets', 'flaticon-calendar', '/app/main/holidayCalendar/calendarTimezoneOffsets'),
                new AppMenuItem('MinimumWageRules', 'Pages.MinimumWageRules', 'flaticon-symbol', '/app/main/rules/minimumWageRules'),
                new AppMenuItem('JORateHistories', 'Pages.JORateHistories', 'flaticon-interface-6', '/app/main/jobOrders/joRateHistories'),
                new AppMenuItem('JobOrderShiftBreaks', 'Pages.JobOrderShiftBreaks', 'flaticon-clock', '/app/main/jobOrderShiftBreaks/jobOrderShiftBreaks'),
            ]),
            // new AppMenuItem('DemoUiComponents', 'Pages.DemoUiComponents', 'flaticon-shapes', '/app/admin/demo-ui-components')
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {

        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
                if (subMenuItem.route) {
                    return true;
                }
            } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
                if (isAnyChildItemActive) {
                    return true;
                }
            }
        }
        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' && this._appSessionService.tenant && !this._appSessionService.tenant.edition) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach(menuItem => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach(subMenu => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }
}
