import { Component, Injector, Input, OnInit } from '@angular/core';
import { ShiftRejectionReasonsComponent } from '@app/main/EmployeeAvailabilitySlots/shift-rejection-reasons/shift-rejection-reasons.component';
import { AppComponentBase } from '@shared/common/app-component-base';
import { GetStandardShiftForViewDto, GetShiftAvailabilityTemplateForViewDto, GetJobOrderShiftForViewDto, CreateOrEditShiftAvailabilityTemplateDto, EmployeeProfileDetailServiceProxy, ShiftAvailabilityTemplatesServiceProxy, LockGeoAvailabilityDto, LockTimeAvailabilityDto } from '@shared/service-proxies/service-proxies';
import { DateTime } from 'luxon';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'employeeGMA',
    templateUrl: './employee-g-a-m.component.html',
    styleUrls: ['./employee-g-a-m.component.less']
})
export class EmployeeGAMComponent extends AppComponentBase implements OnInit {

    @Input() employeeId: number;
    stardardShifts: GetStandardShiftForViewDto[] = [];
    shiftAvailabilityTemplates: GetShiftAvailabilityTemplateForViewDto[] = [];
    myJobOrderShifts: GetJobOrderShiftForViewDto[] = [];
    days = [];

    today: DateTime = DateTime.fromJSDate(new Date());
    currentMonth: DateTime = DateTime.fromJSDate(new Date());
    createOrEditTemplate: CreateOrEditShiftAvailabilityTemplateDto = new CreateOrEditShiftAvailabilityTemplateDto();

    loadTable = false;
    selectedslotDate: any;
    selectedslotShiftId: any;

    frozenCols = [
        { field: 'Date', header: 'Date' },
    ];

    isAvailbleSlot = false;
    isRejectedSlot = false;
    isBusySlot = false;
    lockTimeAvailability: LockTimeAvailabilityDto =new LockTimeAvailabilityDto()
    isLockTimeAvailability: boolean = false;
    constructor(injector: Injector,
        private _employeeProfileDetailServiceProxy: EmployeeProfileDetailServiceProxy,
    ) {
        super(injector);
    }

    ngOnInit() {
        this.getEmployeeAvailabilityTemplate();
        this.getStandarShifts();
        this.loadDays();
    }

    ///add or update shift availability template
    addOrUpdateTempate(day, shift) {
        if (!this.isGranted('Pages.ChangeAVailabilityOnEMployeeBehalf')) {
            this.message.info(this.l('Sorryyoucantsetavailabilityonemployeebehalf'));
        }
        this.primengTableHelper.showLoadingIndicator();
        this.isBusySlot = true;
        this.selectedslotDate = day;
        this.selectedslotShiftId = shift;
        this.createOrEditTemplate.daysOfWeek = day.weekday;
        this.createOrEditTemplate.standardShiftId = shift.standardShift.id;
        this.createOrEditTemplate.employeeId = this.employeeId;
        this.creatorUpdate();
    }

    //inner function
    private creatorUpdate() {
        this._employeeProfileDetailServiceProxy.addEmployeeGAM(this.createOrEditTemplate)
            .pipe(finalize(() => {
                this.primengTableHelper.hideLoadingIndicator();
            }))
            .subscribe((result) => {
                this.createOrEditTemplate = new CreateOrEditShiftAvailabilityTemplateDto();
                this.shiftAvailabilityTemplates = result.items;
                this.loadTable = true;
                this.notify.success(this.l('ShiftAvailbilityAddedSuccessfully'));
                this.isBusySlot = false;

            });
    }
    // get employee availablity from server
    getEmployeeAvailabilityTemplate() {
        this.primengTableHelper.showLoadingIndicator();
        this._employeeProfileDetailServiceProxy.getShiftAvailabilityTemplateForEmployee(
            this.employeeId,
            undefined,
            0,
            9999,
        )
            .pipe(finalize(() => {
                this.primengTableHelper.hideLoadingIndicator();
            })).subscribe((result) => {
                this.shiftAvailabilityTemplates = result?.items;
                this.isLockTimeAvailability = result?.lockTimeAvailability
                this.loadTable = true;
            });
    }

    checkdate(record): boolean {
        return this.today.hasSame(record, 'day');
    }

    ///load current week days
    loadDays() {
        this.days = this.getAllDates(this.currentMonth);
    }

    // all dates of month
    getAllDates(dt: DateTime): DateTime[] {
        if (typeof (dt) == typeof (Date)) {
            dt = DateTime.fromJSDate(dt as any);
        }
        let weekStartdate = dt.startOf('week');
        let weekEnddate = dt.endOf('week');
        let days = [];
        while (weekStartdate <= weekEnddate) {
            days.push(weekStartdate);
            weekStartdate = weekStartdate.plus({ day: 1 });
        }
        return days;
    }

    // all standard Shifts
    getStandarShifts() {
        this.primengTableHelper.showLoadingIndicator();
        this._employeeProfileDetailServiceProxy.getAllStandarShifts
            (null, null, null, null, null, null, '', 0, 9999)
            .subscribe((result) => {
                this.stardardShifts = result.items;
                // this.selectedRow();
            });
    }

    // default selected row
    // selectedRow() {
    //     setTimeout(() => {
    //         let rows = document.getElementsByClassName('selected-row');
    //         if (rows.length < 2) return;
    //         for (const row in rows) {
    //             if (Object.prototype.hasOwnProperty.call(rows, row)) {
    //                 const element = rows[row];
    //                 element.scrollIntoView();
    //             }
    //         }
    //     }, 300)
    // }

    // to check job order pattern is availble or not against the select or column day and standar shift
    templateByDayOfWeelAndStandardShift(day, shift): any {
        if (this.shiftAvailabilityTemplates.length > 0) {
            return this.shiftAvailabilityTemplates.find(a => a.shiftAvailabilityTemplate?.standardShiftId == shift.standardShift.id
                && a.shiftAvailabilityTemplate?.daysOfWeek == day.weekday);
        }
    }

    // mouse over function
    mouseEnter(day, shift) {
        var shiftAvailability = this.templateByDayOfWeelAndStandardShift(day, shift);
        if (shiftAvailability?.shiftAvailabilityTemplate?.isAvailable == true) {
            this.isRejectedSlot = false;
            this.isAvailbleSlot = true;
        }
        else {
            this.isRejectedSlot = false;
            this.isAvailbleSlot = false;
        }
    }
    lockEmployeeTimeAvailability(){
        this.lockTimeAvailability.employeeId = this.employeeId;
        this.lockTimeAvailability.lockTimeAvailability = this.isLockTimeAvailability;
        this.primengTableHelper.showLoadingIndicator();
        this._employeeProfileDetailServiceProxy.lockEmployeeTimeAvailability(this.lockTimeAvailability)
            .pipe(finalize(() => {
                this.primengTableHelper.hideLoadingIndicator();
            })).subscribe(() => {
                this.notify.success(this.l('Record update successfully'));
            });
    }
}
