<div [@routerTransition]>
    <div class="card card-custom h-100">
        <div class="card-header align-items-center border-0">
            <h3 class="card-title align-items-start flex-column">
                <span class="font-weight-bolder text-dark">
                    {{l('PendingOffTimeRequests')}}
                </span>
            </h3>
            <div class="card-toolbar">
                <button class="btn btn-primary" (click)="reloadPage()"><i class="fa fa-sync"></i> </button>
            </div>
        </div>
        <div class="card-body" [perfectScrollbar]>
            <div class="row list-separated text-center" style="min-height: 565px !important;">
                <!--<Primeng-Datatable-start>-->
                <div class="primeng-datatable-container col-12" [busyIf]="primengTableHelper.isLoading">
                    <p-table #dataTable (onLazyLoad)="getAllpendingOffTimeRequests($event)"
                        [value]="primengTableHelper.records" scrollHeight="400px" id="custom-yscrollable-td" rows="{{ primengTableHelper.defaultRecordsCountPerPage }}"
                        [paginator]="false" [lazy]="true" [scrollable]="true" ScrollWidth="100%"
                        [responsive]="primengTableHelper.isResponsive"
                        [resizableColumns]="primengTableHelper.resizableColumns">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 60px" [hidden]="
                                            !isGrantedAny(
                                                'Pages.OffTimeRequests.Approve',
                                                'Pages.OffTimeRequests.Reject'
                                            )
                                        ">
                                    {{ l('Actions') }}
                                </th>
                                <th style="width: 150px" pSortableColumn="employeeFk.fullName">
                                    {{ l('Employee') }}
                                    <p-sortIcon field="employeeFullName"></p-sortIcon>
                                </th>

                                <th style="width: 150px" pSortableColumn="from">
                                    {{ l('From') }}
                                    <p-sortIcon field="offTimeRequest.from"></p-sortIcon>
                                </th>
                                <th style="width: 150px" pSortableColumn="to">
                                    {{ l('To') }}
                                    <p-sortIcon field="offTimeRequest.to"></p-sortIcon>
                                </th>
                                <th style="width: 150px" pSortableColumn="reason">
                                    {{ l('Reason') }}
                                    <p-sortIcon field="offTimeRequest.reason"></p-sortIcon>
                                </th>

                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-record="$implicit" let-i="rowIndex">
                            <tr>
                                <td class="text-center" style="width: 60px" [hidden]="
                                            !isGrantedAny(
                                                'Pages.OffTimeRequests.Approve',
                                                'Pages.OffTimeRequests.Reject'
                                            )
                                        ">
                                    <!-- <button (click)="viewOffTimePenddingRequest(record)" class="btn btn-sm btn-primary">
                                            {{ l('View') }}
                                        </button> -->
                                    <i class="fas fa-eye text-primary" (click)="viewOffTimePenddingRequest(record)"></i>
                                </td>
                                <td style="width: 150px">
                                    <ng-template #popTemplate>
                                        <div class="font-weight-bold">{{employeePopOverName}}</div>
                                        <div class="mt-3 symbol symbol-50 symbol-lg-120"><img class="symbol-label"
                                                [src]="employeeImage" alt="ImageMissing"></div>
                                    </ng-template>
                                    <a [routerLink]="['/app/main/employees/employees']"
                                        [queryParams]="{filterText:record.employeeFullName}" class="cursor-pointer">
                                        <span class="p-column-title"> {{ l('Employee') }}</span> <span container="body"
                                            (mouseover)="getEmployeePopoverDetail(record.employeeFullName)"
                                            (mouseout)="clearEmployeePopoverDetail(employeePopOverTimeOut)"
                                            triggers="mouseenter:mouseleave" placement="right"
                                            [popover]="popTemplate">{{ record.employeeFullName }}</span>
                                    </a>
                                </td>

                                <td style="width: 150px">
                                    <span class="p-column-title"> {{ l('From') }}</span>
                                    <span>
                                        {{ record.offTimeRequest.from | luxonFormat:'EEE MM/dd/yyyy' }}
                                    </span>
                                </td>
                                <td style="width: 150px">
                                    <span class="p-column-title"> {{ l('To') }}</span>
                                    <span *ngIf="record.offTimeRequest.to">
                                        {{ record.offTimeRequest.to | luxonFormat:'EEE MM/dd/yyyy' }}
                                    </span>
                                    <span *ngIf="!record.offTimeRequest.to">-</span>
                                </td>
                                <td style="width: 150px" data-toggle="tooltip"
                                    title="{{ record.offTimeRequest.reason }}" data-placement="right">
                                    <span class="p-column-title"> {{ l('Reason') }}</span> {{
                                    record.offTimeRequest.reason }}
                                </td>

                            </tr>
                        </ng-template>
                    </p-table>
                    <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                        {{ l('NoData') }}
                    </div>
                    <div class="primeng-paging-container">
                        <p-paginator [rows]="primengTableHelper.defaultRecordsCountPerPage" #paginator
                            (onPageChange)="getAllpendingOffTimeRequests($event)"
                            [totalRecords]="primengTableHelper.totalRecordsCount"
                            [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage"
                            [showCurrentPageReport]="true" [currentPageReportTemplate]="
                                    'TotalRecordsCount' | localize: primengTableHelper.totalRecordsCount
                                ">
                        </p-paginator>
                    </div>
                </div>
                <!--<Primeng-Datatable-End>-->
            </div>
        </div>
    </div>
    <!-- <viewOffTimeRequestModal #viewOffTimeRequestModal (modalSave)="getAllpendingOffTimeRequests()"></viewOffTimeRequestModal> -->
    <employeeProfileModal #employeeProfileModal> </employeeProfileModal>
</div>