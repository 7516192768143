import { Component, Injector, Input, Output, EventEmitter, ViewChild, ViewEncapsulation } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ContractTimeCardApprovalServiceProxy, CustomerApprovalStatus, SendAssociateReminderInputDto, StaffTimeCardApprovalDetailDto, CreateOrEditShiftBreakConfigurationDto, JobOrderShiftBreakDto, UpdateTimeCardInputDto, ApplyToAllInputDto, CreateOrEditJobOrderShiftBreakDto, ContractTimeCardsV2ServiceProxy, ContractTimeCardApprovalInputDto, ContractTimeCardsV2ApplyToAllInputDto, ContractEmailApprovalTimeCardsServiceProxy } from '@shared/service-proxies/service-proxies';
import { DateTime, IANAZone } from 'luxon';
import { LazyLoadEvent } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { finalize } from 'rxjs/operators';
import { AppConsts } from '@shared/AppConsts';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { JobOrderShiftBreaksForContractComponent } from '@app/shared/common/shift-breaks/jobOrderShiftBreaks/jobOrderShiftBreaksForContract.component';
import { EditNotesForContractTimeCardModal } from '@app/staff/edit-notes-for-contract-time-card-modal/edit-notes-for-contract-time-card-modal.component';

@Component({
    selector: 'customerStaffPendingContractV2TimeCards',
    templateUrl: './customer-staff-pending-contract-time-cards-v2.component.html',
    styleUrls: ['./customer-staff-pending-contract-time-cards-v2.component.css'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()]
})
export class CustomerStaffPendingContractV2TimeCards extends AppComponentBase {
    @Output() totalCount = new EventEmitter<any>();
    @ViewChild('dataTable', { static: true }) dataTable: Table;
    @ViewChild('paginator', { static: true }) paginator: Paginator;
    @ViewChild('JobOrderShiftBreaks', { static: true }) JobOrderShiftBreaks: JobOrderShiftBreaksForContractComponent;
    @ViewChild('EditNotesForContractTimeCardModal', { static: true }) editNotesForContractTimeCardModal: EditNotesForContractTimeCardModal;

    @Input() selectedPayRollPeriod: DateTime;
    @Input() missingTimeEntries: string;
    @Input() showSelectedPayRollPeriod: boolean = false;
    fraction = 0;
    payrollPeriodId: number = undefined;
    employeePopOverTimeOut: any;
    sendReminderToEmployee: SendAssociateReminderInputDto = new SendAssociateReminderInputDto();
    customerApprovalStatus: CustomerApprovalStatus;
    employeeDetailForPopOver: any;
    employeeImage = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
    expanded: false;
    employeePopOverName: any;
    selectedAll = false;
    userId: number;
    jobOrderId: number;
    tenantId: number;
    payCodeFilter:string;
    cutOffFilterEnabled: boolean = true;
    businessUnitId: number;
    checkedRecordLength = 0;
    showOnlyMyJobOrders: boolean=false;
    public dateRange: DateTime[] = [this._dateTimeService.getStartOfDayMinusDays(30), this._dateTimeService.getEndOfDay()];
    readyForApprovalDto: ContractTimeCardApprovalInputDto[];
    timeZoneToConvert = 'UTC';
    timeArr : any[] = [];
    isSatff: boolean = false;
    constructor(
        injector: Injector,
        private readonly _timecardService: ContractEmailApprovalTimeCardsServiceProxy,
        private _dateTimeService: DateTimeService) {
        super(injector);
    }

    getTimeCardApproval(dateRange: DateTime[], userId?: number, tenantId?:number, clockIssue?:string, payPeriodId?: number, selectedPayRollPeriod?: DateTime) {
        this.userId = userId;
        this.tenantId = tenantId;
        this.primengTableHelper.records = [];
        this.timeArr = [];
        this.readyForApprovalDto = [];
        this.isSatff = clockIssue === 'staff';
        this.dateRange = dateRange;
        if (payPeriodId != undefined)this.payrollPeriodId = payPeriodId;
        this.selectedPayRollPeriod = selectedPayRollPeriod;
        this.selectedAll=false;
        if (this.userId && this.tenantId) {
            this.primengTableHelper.showLoadingIndicator();
            this._timecardService.getPendingCustomerAndStaffTimeCardsV2(
                this.payrollPeriodId,
                this.selectedPayRollPeriod,
                this.primengTableHelper?.getSorting(this.dataTable),
                this.userId,
                this.tenantId,
                clockIssue
            ).pipe(finalize(() => {
                this.primengTableHelper.hideLoadingIndicator();
            })).subscribe((result) => {
                this.timeArr = result.items;
                this.timeArr.map(item => {
                    let timeZone = IANAZone.isValidZone(item.locationTimeZone) ? item.locationTimeZone : abp.timing.timeZoneInfo.iana.timeZoneId;
                    item.checkInJsTimeFirst = item.checkInTimeFirst ? new Date(item.checkInTimeFirst?.setZone(timeZone).toISO({ includeOffset: false })) : undefined;
                    item.checkOutJsTimeFirst = item.checkOutTimeFirst ? new Date(item.checkOutTimeFirst?.setZone(timeZone).toISO({ includeOffset: false })) : undefined;
                    item.checkInJsTimeSecond = item.checkInTimeSecond ? new Date(item.checkInTimeSecond?.setZone(timeZone).toISO({ includeOffset: false })) : undefined;
                    item.checkOutJsTimeSecond = item.checkOutTimeSecond ? new Date(item.checkOutTimeSecond?.setZone(timeZone).toISO({ includeOffset: false })) : undefined;
                    item.checkInJsTimeThird = item.checkInTimeThird ? new Date(item.checkInTimeThird?.setZone(timeZone).toISO({ includeOffset: false })) : undefined;
                    item.checkOutJsTimeThird = item.checkOutTimeThird ? new Date(item.checkOutTimeThird?.setZone(timeZone).toISO({ includeOffset: false })) : undefined;
                    const { hours, minutes } = this.convertHoursToTime(item.enteredHours);
                    item.hours = hours;
                    item.minutes = minutes;
                    item.breakConfigurations.map(b => {
                        b.breakTime = item.breakTimeOfSifts.find(item2 => item2.shiftBreakConfigurationId == b.id)?.breakTime;
                    })
                });
                this.primengTableHelper.records = this.timeArr;
                this.totalCount.emit({
                    count: this.primengTableHelper.records.length,
                    checkTabCount: false,
                });
            });
        }
    }

    convertHoursToTime(enteredHours: number) {
        const totalMinutes = enteredHours * 60;
        const hours = Math.floor(totalMinutes / 60);
        const minutes = Math.round(totalMinutes % 60);
        return {hours, minutes};
    }

    applyToAll() {
        let isNullable = this.readyForApprovalDto.some((x) => x.checkInTimeFirst == null || x.checkOutTimeFirst == null);
        if (isNullable) {
            this.message.warn('One row should be selected and Check-in and check-out time must be filled');
            return;
        }
        let isWrongTime = this.readyForApprovalDto.some((x) => x.normalHours < 0 || x.normalHours > 24);
        if (isWrongTime) {
            this.message.warn(this.l("HoursWorkedShouldBeInBetween0And24"));
            return;
        }
        if (this.checkedRecordLength == 1) {

            let item = this.readyForApprovalDto.find((x) => x.checkInTimeFirst != null && x.checkOutTimeFirst != null);
            let input = new ContractTimeCardsV2ApplyToAllInputDto();
            input.jobOrderShiftId = item.shiftId;
            input.contractTimeCardApproval= item;
            let allShifts = this.primengTableHelper.records.map(s=>parseInt(s.shiftId));
            input.shiftIdsToApply =allShifts;
            this.checkedRecordLength = allShifts.length;
            if (this.checkedRecordLength > 0) {
                this.message.confirm(
                    'This would effect ' + this.checkedRecordLength + ' ' + this.l('records'),
                    this.l('AreYouSure'),
                    (isConfirmed) => {
                        if (isConfirmed) {
                            this.primengTableHelper.showLoadingIndicator();
                            // this._timecardService
                            //     .applyToAllJobOrderShifts(input)
                            //     .pipe(
                            //         finalize(() => {
                            //             this.primengTableHelper.hideLoadingIndicator();
                            //         })
                            //     )
                            //     .subscribe((result) => {
                            //         // let shiftIds = this.readyForApprovalDto.map((x) => x.shiftId);
                            //         this.readyForApprovalDto = [];
                            //         this.checkedRecordLength = 0;
                            //         this.tabCountChange([]);
                            //         this.notify.success(this.l('SuccessfullSatatusChanged'));
                            //     });
                        }
                    }
                );
            }
        } else {
            this.message.warn('Please select only one row to apply all');
        }
    }


    checkUncheckRecord(record) {

        let input = new ContractTimeCardApprovalInputDto();
        var recordIndex = this.readyForApprovalDto.findIndex((x) => x.shiftId == record.shiftId);
        let timeZone = IANAZone.isValidZone(record.locationTimeZone) ? record.locationTimeZone : abp.timing.timeZoneInfo.iana.timeZoneId;
        if (record.isSelected == true) {
            record.isSelected = false;
            this.readyForApprovalDto.splice(recordIndex, 1);
        } else {
            record.isSelected = true;
            input.shiftId = record.shiftId;
            if (record.checkInJsTimeFirst && record.checkOutJsTimeFirst) {
                let checkInDateTime = DateTime.now();
                let checkOutDateTime = DateTime.now();
                let checkInTime = new Date(record.checkInJsTimeFirst.toString());
                let checkOutTime = new Date(record.checkOutJsTimeFirst.toString());
                input.checkInTimeFirst = checkInDateTime.set({
                    year: record.shiftStartDate.setZone(timeZone).get('year'),
                    month: record.shiftStartDate.setZone(timeZone).get('month'),
                    day: record.shiftStartDate.setZone(timeZone).get('day'),
                    hour: checkInTime.getHours(),
                    minute: checkInTime.getMinutes(),
                    second: checkInTime.getSeconds(),
                });
                input.checkOutTimeFirst = checkOutDateTime.set({
                    year: record.shiftStartDate.setZone(timeZone).get('year'),
                    month: record.shiftStartDate.setZone(timeZone).get('month'),
                    day: record.shiftStartDate.setZone(timeZone).get('day'),
                    hour: checkOutTime.getHours(),
                    minute: checkOutTime.getMinutes(),
                    second: checkOutTime.getSeconds(),
                });
                input.checkInTimeFirst = input.checkInTimeFirst.setZone(this.timeZoneToConvert, { keepLocalTime: true });
                input.checkOutTimeFirst = input.checkOutTimeFirst.setZone(this.timeZoneToConvert, { keepLocalTime: true });
            }
            if (record.checkInJsTimeSecond && record.checkOutJsTimeSecond) {
                let checkInDateTimeSecond = DateTime.now();
                let checkOutDateTimeSecond = DateTime.now();
                let checkInTimeSecond = new Date(record.checkInJsTimeSecond.toString());
                let checkOutTimeSecond = new Date(record.checkOutJsTimeSecond.toString());
                input.checkInTimeSecond = checkInDateTimeSecond.set({
                    year: record.shiftStartDate.setZone(timeZone).get('year'),
                    month: record.shiftStartDate.setZone(timeZone).get('month'),
                    day: record.shiftStartDate.setZone(timeZone).get('day'),
                    hour: checkInTimeSecond.getHours(),
                    minute: checkInTimeSecond.getMinutes(),
                    second: checkInTimeSecond.getSeconds(),
                });
                input.checkOutTimeSecond = checkOutDateTimeSecond.set({
                    year: record.shiftStartDate.setZone(timeZone).get('year'),
                    month: record.shiftStartDate.setZone(timeZone).get('month'),
                    day: record.shiftStartDate.setZone(timeZone).get('day'),
                    hour: checkOutTimeSecond.getHours(),
                    minute: checkOutTimeSecond.getMinutes(),
                    second: checkOutTimeSecond.getSeconds(),
                });
                input.checkInTimeSecond = input.checkInTimeSecond.setZone(this.timeZoneToConvert, { keepLocalTime: true });
                input.checkOutTimeSecond = input.checkOutTimeSecond.setZone(this.timeZoneToConvert, { keepLocalTime: true });
            }
            if (record.checkInJsTimeThird && record.checkOutJsTimeThird) {
                let checkInDateTimeThird = DateTime.now();
                let checkOutDateTimeThird = DateTime.now();
                let checkInTimeThird = new Date(record.checkInJsTimeThird.toString());
                let checkOutTimeThird = new Date(record.checkOutJsTimeThird.toString());
                input.checkInTimeThird = checkInDateTimeThird.set({
                    year: record.shiftStartDate.setZone(timeZone).get('year'),
                    month: record.shiftStartDate.setZone(timeZone).get('month'),
                    day: record.shiftStartDate.setZone(timeZone).get('day'),
                    hour: checkInTimeThird.getHours(),
                    minute: checkInTimeThird.getMinutes(),
                    second: checkInTimeThird.getSeconds(),
                });
                input.checkOutTimeThird = checkOutDateTimeThird.set({
                    year: record.shiftStartDate.setZone(timeZone).get('year'),
                    month: record.shiftStartDate.setZone(timeZone).get('month'),
                    day: record.shiftStartDate.setZone(timeZone).get('day'),
                    hour: checkOutTimeThird.getHours(),
                    minute: checkOutTimeThird.getMinutes(),
                    second: checkOutTimeThird.getSeconds(),
                });
                input.checkInTimeThird = input.checkInTimeThird.setZone(this.timeZoneToConvert, { keepLocalTime: true });
                input.checkOutTimeThird = input.checkOutTimeThird.setZone(this.timeZoneToConvert, { keepLocalTime: true });
            }
            input.normalHours = record.enteredHours;
            input.customerNote = record.customerNote;
            this.readyForApprovalDto.push(input);
        }
        this.checkedRecordLength = this.primengTableHelper.records.filter((e) => e.isSelected == true).length;
    }

    selectAll() {
        this.selectedAll = !this.selectedAll;
        this.readyForApprovalDto = [];
        this.primengTableHelper.records.forEach((record) => {
            record.isSelected = !this.selectedAll;
            this.checkUncheckRecord(record);
        });
    }

    onBlurInFirst(event: any, record) {
        if (event?.target?.value != '') {
            let date = this.validateDate(event?.target?.value, false);
            this.onCheckinDataChangeFirst(date, record);
        }
    }
    onBlurInSecond(event: any, record) {
        if (event?.target?.value != '') {
            let date = this.validateDate(event?.target?.value, false);
            this.onCheckinDataChangeSecond(date, record);
        }
    }
    onBlurInThird(event: any, record) {
        if (event?.target?.value != '') {
            let date = this.validateDate(event?.target?.value, false);
            this.onCheckinDataChangeThird(date, record);
        }
    }

    onBlurOutFirst(event: any, record) {
        if (event?.target?.value != '') {
            let date = this.validateDate(event?.target?.value, false);
            this.onCheckOutDataChangeFirst(date, record);
        }
    }

    onBlurOutSecond(event: any, record) {
        if (event?.target?.value != '') {
            let date = this.validateDate(event?.target?.value, false);
            this.onCheckOutDataChangeSecond(date, record);
        }
    }

    onBlurOutThird(event: any, record) {
        if (event?.target?.value != '') {
            let date = this.validateDate(event?.target?.value, false);
            this.onCheckOutDataChangeThird(date, record);
        }
    }

    setReadyForApproval(){
        let isNullable = this.readyForApprovalDto.some((x) => (x.normalHours == null || x.normalHours ==0) && !x.customerNote?.trimStart().trimEnd());
        if (isNullable) {
            this.message.warn(this.l("AddNoteInCaseOfNoHoursWorked"));
            return;
        }
        let isWrongTime = this.readyForApprovalDto.some((x) => x.normalHours < 0 || x.normalHours > 24);
        if (isWrongTime) {
            this.message.warn(this.l("HoursWorkedShouldBeInBetween0And24"));
            return;
        }
        if(this.checkedRecordLength>1){
            this.message.confirm('This would effect ' + this.checkedRecordLength +' '+ this.l('records'),
             this.l('AreYouSure'), (isConfirmed) => {
                if (isConfirmed) {
                    this.setOnCustomerBehalf()
                };
            });
        }
        else{
            this.setOnCustomerBehalf();
        }
    }

    approveOnCustomerBehalf(){
        let isNullable = this.readyForApprovalDto.some((x) => (x.normalHours == null || x.normalHours ==0) && !x.customerNote?.trimStart().trimEnd());
        if (isNullable) {
            this.message.warn(this.l("AddNoteInCaseOfNoHoursWorked"));
            return;
        }
        let isWrongTime = this.readyForApprovalDto.some((x) => x.normalHours < 0 || x.normalHours > 24);
        if (isWrongTime) {
            this.message.warn(this.l("HoursWorkedShouldBeInBetween0And24"));
            return;
        }
        if(this.checkedRecordLength>1){
            this.message.confirm('This would effect ' + this.checkedRecordLength +' '+ this.l('records'),
             this.l('AreYouSure'), (isConfirmed) => {
                if (isConfirmed) {
                    this.setOnCustomerBehalf()
                };
            });
        }
        else{
            this.setOnCustomerBehalf();
        }
    }
    setOnCustomerBehalf() {
        this.primengTableHelper.showLoadingIndicator();
        this._timecardService.setCustomerApprovedOnCustomerBehalf(this.tenantId, this.userId, this.readyForApprovalDto).pipe(finalize(() => {
            this.primengTableHelper.hideLoadingIndicator();
        })).subscribe((result) => {
            let shiftIds = this.readyForApprovalDto.map((x) => x.shiftId);
            this.readyForApprovalDto = [];
            this.checkedRecordLength = 0;
            this.tabCountChange(shiftIds);
            this.notify.success(this.l('SuccessfullSatatusChanged'))
        })
    }

    tabCountChange(shiftId: number | number[]){
        this.selectedAll=false;
        let shiftIds = Array.isArray(shiftId) ? shiftId : [shiftId];
        this.primengTableHelper.records = this.primengTableHelper.records.filter(item => !shiftIds.includes(item.shiftId));
        this.totalCount.emit({
            count:this.primengTableHelper.records.length,
            checkTabCount:true
        });
    }

    sendToCustomerDispute() {
        let isNullable = this.readyForApprovalDto.some((x) => !x.customerNote?.trimStart().trimEnd());
        if (isNullable) {
            this.message.warn(this.l("PleaseAddNoteForRejection"));
            return;
        }
        if(this.checkedRecordLength>1){
            this.message.confirm('This would effect ' + this.checkedRecordLength +' '+ this.l('records'),
             this.l('AreYouSure'), (isConfirmed) => {
                if (isConfirmed) {
                    this.setToCustomerDispute()
                };
            });
        }
        else{
            this.setToCustomerDispute();
        }
    }

    setToCustomerDispute() {
        this.primengTableHelper.showLoadingIndicator();
        this._timecardService.setReadyForDispute(this.userId, this.tenantId, this.readyForApprovalDto).pipe(finalize(() => {
            this.primengTableHelper.hideLoadingIndicator();
        })).subscribe((result) => {
            let shiftIds = this.readyForApprovalDto.map((x) => x.shiftId);
            this.readyForApprovalDto = [];
            this.checkedRecordLength = 0;
            this.tabCountChange(shiftIds);
            this.notify.success(this.l('SuccessfullSatatusChanged'))
        })
    }



    validateDate(dateOrTime: string, isBlur) {
        if (!dateOrTime) {
          return false;
        }

        // Attempt to parse the string as a date
        let parsedDate = new Date(dateOrTime);

        // If the date is invalid, check if it's a valid time format
        if (isNaN(parsedDate.getTime())) {
          const timePattern = /^([0-9]{1,2}):([0-9]{2})\s?(am|pm)?$/i;
          const match = dateOrTime.match(timePattern);

          if (match) {
            const hours = parseInt(match[1], 10);
            const minutes = parseInt(match[2], 10);
            const period = match[3] ? match[3].toLowerCase() : null;

            if (period) {
              if (period === 'pm' && hours < 12) {
                parsedDate = new Date();
                parsedDate.setHours(hours + 12, minutes, 0, 0);
              } else if (period === 'am' && hours === 12) {
                parsedDate = new Date();
                parsedDate.setHours(0, minutes, 0, 0);
              } else {
                parsedDate = new Date();
                parsedDate.setHours(hours, minutes, 0, 0);
              }
            } else {
              parsedDate = new Date();
              parsedDate.setHours(hours, minutes, 0, 0);
            }
          } else {
            return false; // Invalid format
          }
        }

        if (isBlur) {
            return !isNaN(parsedDate.getTime());
        } else {
            return parsedDate;
        }
      }

    onNoteChange(event, record) {
        var item = this.readyForApprovalDto.find((x) => x.shiftId == record.shiftId);
        if (item) {
            item.customerNote = event;
        }
    }

    openShiftBreaksDetails(jobOrderShiftId:number, breakConfigurations: CreateOrEditShiftBreakConfigurationDto[], employeeId: number, isInfuture:boolean){
        if((!this.isGranted('Pages.CanUpdateTime') && this.isCustomer)){
            return;
        }
        this.JobOrderShiftBreaks.show(jobOrderShiftId, breakConfigurations, employeeId, true);
       }


    setDatePart(jsDate, datePart) {
        const dateTime = DateTime.fromJSDate(jsDate);

        const updatedDateTime = dateTime
            .set({ year: datePart.year, month: datePart.month, day: datePart.day });

        return updatedDateTime.toJSDate();
    }

    minutesToHoursAndMinutes(differenceInMs, record) {
        const minDifference = Math.max(Math.floor(differenceInMs / 60000), 0); // Ensure no negative values
        const hours = Math.floor(minDifference / 60);
        const minutes = minDifference % 60;
        record.hours = hours;
        record.minutes = minutes;
        const fraction = minutes / 60;
        const enteredHours = Number((hours + fraction).toFixed(2));

        if (enteredHours > 0 && enteredHours <= 18) { // Ensure enteredHours is between 0 and 18
            record.enteredHours = enteredHours;
        } else {
            record.enteredHours = 0; // Reset if the value is invalid
        }
    }


    onBreaksSave(){
        // this.totalCount.emit({
        //     count: this.primengTableHelper.records.length,
        //     checkTabCount: true,
        // });
       }
       onNotesEditClick(record){
         this.editNotesForContractTimeCardModal.show(record.associateNote,record.customerNote,record.staffNote,record.shiftId);

       }
       onNotesSave(obj){
        var item = this.readyForApprovalDto.find((x) => x.shiftId == obj.shiftId);
           if(obj.shiftId && obj.customerNote){
             let index = this.primengTableHelper.records.findIndex(f=>f.shiftId == obj.shiftId);
             if(index > -1){
                this.primengTableHelper.records[index].customerNote = obj.customerNote;
                if(item) item.customerNote = obj.customerNote;
             }
             let approvalDto = this.readyForApprovalDto.findIndex(f => f.shiftId == obj.shiftId);
             if (approvalDto > -1) {
                 this.readyForApprovalDto[index].customerNote = obj.customerNote;
             }
           }
       }


    onCheckinDataChangeFirst(event, record) {
        let timeZone = IANAZone.isValidZone(record.locationTimeZone) ? record.locationTimeZone : abp.timing.timeZoneInfo.iana.timeZoneId;
        var item = this.readyForApprovalDto.find((x) => x.shiftId == record.shiftId);
        if (item) {
            let checkInDateTime = DateTime.now();
            item.checkInTimeFirst = checkInDateTime.set({
                year: record.shiftStartDate.setZone(timeZone).get('year'),
                month: record.shiftStartDate.setZone(timeZone).get('month'),
                day: record.shiftStartDate.setZone(timeZone).get('day'),
                hour: event.getHours(),
                minute: event.getMinutes(),
                second: event.getSeconds(),
            });
            item.checkInTimeFirst = item.checkInTimeFirst.setZone(this.timeZoneToConvert, { keepLocalTime: true });
            // record.checkInTime = item.checkInTimeFirst
        }
        const datePart = {
            year: record.shiftStartDate.setZone(timeZone).year,
            month: record.shiftStartDate.setZone(timeZone).month,
            day: record.shiftStartDate.setZone(timeZone).day,
        };

        if (record.checkInJsTimeFirst && record.checkOutJsTimeFirst) {
            record.checkInJsTimeFirst = this.validateDate(record.checkInJsTimeFirst,false);
            record.checkOutJsTimeFirst = this.validateDate(record.checkOutJsTimeFirst,false);
            record.checkInJsTimeFirst = this.setDatePart(record.checkInJsTimeFirst, datePart);
            record.checkOutJsTimeFirst = this.setDatePart(record.checkOutJsTimeFirst, datePart);

            if (record.checkOutJsTimeFirst < record.checkInJsTimeFirst) {
                record.checkOutJsTimeFirst.setDate(record.checkOutJsTimeFirst.getDate() + 1);
            }

            record.checkOutInFirst = DateTime.fromISO(this.constructAnIsoString(record.checkInJsTimeFirst), { zone: 'utc' }).setZone(this.timeZoneToConvert, { keepLocalTime: true });
            this.calculateNormalHours(record);
        }

    }
    onCheckinDataChangeSecond(event, record) {
        let timeZone = IANAZone.isValidZone(record.locationTimeZone) ? record.locationTimeZone : abp.timing.timeZoneInfo.iana.timeZoneId;
        var item = this.readyForApprovalDto.find((x) => x.shiftId == record.shiftId);
        if (item) {
            let checkInDateSecond = DateTime.now();
            item.checkInTimeSecond = checkInDateSecond.set({
                year: record.shiftStartDate.setZone(timeZone).get('year'),
                month: record.shiftStartDate.setZone(timeZone).get('month'),
                day: record.shiftStartDate.setZone(timeZone).get('day'),
                hour: event.getHours(),
                minute: event.getMinutes(),
                second: event.getSeconds(),
            });
            item.checkInTimeSecond = item.checkInTimeSecond.setZone(this.timeZoneToConvert, { keepLocalTime: true });
            // record.checkInTime = item.checkInTimeFirst
        }
        const datePart = {
            year: record.shiftStartDate.setZone(timeZone).year,
            month: record.shiftStartDate.setZone(timeZone).month,
            day: record.shiftStartDate.setZone(timeZone).day,
        };

        if (record.checkInJsTimeSecond && record.checkOutJsTimeSecond) {
            record.checkInJsTimeSecond = this.validateDate(record.checkInJsTimeSecond,false);
            record.checkOutJsTimeSecond = this.validateDate(record.checkOutJsTimeSecond,false);
            record.checkInJsTimeSecond = this.setDatePart(record.checkInJsTimeSecond, datePart);
            record.checkOutJsTimeSecond = this.setDatePart(record.checkOutJsTimeSecond, datePart);

            if (record.checkOutJsTimeSecond < record.checkInJsTimeSecond) {
                record.checkOutJsTimeSecond.setDate(record.checkOutJsTimeSecond.getDate() + 1);
            }

            record.checkOutTimeSecond =  DateTime.fromISO(this.constructAnIsoString(record.checkOutJsTimeSecond), { zone: 'utc' }).setZone(this.timeZoneToConvert, { keepLocalTime: true });
            this.calculateNormalHours(record);
        }

    }

    onCheckinDataChangeThird(event, record) {
        let timeZone = IANAZone.isValidZone(record.locationTimeZone) ? record.locationTimeZone : abp.timing.timeZoneInfo.iana.timeZoneId;
        var item = this.readyForApprovalDto.find((x) => x.shiftId == record.shiftId);
        if (item) {
            let checkInDateThird = DateTime.now();
            item.checkInTimeThird = checkInDateThird.set({
                year: record.shiftStartDate.setZone(timeZone).get('year'),
                month: record.shiftStartDate.setZone(timeZone).get('month'),
                day: record.shiftStartDate.setZone(timeZone).get('day'),
                hour: event.getHours(),
                minute: event.getMinutes(),
                second: event.getSeconds(),
            });
            item.checkInTimeThird = item.checkInTimeThird.setZone(this.timeZoneToConvert, { keepLocalTime: true });
            // record.checkInTime = item.checkInTimeFirst
        }
        const datePart = {
            year: record.shiftStartDate.setZone(timeZone).year,
            month: record.shiftStartDate.setZone(timeZone).month,
            day: record.shiftStartDate.setZone(timeZone).day,
        };

        if (record.checkInJsTimeThird && record.checkOutJsTimeThird) {
            record.checkInJsTimeThird = this.validateDate(record.checkInJsTimeThird,false);
            record.checkOutJsTimeThird = this.validateDate(record.checkOutJsTimeThird,false);
            record.checkInJsTimeThird = this.setDatePart(record.checkInJsTimeThird, datePart);
            record.checkOutJsTimeThird = this.setDatePart(record.checkOutJsTimeThird, datePart);

            if (record.checkOutJsTimeThird < record.checkInJsTimeThird) {
                record.checkOutJsTimeThird.setDate(record.checkOutJsTimeThird.getDate() + 1);
            }

            record.checkInTimeThird =  DateTime.fromISO(this.constructAnIsoString(record.checkInJsTimeThird), { zone: 'utc' }).setZone(this.timeZoneToConvert, { keepLocalTime: true });
            this.calculateNormalHours(record);
        }

    }

    onCheckOutDataChangeFirst(event, record) {
        let timeZone = IANAZone.isValidZone(record.locationTimeZone) ? record.locationTimeZone : abp.timing.timeZoneInfo.iana.timeZoneId;
        var item = this.readyForApprovalDto.find((x) => x.shiftId == record.shiftId);
        if (item) {
            let checkOutDateTime = DateTime.now();
            item.checkOutTimeFirst = checkOutDateTime.set({
                year: record.shiftStartDate.setZone(timeZone).get('year'),
                month: record.shiftStartDate.setZone(timeZone).get('month'),
                day: record.shiftStartDate.setZone(timeZone).get('day'),
                hour: event.getHours(),
                minute: event.getMinutes(),
                second: event.getSeconds(),
            });
            item.checkOutTimeFirst = item.checkOutTimeFirst.setZone(this.timeZoneToConvert, { keepLocalTime: true });
        }
        if (record.checkInJsTimeFirst && record.checkOutJsTimeFirst) {
            record.checkInJsTimeFirst = this.validateDate(record.checkInJsTimeFirst,false);
            record.checkOutJsTimeFirst = this.validateDate(record.checkOutJsTimeFirst,false);
            const datePart = {
                year: record.shiftStartDate.setZone(timeZone).year,
                month: record.shiftStartDate.setZone(timeZone).month,
                day: record.shiftStartDate.setZone(timeZone).day,
            };

            record.checkInJsTimeFirst = this.setDatePart(record.checkInJsTimeFirst, datePart);
            record.checkOutJsTimeFirst = this.setDatePart(record.checkOutJsTimeFirst, datePart);

            if (record.checkOutJsTimeFirst < record.checkInJsTimeFirst) {
                record.checkOutJsTimeFirst.setDate(record.checkOutJsTimeFirst.getDate() + 1);
            }

            record.checkOutTimeFirst = DateTime.fromISO(this.constructAnIsoString(record.checkOutJsTimeFirst), { zone: 'utc' }).setZone(this.timeZoneToConvert, { keepLocalTime: true });
            this.calculateNormalHours(record);
        }
    }

    onCheckOutDataChangeSecond(event, record) {
        let timeZone = IANAZone.isValidZone(record.locationTimeZone) ? record.locationTimeZone : abp.timing.timeZoneInfo.iana.timeZoneId;
        var item = this.readyForApprovalDto.find((x) => x.shiftId == record.shiftId);
        if (item) {
            let checkOutDateTimeSecond = DateTime.now();
            item.checkOutTimeSecond = checkOutDateTimeSecond.set({
                year: record.shiftStartDate.setZone(timeZone).get('year'),
                month: record.shiftStartDate.setZone(timeZone).get('month'),
                day: record.shiftStartDate.setZone(timeZone).get('day'),
                hour: event.getHours(),
                minute: event.getMinutes(),
                second: event.getSeconds(),
            });
            item.checkOutTimeSecond = item.checkOutTimeSecond.setZone(this.timeZoneToConvert, { keepLocalTime: true });
        }
        if (record.checkInJsTimeSecond && record.checkOutJsTimeSecond) {
            record.checkInJsTimeSecond = this.validateDate(record.checkInJsTimeSecond,false);
            record.checkOutJsTimeSecond = this.validateDate(record.checkOutJsTimeSecond,false);

            const datePart = {
                year: record.shiftStartDate.setZone(timeZone).year,
                month: record.shiftStartDate.setZone(timeZone).month,
                day: record.shiftStartDate.setZone(timeZone).day,
            };

            record.checkInJsTimeSecond = this.setDatePart(record.checkInJsTimeSecond, datePart);
            record.checkOutJsTimeSecond = this.setDatePart(record.checkOutJsTimeSecond, datePart);

            if (record.checkOutJsTimeSecond < record.checkInJsTimeSecond) {
                record.checkOutJsTimeSecond.setDate(record.checkOutJsTimeSecond.getDate() + 1);
            }

            record.checkOutTimeSecond = DateTime.fromISO(this.constructAnIsoString(record.checkOutJsTimeSecond), { zone: 'utc' }).setZone(this.timeZoneToConvert, { keepLocalTime: true });
            this.calculateNormalHours(record);
        }
    }

    onCheckOutDataChangeThird(event, record) {
        let timeZone = IANAZone.isValidZone(record.locationTimeZone) ? record.locationTimeZone : abp.timing.timeZoneInfo.iana.timeZoneId;
        var item = this.readyForApprovalDto.find((x) => x.shiftId == record.shiftId);
        if (item) {
            let checkOutDateTimeThird = DateTime.now();
            item.checkOutTimeThird = checkOutDateTimeThird.set({
                year: record.shiftStartDate.setZone(timeZone).get('year'),
                month: record.shiftStartDate.setZone(timeZone).get('month'),
                day: record.shiftStartDate.setZone(timeZone).get('day'),
                hour: event.getHours(),
                minute: event.getMinutes(),
                second: event.getSeconds(),
            });
            item.checkOutTimeThird = item.checkOutTimeThird.setZone(this.timeZoneToConvert, { keepLocalTime: true });
        }
        if (record.checkInJsTimeThird && record.checkOutJsTimeThird) {
            record.checkInJsTimeThird = this.validateDate(record.checkInJsTimeThird,false);
            record.checkOutJsTimeThird = this.validateDate(record.checkOutJsTimeThird,false);

            const datePart = {
                year: record.shiftStartDate.setZone(timeZone).year,
                month: record.shiftStartDate.setZone(timeZone).month,
                day: record.shiftStartDate.setZone(timeZone).day,
            };

            record.checkInJsTimeThird = this.setDatePart(record.checkInJsTimeThird, datePart);
            record.checkOutJsTimeThird = this.setDatePart(record.checkOutJsTimeThird, datePart);

            if (record.checkOutJsTimeThird < record.checkInJsTimeThird) {
                record.checkOutJsTimeThird.setDate(record.checkOutJsTimeThird.getDate() + 1);
            }

            record.checkOutTimeThird = DateTime.fromISO(this.constructAnIsoString(record.checkOutJsTimeThird), { zone: 'utc' }).setZone(this.timeZoneToConvert, { keepLocalTime: true });
            this.calculateNormalHours(record);
        }
    }

    calculateNormalHours(record){
        if(record.checkInJsTimeFirst){
            let  hours=0;
            if(this.isValidDate(record.checkOutJsTimeThird)){
                hours= this.calculateTimeDifferences(record.checkInJsTimeFirst, record.checkOutJsTimeThird, record.timeZoneToConvert);
                let break1 = this.calculateTimeDifferences(record.checkOutJsTimeSecond, record.checkInJsTimeThird, record.timeZoneToConvert);
                let break2 = this.calculateTimeDifferences(record.checkOutJsTimeFirst, record.checkInJsTimeSecond, record.timeZoneToConvert);
                let sumOfBreaks = break1 + break2;
                hours = hours - sumOfBreaks;
            }
            else if(this.isValidDate(record.checkOutJsTimeSecond)){
                hours= this.calculateTimeDifferences(record.checkInJsTimeFirst, record.checkOutJsTimeSecond, record.timeZoneToConvert);
                let break1 = this.calculateTimeDifferences(record.checkOutJsTimeFirst, record.checkInJsTimeSecond, record.timeZoneToConvert);
                let sumOfBreaks = break1;
                hours = hours - sumOfBreaks;
            }
            else if(this.isValidDate(record.checkOutJsTimeFirst)){
                hours = this.calculateTimeDifferences(record.checkInJsTimeFirst, record.checkOutJsTimeFirst, record.timeZoneToConvert);
            }
            record.enteredHours = hours;
            var id = this.readyForApprovalDto.findIndex((x) => x.shiftId == record.shiftId);
            if(id > -1){
                this.readyForApprovalDto[id].normalHours = hours;
            }
        }

    }

    isValidDate(d) {
        return d instanceof Date && !isNaN(d.getTime());
      }

      calculateTimeDifferences(checkInJsTime, checkOutJsTime, timeZoneToConvert) {
        let checkInTime = DateTime.fromJSDate(checkInJsTime).setZone(timeZoneToConvert);
        let checkOutTime = DateTime.fromJSDate(checkOutJsTime).setZone(timeZoneToConvert);

        if (checkOutTime < checkInTime) {
            checkOutTime = checkOutTime.plus({ days: 1 });
        }

        const duration = checkOutTime.diff(checkInTime, ['hours', 'minutes']);
        const hours = Math.floor(duration.as('hours'));
        const minutes = Math.round(duration.as('minutes') % 60);

        return hours + minutes / 60;
    }
}
