import { finalize } from 'rxjs/operators';
import { Component, ViewChild, Injector, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { GetTimeEntryForViewDto, TimeEntriesServiceProxy, TimeEntryDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { JobOrderShiftBreaksComponent } from '@app/shared/common/shift-breaks/jobOrderShiftBreaks/jobOrderShiftBreaks.component';

@Component({
    selector: 'viewTimeEntryModal',
    templateUrl: './view-timeEntry-modal.component.html',
    styleUrls: ['./view-timeEntry-modal.component.less'],
})
export class ViewTimeEntryModalComponent extends AppComponentBase {
    @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('JobOrderShiftBreaks', { static: true }) JobOrderShiftBreaks: JobOrderShiftBreaksComponent;

    active = false;
    saving = false;
    lat: any;
    long: any;
    item: GetTimeEntryForViewDto;
    latlong: string[] = [];
    isManual = false;

    constructor(injector: Injector, private _timeEntriesServiceProxy: TimeEntriesServiceProxy) {
        super(injector);
        this.item = new GetTimeEntryForViewDto();
        this.item.timeEntry = new TimeEntryDto();
    }

    show(jOShiftId, item: GetTimeEntryForViewDto): void {
        this.item = new GetTimeEntryForViewDto();
        this.primengTableHelper.showLoadingIndicator();
        this._timeEntriesServiceProxy
            .getTimeEntryForView(item?.timeEntry?.id, jOShiftId)
            .pipe(
                finalize(() => {
                    this.primengTableHelper.hideLoadingIndicator();
                })
            )
            .subscribe((result) => {
                this.primengTableHelper.hideLoadingIndicator();
                this.item = result;
                this.checkOnlineOfflineStatus();
            });
        this.active = true;
        this.modal.show();
    }
    checkOnlineOfflineStatus() {
        if (
            !this.item.timeEntry.clockInDateTime &&
            !this.item.timeEntry.clockOutDateTime &&
            !this.item.timeEntry.isCheckInOnline
        ) {
            this.isManual = true;
        }
    }
    openShiftBreaksDetails(jobOrderShiftId:number ){
        this.JobOrderShiftBreaks.show(jobOrderShiftId);
       }
    openImage(url: string) {
        this.primengTableHelper.showLoadingIndicator();
        var image = new Image();
        image.src = 'data:image/jpg;base64,' + url;
        var w = window.open('');
        w.document.write(image.outerHTML);
        this.primengTableHelper.hideLoadingIndicator();
    }
    openMap(latlong: string) {
        window.open('https://www.google.com/maps/search/?api=1&query=' + latlong);
    }
    close(): void {
        this.item = new GetTimeEntryForViewDto();
        this.active = false;
        this.modal.hide();
    }
    removeAfterDecimal(data) {
        var romveAfterDecimalValue = Math.floor(data);
        if (romveAfterDecimalValue > 0) {
            return romveAfterDecimalValue;
        }
    }
}
