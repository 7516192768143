import { Component, Injector, ViewEncapsulation, ViewChild, Output, EventEmitter } from '@angular/core';
import { JobOrderShiftBreaksServiceProxy, RulesvViolatedDto, StaffTimeCardApprovalServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ViewJobOrderShiftBreakModalComponent } from './view-jobOrderShiftBreak-modal.component';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { Table } from 'primeng/table';
import { Paginator } from 'primeng/paginator';
import { LazyLoadEvent } from 'primeng/api';
import { FileDownloadService } from '@shared/utils/file-download.service';
import { filter as _filter } from 'lodash-es';
import { ModalDirective } from 'ngx-bootstrap/modal';


@Component({
    selector: 'JobOrderShiftBreaks',
    templateUrl: './jobOrderShiftBreaks.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
})
export class JobOrderShiftBreaksComponent extends AppComponentBase {
    @ViewChild('viewJobOrderShiftBreakModal', { static: true })
    viewJobOrderShiftBreakModal: ViewJobOrderShiftBreakModalComponent;
    @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('dataTable', { static: true }) dataTable: Table;
    @ViewChild('paginator', { static: true }) paginator: Paginator;
     
    breakRulesViolated:RulesvViolatedDto[]=[];
    totalBreakTimeSubtracted=0;
    advancedFiltersAreShown = false;
    filterText = '';
    employeeFirstNameFilter = '';
    jobOrderShiftNameFilter = '';
    jobOrderShiftId=0;
    isActive=false;
    constructor(
        injector: Injector,
        private _jobOrderShiftBreaksServiceProxy: JobOrderShiftBreaksServiceProxy,
        private _fileDownloadService: FileDownloadService,
        private _timecardService: StaffTimeCardApprovalServiceProxy
    ) {
        super(injector);
    }

    getJobOrderShiftBreaks(event?: LazyLoadEvent) {
        if(!this.isActive)  return;
        if (this.primengTableHelper.shouldResetPaging(event)) {
            this.paginator.changePage(0);
            if (this.primengTableHelper.records && this.primengTableHelper.records.length > 0) {
                return;
            }
        }

        this.primengTableHelper.showLoadingIndicator();

        this._timecardService
            .getAllShiftBreaksListByShift(
                this.filterText,
                this.jobOrderShiftId,
                this.primengTableHelper.getSorting(this.dataTable),
                this.primengTableHelper.getSkipCount(this.paginator, event),
                this.primengTableHelper.getMaxResultCount(this.paginator, event)
            )
            .subscribe((result) => {
                this.primengTableHelper.totalRecordsCount = result.totalCount;
                this.primengTableHelper.records = result.items;
                this.primengTableHelper.hideLoadingIndicator();
            });
    }
    getShiftBreaksRules(){
        this.primengTableHelper.showLoadingIndicator();
        this._timecardService
            .getBreakRulesViolated(this.jobOrderShiftId )
            .subscribe((result) => {
                 this.breakRulesViolated= result.breakViolatedRules;
                 this.totalBreakTimeSubtracted= result.breakTimeSubtracted;
                this.primengTableHelper.hideLoadingIndicator();
            });
    }
    reloadPage(): void {
        this.paginator.changePage(this.paginator.getPage());
    }

    show(shiftId: number): void {
        if(shiftId !=null){
        this.isActive=true;
        this.jobOrderShiftId=shiftId;
        this.breakRulesViolated=[];
        this.getShiftBreaksRules();
        this.modal.show();
        this.getJobOrderShiftBreaks();
        }
    }

    exportToExcel(): void {
        this._jobOrderShiftBreaksServiceProxy
            .getJobOrderShiftBreaksToExcel(this.filterText, this.employeeFirstNameFilter, this.jobOrderShiftNameFilter,this.jobOrderShiftId)
            .subscribe((result) => {
                this._fileDownloadService.downloadTempFile(result);
            });
    }


    resetFilters(): void {
        this.filterText = '';

        this.getJobOrderShiftBreaks();
    }
    close(): void {
        this.modal.hide();
    }
}
