<span class="hidetoggel" style="padding-left: 25px" (click)="toggleSidebar()">
    <i style="cursor: pointer; font-size: 16px;" class="fa fa-search"></i>
</span>
<p-autoComplete
  [(ngModel)]="selected"
  [suggestions]="searchMenuResults"
  (completeMethod)="searchMenuItem($event)"
  *ngIf="isMenuSearchActive"
  (onSelect)="selectMenuItem($event)"
  field="name"
  placeholder="{{ 'QuickNav' | localize }}"
  [minLength]="1"
  (mousedown)="stopPropagation($event)"
  (wheel)="allowScroll($event)"
  inputStyleClass="form-control menuSearchBarInput"
  styleClass="menuSearchBarContainer custom-autocomplete-style"  
  [style]="{ padding: '0 10px' }"
></p-autoComplete>