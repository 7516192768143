<div appBsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" (onShown)="shown()" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    {{options.title}}
                </h4>
                <button type="button" class="close" (click)="close()" [attr.aria-label]="l('Close')">
                     <i aria-hidden="true" class="ki ki-close"></i>
                </button>
            </div>
            <div class="modal-body">
                <form novalidate (ngSubmit)="refreshTable()" autocomplete="new-password">
                    <div *ngIf="options.isFilterEnabled" class="input-group mb-4">
                        <input autoFocus [(ngModel)]="filterText" name="filterText" class="form-control" placeholder="{{'SearchWithThreeDot' | localize}}" type="text">
                        <span class="input-group-btn">
                            <button class="btn btn btn-primary" type="submit"><i class="flaticon-search-1" [attr.aria-label]="l('Search')"></i></button>
                        </span>
                    </div>

                    <!--<Primeng-TurboTable-Start>-->
                    <div class="primeng-datatable-container" [busyIf]="primengTableHelper.isLoading">
                        <p-table #dataTable (onLazyLoad)="getRecordsIfNeeds($event)" [value]="primengTableHelper.records" rows="{{primengTableHelper.defaultRecordsCountPerPage}}" [paginator]="false" [lazy]="true" [responsive]="primengTableHelper.isResponsive">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width:15%">
                                        {{'Select' | localize}}
                                    </th>
                                    <th>
                                        {{'Name' | localize}}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-record="$implicit">
                                <tr>
                                    <td>
                                        <button class="btn btn-outline-primary btn-sm btn-icon" (click)="selectItem(record)" title="{{'Select' | localize}}">
                                            <i class="la la-chevron-circle-right" [attr.aria-label]="l('Select')"></i>
                                        </button>
                                    </td>
                                    <td>
                                        <span class="p-column-title">{{'Name' | localize}}</span> {{record.name}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                            {{'NoData' | localize}}
                        </div>
                        <div class="primeng-paging-container">
                            <p-paginator [rows]="primengTableHelper.defaultRecordsCountPerPage" #paginator (onPageChange)="getRecordsIfNeeds($event)" [totalRecords]="primengTableHelper.totalRecordsCount" [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage">
                            </p-paginator>
                            <span class="total-records-count">
                                {{'TotalRecordsCount' | localize:primengTableHelper.totalRecordsCount}}
                            </span>
                        </div>
                    </div>
                    <!--<Primeng-TurboTable-End>-->
                </form>

            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-default font-weight-bold" (click)="close()">{{"Cancel" | localize}}</button>
            </div>

        </div>
    </div>
</div>