import {
    PermissionCheckerService,
    FeatureCheckerService,
    LocalizationService,
    MessageService,
    AbpMultiTenancyService,
    NotifyService,
    SettingService
} from 'abp-ng2-module';
import { Component, Injector } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppUrlService } from '@shared/common/nav/app-url.service';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppUiCustomizationService } from '@shared/common/ui/app-ui-customization.service';
import { PrimengTableHelper } from 'shared/helpers/PrimengTableHelper';
import { CommonLookupServiceProxy, GetProfilePictureOutput, UiCustomizationSettingsDto } from '@shared/service-proxies/service-proxies';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSpinnerTextService } from '@app/shared/ngx-spinner-text.service';
import { DateTime } from 'luxon';
import { take } from 'rxjs/operators';

export abstract class AppComponentBase {

    localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;
    commonLookup: CommonLookupServiceProxy;
    localization: LocalizationService;
    permission: PermissionCheckerService;
    feature: FeatureCheckerService;
    notify: NotifyService;
    setting: SettingService;
    message: MessageService;
    multiTenancy: AbpMultiTenancyService;
    appSession: AppSessionService;
    primengTableHelper: PrimengTableHelper;
    ui: AppUiCustomizationService;
    appUrlService: AppUrlService;
    spinnerService: NgxSpinnerService;
    private ngxSpinnerTextService: NgxSpinnerTextService;
    btnDisabled: boolean;

    summernoteConfig = {
        placeholder: '',
        tabsize: 2,
        height: '200px',
        uploadImagePath: '/api/upload',
        toolbar: [
            ['style', ['bold', 'itali   c', 'underline', 'clear']],
            ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
            ['fontsize', ['fontname', 'fontsize', 'color']],
            ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
            ['insert', ['table', 'picture', 'link', 'video', 'hr']]
        ],
        fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
    };
    constructor(injector: Injector) {
        this.localization = injector.get(LocalizationService);
        this.permission = injector.get(PermissionCheckerService);
        this.feature = injector.get(FeatureCheckerService);
        this.notify = injector.get(NotifyService);
        this.setting = injector.get(SettingService);
        this.message = injector.get(MessageService);
        this.multiTenancy = injector.get(AbpMultiTenancyService);
        this.appSession = injector.get(AppSessionService);
        this.commonLookup = injector.get(CommonLookupServiceProxy);
        this.ui = injector.get(AppUiCustomizationService);
        this.appUrlService = injector.get(AppUrlService);
        this.primengTableHelper = new PrimengTableHelper();
        this.spinnerService = injector.get(NgxSpinnerService);
        this.ngxSpinnerTextService = injector.get(NgxSpinnerTextService);
    }
    showEmployeProfile(employeeid) {

    }
    getEmployeeProfileForPopover(employeeId) {
        return new Promise(resolve => {
            this.commonLookup.getEmployeeUserPicture(employeeId)
                .subscribe((result) => {
                    resolve(result);
                })
        })
    }
    clearEmployeePopoverDetail(employeePopOverTimeOut) {
        clearTimeout(employeePopOverTimeOut);
    }
    getEmployeeProfilePictureForPopover(result) {
        if (result.profilePicture == "") {
            return AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
        }
        else {
            return 'data:image/jpeg;base64,' + result.profilePicture;
        }
    }

    flattenDeep(array) {
        return array.reduce((acc, val) =>
            Array.isArray(val) ?
                acc.concat(this.flattenDeep(val)) :
                acc.concat(val),
            []);
    }

    l(key: string, ...args: any[]): string {
        args.unshift(key);
        args.unshift(this.localizationSourceName);
        return this.ls.apply(this, args);
    }

    ls(sourcename: string, key: string, ...args: any[]): string {
        let localizedText = this.localization.localize(key, sourcename);

        if (!localizedText) {
            localizedText = key;
        }

        if (!args || !args.length) {
            return localizedText;
        }

        args.unshift(localizedText);
        return abp.utils.formatString.apply(this, this.flattenDeep(args));
    }

    isGranted(permissionName: string): boolean {
        return this.permission.isGranted(permissionName);
    }

    isGrantedAny(...permissions: string[]): boolean {
        if (!permissions) {
            return false;
        }

        for (const permission of permissions) {
            if (this.isGranted(permission)) {
                return true;
            }
        }

        return false;
    }

    s(key: string): string {
        return abp.setting.get(key);
    }

    appRootUrl(): string {
        return this.appUrlService.appRootUrl;
    }

    get currentTheme(): UiCustomizationSettingsDto {
        return this.appSession.theme;
    }

    get containerClass(): string {
        if (this.appSession.theme.baseSettings.layout.layoutType === 'fluid') {
            return 'container-fluid';
        }

        return 'container';
    }

    showMainSpinner(text?: string): void {
        this.ngxSpinnerTextService.setText(text);
        this.spinnerService.show();
    }

    hideMainSpinner(text?: string): void {
        this.spinnerService.hide();
    }

    convertUtcToLocal(datetime: any): string {
        let timeZone = abp.timing.timeZoneInfo.iana.timeZoneId;
        const dateObject = new Date(datetime).toLocaleString("en-US", {
            timeZone,
        });
        return dateObject;
    }
    validationCheck(item: string) {
        if (item.trimStart() == null || item.trimStart() == '' || item.trimEnd() == null || item.trimEnd() == '') {
            this.btnDisabled = true;
        } else {
            this.btnDisabled = false;
        }
    }
    
    IsNullOrEmpty(item: string):boolean {
        if (!item || item.trimStart() == null || item.trimStart() == '' || item.trimEnd() == null || item.trimEnd() == '') {
            return true;
        } else {
            return false;
        }
    }
    
    removeAfterDecimal(data){
        var romveAfterDecimalValue  = Math.floor(data);
        if(romveAfterDecimalValue > 0){
            return romveAfterDecimalValue ;
        }
    }
    checkMinMax( min: number,max: number): boolean {
        if(min > max){
            return true;
        }
        return false;
    }
    constructAnIsoString(jsDate ){
        if(jsDate){
        let year = jsDate.getFullYear();
        let month = jsDate.getMonth() + 1; // Months are 0-based in JavaScript
        let date = jsDate.getDate();
        let hours = jsDate.getHours();
        let minutes = jsDate.getMinutes();
        let seconds = jsDate.getSeconds();

        // Pad the month, date, hours, minutes, and seconds with leading zeros if necessary
        month = month < 10 ? '0' + month : month;
        date = date < 10 ? '0' + date : date;
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;

        // Construct an ISO string
        return`${year}-${month}-${date}T${hours}:${minutes}:${seconds}`;
        }
    }
    
    get isCustomer(): boolean {
        return this.appSession.isCustomer;
    }
} 

