import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { EmployeeProfileDetailServiceProxy, GetEmployeeForViewDto } from '@shared/service-proxies/service-proxies';
import { debounceTime, finalize } from 'rxjs/operators';

@Component({
    selector: 'employeeSummary',
    templateUrl: './employee-summary.component.html',
})
export class EmployeeSummaryComponent extends AppComponentBase implements OnInit {

    @Input() employeeId: number;
    @Input() invitationflage: boolean;
    @Output() geoPref: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() gma: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() invitation: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() feedback: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() shiftRejection: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() shiftAssignment: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() shiftCompleted: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() employeeLoction: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() approveTimeOffReq: EventEmitter<boolean> = new EventEmitter<boolean>();
    employeeDetail: GetEmployeeForViewDto = new GetEmployeeForViewDto();

    employeeImage = '';

    constructor(
        injector: Injector,
        private readonly employeeProfileDetailservice: EmployeeProfileDetailServiceProxy
    ) {
        super(injector);
    }

    ngOnInit() {
        this.getEmployeeProfileDetailsForView();
        this.bindEmployeeProfileRefreshEvent();
    }

    getEmployeeProfileDetailsForView(){
        this.employeeImage = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
        if (this.employeeId != undefined) {
            this.primengTableHelper.showLoadingIndicator();
            this.employeeProfileDetailservice.getEmployeeProfileDetail(this.employeeId).pipe(finalize(() => {
                this.getProfilePicture();
                this.primengTableHelper.hideLoadingIndicator();
            })).subscribe(employee => {
                this.employeeDetail = employee;
            });
        }
    }

    getProfilePicture(): void {
        this.employeeProfileDetailservice.getEmployeeUserPicture(this.employeeId).subscribe(result => {
            if (result && result.profilePicture) {
                this.employeeImage = 'data:image/jpeg;base64,' + result.profilePicture;
            }
        });
    }
    bindEmployeeProfileRefreshEvent(){
        abp.event.on('EmployeeProfile.Updated', () => {
            this.getEmployeeProfileDetailsForView();
        });
      }
}
