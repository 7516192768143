<div [@routerTransition]>
    <div class="card card-custom h-100">
        <div class="card-header align-items-center border-0">
            <h3 class="card-title align-items-start flex-column">
                <span class="font-weight-bolder text-dark"> {{l('InvitationAcceptance')}} </span>
            </h3>
            <div class="card-toolbar">
                <button class="btn btn-primary" (click)="reloadPage()"><i class="fa fa-sync"></i></button>
            </div>
        </div>
        <div class="card-body" [perfectScrollbar]>
            <div class="row list-separated text-center" style="min-height: 565px !important">
                <!--<Primeng-Datatable-start>-->
                <div class="primeng-datatable-container col-12" [busyIf]="primengTableHelper.isLoading">
                    <p-table
                        #dataTable
                        scrollHeight="400px" 
                        id="custom-yscrollable-td"
                        (onLazyLoad)="getRecentResponsesYes($event)"
                        [value]="primengTableHelper.records"
                        rows="{{ primengTableHelper.defaultRecordsCountPerPage }}"
                        [paginator]="false"
                        [lazy]="true"
                        [scrollable]="true"
                        ScrollWidth="100%"
                        [responsive]="primengTableHelper.isResponsive"
                        [resizableColumns]="primengTableHelper.resizableColumns"
                    >
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="lastModificationTime" style="width: 100px">
                                    {{ l('LastModificationTime') }}
                                    <p-sortIcon field="lastModificationTime"></p-sortIcon>
                                </th>
                                <th pSortableColumn="jobOrderId" style="width: 100px">
                                    {{ l('JobOrderId') }}
                                    <p-sortIcon field="jobOrderId"></p-sortIcon>
                                </th>
                                <th pSortableColumn="customerLocationName" style="width: 100px">
                                    {{ l('CustomerLocation') }}
                                    <p-sortIcon field="customerLocationName"></p-sortIcon>
                                </th>
                                <th pSortableColumn="employeeName" style="width: 100px">
                                    {{ l('Employee') }}
                                    <p-sortIcon field="employeeName"></p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-record="$implicit" let-i="rowIndex">
                            <tr>
                                <td  style="width: 100px" class="short-string-column">
                                    <span class="ui-column-title"> {{ l('LastModificatinTime') }}</span>
                                    {{record.lastModificationTime |luxonFormat: 'EEE MM/dd/yyyy hh:mm a'}}
                                </td>
                                <td   style="width: 100px" class="short-string-column">
                                    <span class="ui-column-title"> {{ l('JobOrderId') }}</span>
                                    <a
                                        [routerLink]="['/app/main/job-Order/job-Order-detail']"
                                        [queryParams]="{jobID:record.jobOrderId,inviteAccept:true}"
                                        class="cursor-pointer"
                                        >#{{ record.jobOrderId }}</a
                                    >
                                </td>
                                <td style="width: 100px" class="long-string-column">
                                    <span class="ui-column-title"> {{ l('CustomerLocation') }}</span> 
                                    {{record.customerLocationName }}
                                </td>
                                <td style="width: 100px" class="long-string-column">
                                    <span class="ui-column-title"> {{ l('Employee') }}</span>
                                        <span>{{ record.employeeName }}</span>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                        {{ l('NoData') }}
                    </div>
                    <div class="primeng-paging-container">
                        <p-paginator
                            [rows]="primengTableHelper.defaultRecordsCountPerPage"
                            #paginator
                            (onPageChange)="getRecentResponsesYes($event)"
                            [totalRecords]="primengTableHelper.totalRecordsCount"
                            [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage"
                        >
                        </p-paginator>
                        <span class="total-records-count">
                            {{ l('TotalRecordsCount', primengTableHelper.totalRecordsCount) }}
                        </span>
                    </div>
                </div>
                <!--<Primeng-Datatable-End>-->
            </div>
        </div>
    </div>
    <employeeProfileModal #employeeProfileModal> </employeeProfileModal>
</div>
