<div class="d-flex flex-column-fluid" id="jobDetail">
    <div class="container-fluid p-0">
        <div class="card card-custom gutter-b">
            <div class="card-body" [busyIf]="primengTableHelper.isLoading">
                <!--begin::Details-->
                <div class="d-flex mb-9">
                    <!--begin: Pic-->
                    <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                        <div class="symbol symbol-50 symbol-lg-120">
                            <img class="symbol-label" [src]="employeeImage" alt="ImageMissing">
                        </div>
                    </div>
                    <!--end::Pic-->
                    <!--begin::Info-->
                    <div class="flex-grow-1">
                        <!--begin::Title-->
                        <div class="d-flex justify-content-between flex-wrap mt-1">
                            <div class="d-flex mr-3">
                                <a class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3">{{employeeDetail?.employee?.fullName}}</a>
                                <a *ngIf="employeeDetail?.isUserOnBoarded">
                                    <i class="flaticon2-correct text-success font-size-h5"></i>
                                </a>
                            </div>
                        </div>
                        <!--end::Title-->
                        <!--begin::Content-->
                        <div class="d-flex flex-wrap justify-content-between mt-1">
                            <div class="d-flex flex-column flex-grow-1 pr-8">
                                <div class="d-flex flex-wrap mb-4">
                                    <a class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2" href="mailto:{{employeeDetail?.employee?.email}}" data-toggle="tooltip" title="Email Address" data-placement="right" data-original-title="Email Address">
                                        <i class="flaticon2-new-email mr-2 font-size-lg"></i>{{employeeDetail?.employee?.email}}</a>
                                    <a class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2" data-toggle="tooltip" title="Phone No." data-placement="right" data-original-title="Phone No." href="tel:{{employeeDetail?.employee?.phone}}">
                                        <i class="fas fa-phone mr-2 font-size-lg"></i>{{employeeDetail?.employee?.phone}}
                                    </a>
                                    <a class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2" data-toggle="tooltip" title="Hire Date" data-placement="right" data-original-title="Hire Date">
                                        <i class="flaticon-clock-2 mr-2 font-size-lg"></i>{{employeeDetail?.employee?.hireDate| luxonFormat:'EEE MM/dd/yyyy'}}
                                    </a>
                                    <a class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2" data-toggle="tooltip" title="Hourly Rate" data-placement="right" data-original-title="Hourly Rate">
                                        $ {{employeeDetail?.employee?.hourlyRate| number:'1.2-2'}}
                                    </a>
                                    <a *ngIf="employeeDetail?.businessUnitName!=undefined" class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2" data-toggle="tooltip" title="Business Unit" data-placement="right" data-original-title="Business Unit">
                                        <i class="flaticon-information mr-2 font-size-lg"></i>{{employeeDetail?.businessUnitName}}
                                    </a>
                                </div>

                            </div>
                        </div>
                        <!--end::Content-->
                        <div class="row">
                            <div class="col-4">

                                <div class="d-flex align-items-center mb-2">
                                    <span class="font-weight-bold mr-2">{{l('FirstName')}}:</span>
                                    <span> {{ employeeDetail?.employee?.firstName }}</span>
                                </div>
                                <div class="d-flex align-items-center mb-2">
                                    <span class="font-weight-bold mr-2">{{l('ExternalKey')}}:</span>
                                    <span> {{ employeeDetail?.employee?.externalKey }}</span>
                                </div>
                            </div>
                            <div class="col-4">

                                <div class="d-flex align-items-center mb-2">
                                    <span class="font-weight-bold mr-2">{{l('LastName')}}:</span>
                                    <span> {{ employeeDetail?.employee?.lastName }}</span>
                                </div>
                                <div class="d-flex align-items-center mb-2">
                                    <span class="font-weight-bold mr-2">{{l('OverAllRating')}}:</span>
                                    <span> {{ employeeDetail?.employee?.overAllRating }}</span>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="d-flex align-items-center mb-2">
                                    <span class="font-weight-bold mr-2">{{l('IsPhoneSmsCapable')}}:</span>
                                    <i *ngIf="employeeDetail?.employee?.isPhoneSmsCapable" class="fa fa-check-circle text-success" title="True"></i>
                                    <!-- <i *ngIf="!employeeDetail?.employee?.isPhoneSmsCapable" class="fa fa-times-circle" title="False"></i> -->
                                </div>
                                <div class="d-flex align-items-center mb-2">
                                    <span class="font-weight-bold mr-2">{{l('OnGoingAssignment')}}:</span>
                                    <i *ngIf=" employeeDetail?.employee?.onGoingAssignment" class="fa fa-check-circle text-success" title="True"></i>
                                    <!-- <i *ngIf="!employeeDetail?.employee?.onGoingAssignment" class="fa fa-times-circle" title="False"></i> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end::Info-->
                </div>
                <!--end::Details-->
                <div class="separator separator-solid"></div>
                <!--begin::Items-->
                <div class="d-flex align-items-center flex-wrap mt-8">
                    <!--begin::Item-->
                    <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2 cursor-pointer" (click)="invitation.emit(true)">
                        <span class="mr-4">
                            <i class="flaticon-confetti display-4 text-muted font-weight-bold"></i>
                        </span>
                        <div class="d-flex flex-column text-dark-75 ">
                            <span class="font-weight-bolder font-size-sm">{{l('Invitations')}}</span>
                            <span class="font-weight-bolder font-size-h5">
                                {{employeeDetail.invites}}
                           </span>

                        </div>
                    </div>
                    <!--end::Item-->
                    <!--begin::Item-->
                    <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2 cursor-pointer" (click)="geoPref.emit(true)">
                        <span class="mr-4">
                            <i class="flaticon-pie-chart display-4 text-muted font-weight-bold"></i>
                        </span>
                        <div class="d-flex flex-column text-dark-75">
                            <span class="font-weight-bolder font-size-sm">{{l('GeoPreferencias')}}</span>
                            <span class="font-weight-bolder font-size-h5">
                                {{employeeDetail.geoLocatons}}</span>
                        </div>
                    </div>
                    <!--end::Item-->
                    <!--begin::Item-->
                    <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2 cursor-pointer" (click)="feedback.emit(true)">
                        <span class="mr-4">
                            <i class="flaticon-file-2 display-4 text-muted font-weight-bold"></i>
                        </span>
                        <div class="d-flex flex-column flex-lg-fill">
                            <span class="text-dark-75 font-weight-bolder font-size-sm">{{l('Feedback')}}</span>
                            <span class="font-weight-bolder font-size-h5">
                                {{employeeDetail.feedBacks}}</span>

                        </div>
                    </div>
                    <!--end::Item-->
                    <!--begin::Item-->
                    <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2 cursor-pointer" (click)="shiftCompleted.emit(true)">
                        <span class="mr-4">
                            <i class="flaticon-chat-1 display-4 text-muted font-weight-bold"></i>
                        </span>
                        <div class="d-flex flex-column">
                            <span class="text-dark-75 font-weight-bolder font-size-sm">{{l('ShiftCompleted')}}</span>
                            <span class="font-weight-bolder font-size-h5">
                                {{employeeDetail.completedShifts}}
                            </span>

                        </div>
                    </div>
                    <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2 cursor-pointer" (click)="employeeLoction.emit(true)">
                        <span class="mr-4">
                            <i class="flaticon-map-location display-4 text-muted font-weight-bold"></i>
                        </span>
                        <div class="d-flex flex-column">
                            <span class="text-dark-75 font-weight-bolder font-size-sm">{{l('CustomerLocations')}}</span>
                            <span class="font-weight-bolder font-size-h5">
                                {{employeeDetail.customerLocations}}
                            </span>
                        </div>
                    </div>
                    <!--end::Item-->
                    <!--begin::Item-->
                    <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2 cursor-pointer" (click)="shiftAssignment.emit(true)">
                        <span class="mr-4">
                            <i class="flaticon-chat-1 display-4 text-muted font-weight-bold"></i>
                        </span>
                        <div class="d-flex flex-column">
                            <span class="text-dark-75 font-weight-bolder font-size-sm">{{l('ShiftAssigned')}}</span>
                            <span class="font-weight-bolder font-size-h5">
                                {{employeeDetail.assignedShifts}}
                            </span>
                        </div>
                    </div>
                    <!--end::Item-->

                    <!--begin::Item-->
                    <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2 cursor-pointer" (click)="approveTimeOffReq.emit(true)">
                        <span class="mr-4">
                            <i class="fa fa-umbrella-beach font-size-h1 "></i>
                        </span>
                        <div class="d-flex flex-column">
                            <span class="text-dark-75 font-weight-bolder font-size-sm">{{l('ApprovedTimeOffRequest')}}</span>
                            <span class="font-weight-bolder font-size-h5">
                                {{employeeDetail.approvedTimeOffRequest}}
                            </span>

                        </div>
                    </div>
                    <!--end::Item-->
                </div>
                <!--begin::Items-->
            </div>
        </div>
    </div>
</div>