<div bsModal #createOrEditModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true"
    [config]="{ backdrop: false }">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <form *ngIf="active" #localAreaForm="ngForm" novalidate (ngSubmit)="save()" autocomplete="off">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{"Write Message" | localize}}</span>
                    </h5>
                    <button type="button" class="close" (click)="close()" aria-label="Close" [disabled]="saving">
                        <i aria-hidden="true" class="ki ki-close"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        <div class="form-group">
                            <label for="JobOrder_note">{{ l('StaffNotes') }}</label>
                            <input type="text" #JobOrder_note="ngModel" id="JobOrder_note" class="form-control"
                                [(ngModel)]="lateCorrespondenceMsg" name="note" required />
                            <validation-messages [formCtrl]="JobOrder_note"></validation-messages>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button [disabled]="saving" type="button" class="btn btn-default" (click)="close()">
                        {{ l('Cancel') }}
                    </button>
                    <button type="submit" class="btn btn-primary blue" [disabled]="lateCorrespondenceMsg.trim()==''" [buttonBusy]="saving"
                        [busyText]="l('SavingWithThreeDot')">
                        <i class="fa fa-save"></i> <span>{{ l('Save') }}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

