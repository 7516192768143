import { Directive, HostListener, ElementRef, Renderer2, Output, EventEmitter, HostBinding } from '@angular/core';
import { NgControl, NgModel } from '@angular/forms';

@Directive({
  selector: '[appTimeFormatPublic]'
})
export class TimeFormatPublicDirective {
@Output() valueChange = new EventEmitter<string>();
  constructor(private el: ElementRef, private renderer: Renderer2, private control: NgControl, private ngModel: NgModel) {}
  @HostBinding('class.invalid-time') isInvalid = false;

  @HostListener('ngModelChange', ['$event'])
  onModelChange(value: string) {
    this.isInvalid = false;
  }

  @HostListener('onBlur', ['$event'])
  onBlur(event) {
    let inputValue = event.target as HTMLInputElement;
    let time = inputValue.value.trim();
    const origTime = time;
    let inp = "";

    if (time === "") {
      return true;
    }
    // Handle leading zero by removing it if the hour is a valid time
    if (time.startsWith('0')) {
      time = time.substring(1);
    }

    // Account for user entering AM or A or PM or P after time entered of 1 thru 12
    const appendedTime = this.appendTimeEnteredWithAMPM(time);
    if (typeof appendedTime === 'string') {
        this.updateValue(appendedTime);
        return true;
    }


    // Remove non-digit characters
    time = time.replace(/\D/g, '');
    let result: string | boolean = false;
    let m: RegExpMatchArray | null;

    const re = /^\s*([01]?\d|2[0-3]):?([0-5]\d)\s*$/;

    if ((m = time.match(re))) {
      result = (m[1].length === 2 ? "" : "0") + m[1] + ":" + m[2];
    }

    if (result) {
      const preResult = result;
      result = this.convertMilitaryToStandard(result);
      let AMPM = " AM";
      if (result !== preResult) {
        AMPM = " PM";
      }

      // Account for user entering AM or A or PM or P after valid time other than 1 thru 12
      inp = origTime.toUpperCase();
      const posOf_P = inp.search("P");
      const posOf_A = inp.search("A");
      const len = inp.length;

      if (posOf_P > 0 && (posOf_P === (len - 1) || posOf_P === (len - 2))) {
        AMPM = " PM";
      }
      if (posOf_A > 0 && (posOf_A === (len - 1) || posOf_A === (len - 2))) {
        AMPM = " AM";
      }

      // Set formatted value
      this.updateValue(result + AMPM);
    }

    if (result) {
        return result;
    } else {
        this.ngModel.control.setValue(null);
        this.isInvalid = true;
        this.ngModel.control.markAsTouched();
        this.ngModel.control.setErrors({ invalid: true });
        return result;
    }
  }

  appendTimeEnteredWithAMPM(inp: string): string | boolean {
    if (this.isValidTimeRange(inp)) {
      inp = inp.toUpperCase();
      const posOf_P = inp.search("P");
      const posOf_A = inp.search("A");
      const len = inp.length;

      if (posOf_P > 0 && (posOf_P === (len - 1) || posOf_P === (len - 2))) {
        if (parseInt(inp.replace(/\D/g, ''), 10) < 10) {
          return "0" + inp.replace(/\D/g, '') + ":00 PM";
        }
        return inp.replace(/\D/g, '') + ":00 PM";
      }
      if (posOf_A > 0 && (posOf_A === (len - 1) || posOf_A === (len - 2))) {
        if (parseInt(inp.replace(/\D/g, ''), 10) < 10) {
          return "0" + inp.replace(/\D/g, '') + ":00 AM";
        }
        return inp.replace(/\D/g, '') + ":00 AM";
      }

      return false;
    }

    return false;
  }

  isValidTimeRange(inp: string): boolean {
    const time = parseInt(inp.replace(/\D/g, ''), 10);
    return time > 0 && time < 13;
  }

  convertMilitaryToStandard(timein: string): string {
    let timeout = "";
    const minutes = timein.substring(3, 5);
    const hours = timein.substring(0, 2);

    switch (hours) {
      case "13": timeout = "01:" + minutes; break;
      case "14": timeout = "02:" + minutes; break;
      case "15": timeout = "03:" + minutes; break;
      case "16": timeout = "04:" + minutes; break;
      case "17": timeout = "05:" + minutes; break;
      case "18": timeout = "06:" + minutes; break;
      case "19": timeout = "07:" + minutes; break;
      case "20": timeout = "08:" + minutes; break;
      case "21": timeout = "09:" + minutes; break;
      case "22": timeout = "10:" + minutes; break;
      case "23": timeout = "11:" + minutes; break;
      case "00": timeout = "12:" + minutes; break;
      default: timeout = timein;
    }

    return timeout;
  }

  private updateValue(value: string) {
    this.renderer.setProperty(this.el.nativeElement, 'value', value);
    if (this.control) {
      this.control.control?.setValue(value);
    }
  }
}
