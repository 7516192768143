import { Injectable } from '@angular/core';
import { GetOffTimeRequestForViewDto } from '@shared/service-proxies/service-proxies';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MessageService {
    private subject = new Subject<any>();

    viewPenddingTimeOffRequest(showPendingTimeOffRequest:GetOffTimeRequestForViewDto) {
        this.subject.next({ showPenddigRequestModal : showPendingTimeOffRequest});
    }
    refreshPenddingTimeOffRequestWidget(refreshCallBoolean:boolean){
        this.subject.next({refreshWidgetCall:refreshCallBoolean})
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}