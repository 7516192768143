<div class="card ">
    <!--begin::Header-->
    <div class="card-header pb-1">
        <div class="card-title ">
            <h3 class="">{{l('ShiftAssigned')}}</h3>
        </div>
    </div>
    <!--end::Header-->
    <div class="card-body py-1">
        <div class="row align-items-center">
            <!--<Primeng-Datatable-Start>-->
            <div class="primeng-datatable-container col-12" [busyIf]="primengTableHelper.isLoading">
                <p-table #dataTable (onLazyLoad)="getJobOrderShifts($event)" [value]="primengTableHelper.records"
                    rows="{{ primengTableHelper.defaultRecordsCountPerPage }}" [paginator]="false" [lazy]="true"
                    [scrollable]="true" ScrollWidth="100%" [responsive]="primengTableHelper.isResponsive"
                    [resizableColumns]="primengTableHelper.resizableColumns">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 20%" pSortableColumn="id">
                                {{ l('#') }}
                                <p-sortIcon field="jobOrderShift.id"></p-sortIcon>
                            </th>
                            <th style="width: 20%" pSortableColumn="jobOrderFk.standardShiftFk.name">
                                {{ l('StandardShift') }}
                                <p-sortIcon field="standardShiftName"></p-sortIcon>
                            </th>
                            <th style="width: 20%" pSortableColumn="jobOrderFk.customerLocationFk.name">
                                {{ l('CustomerLocation') }}
                                <p-sortIcon field="customerLocationName"></p-sortIcon>
                            </th>
                            <th style="width: 15%" pSortableColumn="shiftDate">
                                {{ l('ShiftDate') }}
                                <p-sortIcon field="jobOrderShift.shiftDate"></p-sortIcon>
                            </th>
                            <th style="width: 15%" pSortableColumn="shiftStartDateTime">
                                {{ l('StartTime') }}
                                <p-sortIcon field="jobOrderShift.shiftStartDateTime"></p-sortIcon>
                            </th>
                            <th style="width: 15%" pSortableColumn="shiftEndDateTime">
                                {{ l('EndTime') }}
                                <p-sortIcon field="jobOrderShift.shiftEndDateTime"></p-sortIcon>
                            </th>
                            <!-- <th style="width: 20%" pSortableColumn="assignedDate">
                                {{ l('AssignedDate') }}
                                <p-sortIcon field="jobOrderShift.assignedDate"></p-sortIcon>
                            </th> -->
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-record="$implicit" let-i="rowIndex">
                        <tr>
                            <td class="border-1" style="width: 20%" class="long-string-column">
                                <span class="ui-column-title"> {{ l('#') }}</span>
                                <span>#{{ record.jobOrderShift.id}}</span>
                                (<b>JO</b>:
                                <a class="cursor-pointer short-string-column"
                                 href="/app/main/job-Order/job-Order-detail?jobID={{ record.jobOrderShift.jobOrderId }}" target="_blank"> 
                                {{ record.jobOrderShift.jobOrderId }}
                            </a>)
                            </td>
                            <td class="border-1" style="width: 20%" class="long-string-column">
                                <span class="ui-column-title"> {{ l('StandardShiftName') }}</span>
                                <span>{{ record.standardShiftName}}</span>
                            </td>
                            <td class="border-1" style="width: 20%" class="long-string-column" data-toggle="tooltip"
                                title="{{ record.customerLocationName }}" data-placement="right">
                                <span class="ui-column-title"> {{ l('CustomerLocation') }}</span>
                                <span *ngIf="record.customerLocationName">
                                    {{ record.customerLocationName }}
                                </span>
                                <span *ngIf="!record.customerLocationName">-</span>
                            </td>
                            <td class="border-1" style="width: 15%" class="date-time-column" data-toggle="tooltip"
                                
                                data-placement="right">
                                <span class="ui-column-title"> {{ l('ShiftDate') }}</span>
                                <span *ngIf="record.jobOrderShift.shiftDate"
                                title="Local: {{record.localTenantStartDateTime | formatStringToDate : record.jobOrderShift.shiftDate}}">
                                    {{record.jobOrderShift.locationStartDateTime | formatStringToDate : record.jobOrderShift.shiftDate  }}
                                </span>
                                <span *ngIf="!record.jobOrderShift.shiftDate">-</span>
                            </td>
                            <td class="border-1" style="width: 15%" class="date-time-column">
                                <span class="ui-column-title"> {{ l('StartTime') }}</span>
                                <span *ngIf="record.jobOrderShift.shiftStartDateTime"
                                title="Local: {{record.localTenantStartDateTime | formatStringToTime : record.jobOrderShift.shiftStartDateTime }}">
                                    {{record.jobOrderShift.locationStartDateTime | formatStringToTime : record.jobOrderShift.shiftStartDateTime  }}
                                </span>
                                <span *ngIf="!record.jobOrderShift.shiftStartDateTime">-</span>
                            </td>
                            <td class="border-1" style="width: 15%" class="date-time-column">
                                <span class="ui-column-title"> {{ l('EndTime') }}</span>
                                <span *ngIf="record.jobOrderShift.shiftEndDateTime"
                                title="Local: {{record.localTenantEndDateTime | formatStringToTime : record.jobOrderShift.shiftEndDateTime }}">
                                {{record.jobOrderShift.locationEndDateTime | formatStringToTime : record.jobOrderShift.shiftEndDateTime  }}
                                </span>
                                <span *ngIf="!record.jobOrderShift.shiftEndDateTime">-</span>
                            </td>
                            <!-- <td class="border-1" style="width: 20%" class="date-time-column">
                                <span class="ui-column-title"> {{ l('AssignedDate') }}</span>
                                <span *ngIf="record.jobOrderShift.assignedDate">
                                                    {{ record.jobOrderShift.assignedDate | luxonFormat: 'EEE MM/dd/yyyy' }}
                                                </span>
                                <span *ngIf="!record.jobOrderShift.assignedDate">-</span>
                            </td> -->
                        </tr>
                    </ng-template>
                </p-table>
                <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                    {{ l('NoData') }}
                </div>
                <div class="primeng-paging-container">
                    <p-paginator [rows]="primengTableHelper.defaultRecordsCountPerPage" #paginator
                        (onPageChange)="getJobOrderShifts($event)" [totalRecords]="primengTableHelper.totalRecordsCount"
                        [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage">
                    </p-paginator>
                    <span class="total-records-count">
                        {{ l('TotalRecordsCount', primengTableHelper.totalRecordsCount) }}
                    </span>
                </div>
            </div>
            <!--<Primeng-Datatable-End>-->
        </div>
    </div>
</div>