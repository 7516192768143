<div class="card">
    <!--begin::Header-->
    <div class="card-header pb-1">
        <div class="card-title">
            <h3 class="">{{l('CallOut')}}</h3>
        </div>
    </div>
    <!--end::Header-->
    <div class="card-body py-1">

        <div class="row align-items-center">
            <!--<Primeng-Datatable-Start>-->
            <div class="primeng-datatable-container col-12" [busyIf]="primengTableHelper.isLoading">
                <p-table #dataTable (onLazyLoad)="getShiftRejections($event)" [value]="primengTableHelper.records" rows="{{ primengTableHelper.defaultRecordsCountPerPage }}" [paginator]="false" [lazy]="true" [scrollable]="true" ScrollWidth="100%" [responsive]="primengTableHelper.isResponsive"
                    [resizableColumns]="primengTableHelper.resizableColumns">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 100px" pSortableColumn="jobOrderFk.id">
                                {{ l('JobOrder') }}
                                <p-sortIcon field="jobOrderName"></p-sortIcon>
                            </th>
                            <th style="width: 150px" pSortableColumn="rejectedDateTime">
                                {{ l('RejectedDateTime') }}
                                <p-sortIcon field="shiftRejection.rejectedDateTime"></p-sortIcon>
                            </th>
                            <th style="width: 180px" pSortableColumn="rejectedByChannel">
                                {{ l('RejectedByChannel') }}
                                <p-sortIcon field="shiftRejection.rejectedByChannel"></p-sortIcon>
                            </th>
                            <th style="width: 150px" pSortableColumn="comments">
                                {{ l('Comments') }}
                                <p-sortIcon field="shiftRejection.comments"></p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-record="$implicit" let-i="rowIndex">
                        <tr>
                            <td class="short-string-column" style="width: 100px"> 
                                <a [routerLink]="['/app/main/job-Order/job-Order-detail']"
                                [queryParams]="{jobID:record.shiftRejection.jobOrderId}">
                                <span class="ui-column-title"> {{ l('JobOrder') }}</span>
                                {{ record.shiftRejection.jobOrderId }}
                            </a>
                            </td>

                            <td class="date-time-column" style="width: 150px">
                                <span class="ui-column-title"> {{ l('RejectionDateTime') }}</span>
                                <span *ngIf="record.shiftRejection.rejectedDateTime">
                                        {{ record.shiftRejection.rejectedDateTime | luxonFormat: 'EEE MM/dd/yyyy hh:mm a' }}
                                    </span>
                                <span *ngIf="!record.shiftRejection.rejectedDateTime">-</span>
                            </td>
                            <td class="long-string-column" style="width: 180px">
                                <span class="ui-column-title"> {{ l('RejectedByChannel') }}</span> {{ l(communicationChannel[record.shiftRejection.rejectedByChannel]) }}
                            </td>
                            <td class="multiline-text-column" style="width: 150px">
                                <span class="ui-column-title"> {{ l('Comments') }}</span> {{ record.shiftRejection.comments }}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                    {{ l('NoData') }}
                </div>
                <div class="primeng-paging-container">
                    <p-paginator [rows]="primengTableHelper.defaultRecordsCountPerPage" #paginator (onPageChange)="getShiftRejections($event)" [totalRecords]="primengTableHelper.totalRecordsCount" [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage">
                    </p-paginator>
                    <span class="total-records-count">
                            {{ l('TotalRecordsCount', primengTableHelper.totalRecordsCount) }}
                        </span>
                </div>
            </div>
            <!--<Primeng-Datatable-End>-->
        </div>
    </div>
</div>