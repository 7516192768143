import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AssignUnAssignLocalAreaInputDto, EmployeeProfileDetailServiceProxy, EntityDto, GeoAvailabilitiesServiceProxy, LocalAreasServiceProxy, LockGeoAvailabilityDto } from '@shared/service-proxies/service-proxies';
import { LazyLoadEvent } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'employeeGeoPreferences',
    templateUrl: './employee-geo-preferences.component.html',
    styleUrls: ['./employee-geo-preferences.component.css']
})
export class EmployeeGeoPreferencesComponent extends AppComponentBase {

    @Input() employeeId: number;
    @ViewChild('dataTable', { static: true }) dataTable: Table;
    @ViewChild('paginator', { static: true }) paginator: Paginator;

    localAreaInput: AssignUnAssignLocalAreaInputDto = new AssignUnAssignLocalAreaInputDto();
    lockGeoPreferences: LockGeoAvailabilityDto = new LockGeoAvailabilityDto();
    advancedFiltersAreShown = false;
    filterText = '';
    nameFilter = '';
    maxOrderSeqFilter: number;
    maxOrderSeqFilterEmpty: number;
    minOrderSeqFilter: number;
    minOrderSeqFilterEmpty: number;
    isActiveFilter = -1;
    allAssignLocalAreas = false;
    isLockAvailability: boolean = false;
    constructor(
        injector: Injector,
        private _employeeProfileDetailservice: EmployeeProfileDetailServiceProxy
    ) {
        super(injector);
    }
    assignUnassignlocalArea() {
        if (this.allAssignLocalAreas) {
            this.unAssignAllAreas();
        } else {
            this.assignAllAreas();
        }
    }

    assignAllAreas() {
        this.localAreaInput.employeeId = this.employeeId;
        this.primengTableHelper.showLoadingIndicator();
        this._employeeProfileDetailservice.assignAllAreaToEmployee(this.localAreaInput)
            .pipe(finalize(() => {
                this.primengTableHelper.hideLoadingIndicator();
            })).subscribe(() => {
                this.reloadPage();
                abp.event.trigger('EmployeeProfile.Updated');
                this.allAssignLocalAreas = false;
                this.notify.success(this.l('AllLocalAreasAreAssignedSuccessfully'));
            });
    }
    unAssignAllAreas() {
        this.localAreaInput.employeeId = this.employeeId;
        this.primengTableHelper.showLoadingIndicator();
        this._employeeProfileDetailservice.unAssignAllAreaToEmployee(this.localAreaInput)
            .pipe(finalize(() => {
                this.primengTableHelper.hideLoadingIndicator();
            })).subscribe(() => {
                this.reloadPage();
                abp.event.trigger('EmployeeProfile.Updated');
                this.allAssignLocalAreas = false;
                this.notify.success(this.l('AllLocalAreasAreUnAssignedSuccessfully'));
            });
    }
    assignUnAssignArea(localAreaId: number) {
        this.primengTableHelper.showLoadingIndicator();
        this.localAreaInput.employeeId = this.employeeId;
        this.localAreaInput.localArea = localAreaId;
        this._employeeProfileDetailservice.assignUnAssingLocalAreaToEmployee(this.localAreaInput)
            .pipe(finalize(() => {
                this.primengTableHelper.hideLoadingIndicator();
            })).subscribe(() => {
                this.reloadPage();
                abp.event.trigger('EmployeeProfile.Updated');
                this.notify.success(this.l('RecordSuccessfullyUpdated'));
            });
    }
    getLocalAreas(event?: LazyLoadEvent) {
        if (this.primengTableHelper.shouldResetPaging(event)) {
            this.paginator.changePage(0);
            return;
        }

        this.primengTableHelper.showLoadingIndicator();

        this._employeeProfileDetailservice
            .getGeoAvailabilityByEmployeeId(
                this.employeeId,
                this.primengTableHelper.getSorting(this.dataTable),
                this.primengTableHelper.getSkipCount(this.paginator, event),
                this.primengTableHelper.getMaxResultCount(this.paginator, event)
            ).pipe(finalize(() => {
                this.primengTableHelper.hideLoadingIndicator();
            }))
            .subscribe((result) => {
                this.primengTableHelper.totalRecordsCount = result?.totalCount;
                this.primengTableHelper.records = result?.items;
                this.isLockAvailability = result?.lockGeoAvailability;
                if (result?.items.find(x => !x.isAssigned)) {
                    this.allAssignLocalAreas = false;
                }
                else {
                    this.allAssignLocalAreas = result.isAllAssigned;
                }
            });
    }

    reloadPage(): void {
        this.paginator.changePage(this.paginator.getPage());
    }
    lockEmployeeGeoAvailability() {
        if (this.employeeId) {
            this.lockGeoPreferences.employeeId = this.employeeId;
            this.lockGeoPreferences.lockGeoAvailability = this.isLockAvailability;
            this.primengTableHelper.showLoadingIndicator();
            this._employeeProfileDetailservice.lockEmployeeGeoAvailability(this.lockGeoPreferences)
                .pipe(finalize(() => {
                    this.primengTableHelper.hideLoadingIndicator();
                })).subscribe(() => {
                    this.notify.success(this.l('Record update successfully'));
                });
        }
    }

}
