<div class="card ">
    <!--begin::Header-->
    <div class="card-header pb-1">
        <div class="card-title ">
            <h3 class="">{{l('Invitations')}}</h3>
        </div>
    </div>
    <!--end::Header-->
    <div class="card-body py-1">

        <div class="row align-items-center">
            <!--<Primeng-Datatable-Start>-->
            <div class="primeng-datatable-container col-12" [busyIf]="primengTableHelper.isLoading">
                <p-table #dataTable (onLazyLoad)="getInvites($event)" [value]="primengTableHelper.records"
                    rows="{{ primengTableHelper.defaultRecordsCountPerPage }}" [paginator]="false" [lazy]="true"
                    [scrollable]="true" ScrollWidth="100%" [responsive]="primengTableHelper.isResponsive"
                    [resizableColumns]="primengTableHelper.resizableColumns">
                    <ng-template pTemplate="header">
                        <tr>

                            <th style="width: 100px" pSortableColumn="jobOrderFk.id">
                                {{ l('JobOrder') }}
                                <p-sortIcon field="jobOrderName"></p-sortIcon>
                            </th>

                            <!-- <th style="width: 150px" pSortableColumn="rejectionReason">
                                    {{ l('RejectionReason') }}
                                    <p-sortIcon field="invite.rejectionReason"></p-sortIcon>
                                </th> -->
                            <th style="width: 100px" pSortableColumn="status">
                                {{ l('Status') }}
                                <p-sortIcon field="invite.status"></p-sortIcon>
                            </th>
                            <th style="width: 400px" pSortableColumn="inviteMessage">
                                {{ l('InviteMessage') }}
                                <p-sortIcon field="invite.inviteMessage"></p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-record="$implicit" let-i="rowIndex">
                        <tr>

                            <!-- <td class="border-1" *ngIf="record.jobOrderShiftName" style="width: 150px">
                                    <span class="ui-column-title"> {{ l('jobOrderShiftName') }}</span> {{ record.jobOrderShiftName }}
                                </td> -->
                            <td class="border-1" style="width: 100px" class="long-string-column">
                                <span class="ui-column-title"> {{ l('JobOrder') }}</span> {{ record.jobOrderName }}
                            </td>
                            <td class="border-1" style="width: 100px" class="short-string-column">
                                <span class="ui-column-title"> {{ l('Status') }}</span> {{
                                l(jobOrderShiftStatus[record.invite.status]) }}
                            </td>
                            <td class="border-1" style="width: 400px" class="multiline-text-column"
                                data-toggle="tooltip" title="{{ record.invite.inviteMessage }}" data-placement="right">
                                <span class="ui-column-title"> {{ l('Message') }}</span> {{ record.invite.inviteMessage
                                }}
                            </td>
                            <!-- <td class="border-1" style="width: 150px">
                                    <span class="ui-column-title"> {{ l('RejectionReason') }}</span> {{ record.invite.rejectionReason }}
                                </td> -->
                        </tr>
                    </ng-template>
                </p-table>
                <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                    {{ l('NoData') }}
                </div>
                <div class="primeng-paging-container">
                    <p-paginator [rows]="primengTableHelper.defaultRecordsCountPerPage" #paginator
                        (onPageChange)="getInvites($event)" [totalRecords]="primengTableHelper.totalRecordsCount"
                        [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage">
                    </p-paginator>
                    <span class="total-records-count">
                        {{ l('TotalRecordsCount', primengTableHelper.totalRecordsCount) }}
                    </span>
                </div>
            </div>
            <!--<Primeng-Datatable-End>-->
        </div>
    </div>
</div>