import { Component, Injector, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppConsts } from '@shared/AppConsts';
import { AutoCompleteServiceProxy, CommonLookupServiceProxy, JobOrderLookupDto, JobOrdersServiceProxy, NameValueOfString, PagedResultDtoOfGetJobOrderShiftForViewDto, StaffDashboardServiceProxy, UnassignJobOrderShiftInputDto } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { LazyLoadEvent } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { finalize } from 'rxjs/operators';
import { WidgetComponentBaseComponent } from '../widget-component-base';
import { LateCorrespondsMessageModelComponent } from './late-corresponds-message-model/late-corresponds-message-model.component';

@Component({
    selector: 'app-widget-check-ins-list',
    templateUrl: './widget-missing-check-ins-list.component.html',
    styleUrls: ['./widget-missing-check-ins-list.component.css'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
})
export class WidgetMissingCheckInsListComponent extends WidgetComponentBaseComponent {
    @ViewChild('dataTable', { static: true }) dataTable: Table;
    @ViewChild('paginator', { static: true }) paginator: Paginator;
    @ViewChild('lateCorrespondsMessage', { static: true }) lateCorrespondsMessage: LateCorrespondsMessageModelComponent;
    employeeImage = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
    employeeDetailForPopOver: any;
    employeePopOverTimeOut: any;
    employee: PagedResultDtoOfGetJobOrderShiftForViewDto;
    employeePopOverName: string;
    saving = false;
    unassignJobOrderShiftInputDto: UnassignJobOrderShiftInputDto = new UnassignJobOrderShiftInputDto();
    jobOrderIdNoCallNoShow:number;
    HasLateCorrespondence: boolean = true;
    lateCorresponsMsg:string;
    selectedBusinessUnits: JobOrderLookupDto = new JobOrderLookupDto();
    shiftId: any;
    businessUnits: JobOrderLookupDto[];
    @Input() onlyActive: boolean = true;
    filteredbusinessUnits: NameValueOfString[];
    businessUnitIdFilter: number;
    showfilters:boolean = false;
    constructor(injector: Injector,
        private _commonLookupServiceProxy: CommonLookupServiceProxy,
        private _jobOrdersServiceProxy: JobOrdersServiceProxy,
        private autoCompleteServiceProxy: AutoCompleteServiceProxy,
        private _staffDashboardService: StaffDashboardServiceProxy) {
        super(injector);
        this.getBusinessUnits();
    }
    getEmployeePopoverDetail(employeeName): void {
        this.employeePopOverName = "";
        this.employeeImage = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
        this.employeePopOverTimeOut = setTimeout(() => {
            var getEmpId = this.employee.items.find(x => x.employeeFullName == employeeName)
            this.employeeDetailForPopOver = this.getEmployeeProfileForPopover(getEmpId.jobOrderShift.employeeId).then(result => {
                this.employeeDetailForPopOver = result;
                this.employeePopOverName = this.employeeDetailForPopOver.userName;
                this.employeeImage = this.getEmployeeProfilePictureForPopover(result);
            });
        }, 1000);
    }
    getAllMissingCheckInnsShifts(event?: LazyLoadEvent) {
        if (this.primengTableHelper.shouldResetPaging(event)) {
            this.paginator.changePage(0);
            return;
        }
        this.primengTableHelper.showLoadingIndicator();
        this._staffDashboardService.getAllMissingCheckinShifts(
            this.HasLateCorrespondence,
            (this.businessUnitIdFilter =
                this.selectedBusinessUnits?.id == 0 ? undefined : this.selectedBusinessUnits?.id),
            this.primengTableHelper.getSorting(this.dataTable),
            this.primengTableHelper.getSkipCount(this.paginator, event),
            this.primengTableHelper.getMaxResultCount(this.paginator, event),
        ).pipe(
            finalize(() => {
                this.primengTableHelper.hideLoadingIndicator();
            })
        ).subscribe((result) => {
            this.primengTableHelper.totalRecordsCount = result.totalCount;
            this.primengTableHelper.records = result.items;
            this.employee = result;
        });
    }

    reloadPage(): void {
        this.paginator.changePage(this.paginator.getPage());
    }

    noCallNoShow(data, i) {
        this.saving = true;
        this.jobOrderIdNoCallNoShow = i;
        this.unassignJobOrderShiftInputDto.jobOrderShiftId = [];
        this.unassignJobOrderShiftInputDto.isCallOut = false;
        this.unassignJobOrderShiftInputDto.notes = null;
        this.unassignJobOrderShiftInputDto.jobOrderId = data?.jobOrderId;
        this.unassignJobOrderShiftInputDto.isNoCallNoShow = true;
        this.unassignJobOrderShiftInputDto.jobOrderShiftId.push(data?.jobOrderShift?.id);
        this.message.confirm(this.l('YouWantToMarkAsNoCallNoShow'), this.l('AreYouSure'), (isConfirmed) => {
            if (isConfirmed) {
                this._jobOrdersServiceProxy.unassignSelectedJobOrderShifts(this.unassignJobOrderShiftInputDto).pipe(finalize(() => {
                })).subscribe(() => {
                    this.getAllMissingCheckInnsShifts();
                    this.saving = false;
                    this.notify.info(this.l('SavedSuccessfully'));
                }, (error)=> {
                    this.saving = false;
                });;
            }
        });
        
    }

    saveLateCorrespondMessage(event){
        this._staffDashboardService.updateLateCorrespondMessage(this.shiftId, event).pipe(finalize(() => {
        })).subscribe(() => {
            this.getAllMissingCheckInnsShifts();
            this.saving = false;
            this.notify.info(this.l('SavedSuccessfully'));
        }, (error)=> {
            this.saving = false;
        });;
    }

    sendLateCorrespondMessage(jobOrderShiftId){
        this.shiftId = jobOrderShiftId;
        this.lateCorrespondsMessage.show();
    }

    getBusinessUnits() {
        this._jobOrdersServiceProxy
            .getAllBusinessUnitsForDropdown()
            .pipe(finalize(() => {}))
            .subscribe((data) => {
                this.businessUnits = data;
            });
    }

    filterbusinessUnit(event): void {
        this.autoCompleteServiceProxy.getBusinessUnits(undefined,event.query,this.onlyActive).subscribe((businessUnits) => {
            this.filteredbusinessUnits = businessUnits;
        });
    }
}
