import { Injectable, Injector, NgZone } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { HubConnection } from '@microsoft/signalr';

@Injectable()
export class JobOrderSignalrService extends AppComponentBase {

    constructor(
        injector: Injector,
        public _zone: NgZone
    ) {
        super(injector);
    }

    jobOrderHub: HubConnection;
    isUserConnected = false;

   configureConnection(connection): void {
        // Set the common hub
        this.jobOrderHub = connection;

        // Reconnect loop
        let reconnectTime = 5000;
        let tries = 1;
        let maxTries = 8;
        function start() {
            return new Promise(function (resolve, reject) {
                if (tries > maxTries) {
                    reject();
                } else {
                    connection.start()
                        .then(resolve)
                        .then(() => {
                            reconnectTime = 5000;
                            tries = 1;
                        })
                        .catch(() => {
                            setTimeout(() => {
                                start().then(resolve);
                            }, reconnectTime);
                            reconnectTime *= 2;
                            tries += 1;
                        });
                }
            });
        }

        // Reconnect if hub disconnects
        connection.onclose(e => {
            this.isUserConnected = false;

            if (e) {
                abp.log.debug('Job Order connection closed with error: ' + e);
            } else {
                abp.log.debug('Job Order disconnected');
            }

            start().then(() => {
                this.isUserConnected = true;
            });
        });

        // Register to get notifications
        this.registerJobOrderIndicationEvents(connection);
    }

    registerJobOrderIndicationEvents(connection): void {
        connection.on('getJobOrderEditingStateChange', (user, jobOrderId,isOpened) => {
            abp.event.trigger('app.jobOrder.jobOrderEditingStateChange',
                {
                    user: user,
                    jobOrderId: jobOrderId,
                    isOpened : isOpened
                });
        });
        connection.on('getJobOrderUserStateChange', (userId,isConnected) => {
            abp.event.trigger('app.jobOrder.getJobOrderUserStateChange',
                {
                    user: userId,
                    isConnected: isConnected
                });
        });
    }
    sendJobOrderIndication(indicationData, callback): void {
        if (!this.isUserConnected) {
            if (callback) {
                callback("No connection");
            }
            return;
        }

        this.jobOrderHub.invoke('jobOrderIndication', indicationData).then(result => {
            if (result) {
                abp.notify.warn(result);
            }

            if (callback) {
                callback("Invoke error");
            }
        }).catch(error => {
            abp.log.error(error);

            if (callback) {
                callback(error);
            }
        });
    }
 
    init(): void {
        this._zone.runOutsideAngular(() => {
            abp.signalr?.connect();
            abp.signalr?.startConnection(abp.appPath + 'signalr-joborder', connection => {
                this.configureConnection(connection);
            }).then(() => {
                abp.event.trigger('app.jobOrder.connected');
                this.isUserConnected = true;
            });
        });
    }
}
