import { Component, Injector, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppConsts } from '@shared/AppConsts';
import { CommonLookupServiceProxy, PagedResultDtoOfGetJobOrderShiftForViewDto, StaffDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { LazyLoadEvent } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { finalize } from 'rxjs/operators';
import { WidgetComponentBaseComponent } from '../widget-component-base';

@Component({
    selector: 'app-widget-missing-time-entries-list',
    templateUrl: './widget-missing-time-entries-list.component.html',
    styleUrls: ['./widget-missing-time-entries-list.component.css'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
})
export class WidgetMissingTimeEntriesListComponent extends WidgetComponentBaseComponent {
    @ViewChild('dataTable', { static: true }) dataTable: Table;
    @ViewChild('paginator', { static: true }) paginator: Paginator;
    employeeImage=AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
    employeeDetailForPopOver:any;
    employeePopOverTimeOut:any;
    employee:PagedResultDtoOfGetJobOrderShiftForViewDto;
    employeePopOverName: string;
    constructor(injector: Injector,
        private _commonLookupServiceProxy:CommonLookupServiceProxy,
        private _staffDashboardService: StaffDashboardServiceProxy) {
        super(injector);
    }
    getEmployeePopoverDetail(employeeName): void {
        this.employeePopOverName="";
        this.employeeImage=AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
        this.employeePopOverTimeOut=setTimeout(() => {
            var getEmpId=this.employee.items.find(x=>x.employeeFullName==employeeName)
        this.employeeDetailForPopOver =this.getEmployeeProfileForPopover(getEmpId.jobOrderShift.employeeId).then(result=>{
            this.employeeDetailForPopOver=result;
            this.employeePopOverName=this.employeeDetailForPopOver.userName;
            this.employeeImage = this.getEmployeeProfilePictureForPopover(result);
        });
        }, 1000);
    }
    getAllMissingTimeEntriesShifts(event?: LazyLoadEvent) {
        if (this.primengTableHelper.shouldResetPaging(event)) {
            this.paginator.changePage(0);
            return;
        }
        this.primengTableHelper.showLoadingIndicator();
        this._staffDashboardService.getAllMissingTimeEntriesShifts(
            this.primengTableHelper.getSkipCount(this.paginator, event),
            this.primengTableHelper.getMaxResultCount(this.paginator, event),
            this.primengTableHelper.getSorting(this.dataTable),
        ).pipe(
            finalize(() => {
                this.primengTableHelper.hideLoadingIndicator();
            })
        ).subscribe((result) => {
            this.primengTableHelper.totalRecordsCount = result.totalCount;
            this.primengTableHelper.records = result.items;
            this.employee=result;
        });
    }

    reloadPage(): void {
        this.paginator.changePage(this.paginator.getPage());
    }
}
